import PropTypes from 'prop-types'
import React,{useEffect, useState} from "react"
import { useLocation } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card, 
  CardBody,
} from "reactstrap"
import "chartist/dist/scss/chartist.scss";

import loading from "../../assets/images/loading.gif";

import store from 'store';
import { SET_CODE } from 'store/auth/data/actionTypes';

import Board from './Component/Board';

//i18n
import { withTranslation } from "react-i18next"
import { getCompanyDetail } from 'crud/auth.crud';

const CompanyMain = props => {
  const location = useLocation()

  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState(0)
  const [dataList, setDataList] = useState([]);
  const [markerList, setMarkerList] = useState([]);
  const [inputData, setInputData] = useState({});
  const [page, setPage] = useState(1);
  const [centerMarker, setCenterMarker] = useState({ lat: 36.44699, lng: 127.09598 })

  const nextGetData = () => {
    setOnUpload(true)
    let newArr = []
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    setPageArr(newArr)
    setOnUpload(false)
  }

  const getDataList = async () => {
    setOnUpload(true)
    try {
      console.log(location.state['id'])
      const response = await getCompanyDetail({id : location.state['id']})
      // 나중에 '900002'를 location.state.id로 바꿔야함
      console.log(">>>>>>>>>"+response.data)
      console.log(">>>>22?? ::::", JSON.stringify(response))
      
      if (!contentCount || contentCount !== Math.floor(response.data?.count / 15) + 1) {
        setContentCount(Math.floor(response.data.count / 15) + 1)
        let newArr = []
        for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
          if (i + 1 <= Math.floor(response.data.count / 15) + 1) {
            newArr.push(i + 1)
          }
        }
        setPageArr(newArr)
      }
      if (response.status === 200) {
        setInputData(response.data)
        // const content = response.data?.results || [];
        // if (content.length > 0) {
        //   setDataList([...content])
        //   setMarkerList([...content].splice(0, 1))
        //   setInputData(response.data)
        //   setCenterMarker({ lat: [...content].splice(0, 1)[0].lat, lng: [...content].splice(0, 1)[0].lng })
        // } else {
        //   setDataList(false)          
        // }
      }
    } catch (error) {
      console.error(error);
    }
    setOnUpload(false)
  };

  const movePage = (id, company) => {
    console.log(id, company)
    // history.push({ pathname: '/detail', state: { id: id } })
  }



  


  useEffect(() => {
    nextGetData()
  }, [pagesGroup])

  useEffect( async () => {
    await getDataList()
  }, [page])

  useEffect(()=>{
    console.log(">>>"+JSON.stringify(location))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>IOT MONITOR</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col md={4}>
              <Row>
                <Col md={12}>
                  {/* <Board
                  title={'유저 정보'}
                  form={'input'}
                  height={'80vh'}
                  inputContent={[
                    {title: 'idx', value: inputData.id},
                    {title: '유저 ID', value: inputData.Company_id},
                    {title: '유저명', value: inputData.Companyname},
                    {title: 'email', value: inputData.email},
                    {title: '연락처', value: inputData.mobile_phone},
                    {title: '계정 활성화 여부', value: inputData.is_active ? "활성" : "비활성"},
                    // {title: '푸시알림 동의여부', value: inputData.agree_push},
                    // {title: '메일알림 동의여부', value: inputData.agree_email},
                    {title: '가입일', value: inputData.date_joined},
                    {title: '', button: "test", onclick:() => console.log("aaaa")},
                    ]} /> */}
                    {/* {item.key === 'created_at' ? item.value.substr(0, 10) : item.value} */}
                  <Card>
                    <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
                      <div className="table-responsive" style={{ height: '80vh', minHeight: '100px' }}>
                        <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                          <thead className="chart-thead">
                            <tr>
                              <th scope="col" className='bg-dark-ash'>발주처 상세</th>
                            </tr>
                          </thead>
                        </table>



                        <div className="w-100" style={{ padding: 24 }}>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">번호</p>
                              <input value={inputData.id} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { console.log("e:::", e) }} />
                            </>
                          </div>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">발주처 명</p>
                              <input value={inputData.name} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { console.log("e:::", e) }} />
                            </>
                          </div>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">가입일</p>
                              <input value={inputData.updated_at !== undefined ? inputData.updated_at.substring(0,10) : '-'} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { console.log("e:::", e) }} />
                            </>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* <button
            className="btn btn-primary w-md waves-effect waves-light"
            type="submit"
            onClick={() => { console.log("update Company") }}
          >
            수정
          </button> */}
          
          {onUpload && 
            <div style={{
                position: "absolute",
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                background: 'rgba(255, 255, 255, 0.6)',
                zIndex: 999,
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img src={loading} alt="로딩중입니다." width={'5%'}></img>
            </div>
          }

        </Container>
      </div>
    </React.Fragment>
  )
}

CompanyMain.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(CompanyMain)
