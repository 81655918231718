import { func } from "prop-types";
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';

import { Link } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody } from "reactstrap"

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { checkDriverId, checkWaverBarNumber, deleteChildrenCompanyList, deleteDriverAccount, deleteWaverBarNumber, getBusinessCompany, getBusinessList, getDriverList, getWaverBarList, patchBusinessList, saveDriverAccount, saveDriverExcel, saveWaverBarExcel, saveWaverBarNumber, setNewBusinessList, updateDriverList, uploadCompanyListExcel } from "crud/auth.crud";

import _ from "lodash";
import moment from "moment";
import Util from "pages/Dashboard/Common/Util";

// import images
import weaver from "../../assets/images/weaver.png";
import loading from "../../assets/images/loading.gif";

const BusinessCompanyTotalList = () => {
  const history = useHistory()
  const selector = useSelector(state => state)
  const newDate = new Date()
  const nextDate = new Date()

  const [dataList, setDataList] = useState([]);
  const [sumList, setSumList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [pricePerL, setPricePerL] = useState(50);
  const [user, setUser] = useState(selector.auth?.user?.id);
  const [company, setCompany] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.id);
  const [startDate, setStartDate] = useState(moment(newDate).format('YYYY-MM') + '-01 00:00:00');
  const [endDate, setEndDate] = useState(moment(nextDate.setMonth(nextDate.getDate() + 1)).format('YYYY-MM') + '-01 00:00:00');
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(4);
  const [totalElements, setTotalElements] = useState(4);
  const [page, setPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const [companyListFile, setCompanyListFile] = useState(undefined);
  const [onUpload, setOnUpload] = useState(false);
  // const [pagesGroup, setPagesGroup] = useState(1)
  // const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  // const [contentCount, setContentCount] = useState()

  // const nextGetData = async () => {
  //   setOnUpload(true)
  //   let newArr = []
  //   console.log("pageArr >>> ", pageArr)
  //   for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
  //     console.log("페이지 일치 여부", page, i + 1)
  //     if (i + 1 <= contentCount) {
  //       newArr.push(i + 1)
  //     }
  //   }
  //   console.log("newArr >>> ", newArr)
  //   setPageArr(newArr)
  //   setOnUpload(false)
  // }

  const getDataList = async () => {
    setOnUpload(true)

    try {
      const response = await getBusinessCompany(company);
      console.log(response.data)
      if (response.data?.result === 'success') {
        const dataList = response?.data?.data;
        setDataList(dataList.filter(item => item.id !== company))
      }
      // if (!contentCount || contentCount !== Math.floor(response.data?.count / 15) + 1) {
      //   setContentCount(Math.floor(response.data.count / 15) + 1)
      //   let newArr = []
      //   for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      //     if (i + 1 <= Math.floor(response.data.count / 15) + 1) {
      //       newArr.push(i + 1)
      //     }
      //   }
      //   setPageArr(newArr)
      // }
    } catch (error) {
      console.error(error);
    }
    setOnUpload(false)
  }

  // const checkPk = async () => {
  //   setOnUpload(true)
  //   const params = {
  //     id: waverBarId,
  //   }
  //   const rs = await checkWaverBarNumber(params)
  //   if (rs.data?.result === 'success') {
  //     setWaverBarIdConfirm(true)
  //   }
  //   alert(rs.data?.msg)
  //   setOnUpload(false)
  // }

  // const isValiddate = () => {
  //   if (waverBarId === '') {
  //     return alert(" IoT Monitor바 번호를 입력해주세요.")
  //   }
  //   return "success"
  // }

  // const saveWaverBar = async () => {
  //   setOnUpload(true)
  //   const validdate = isValiddate();
  //   if (validdate !== 'success') {
  //     return alert(validdate)
  //   }
  //   const params = {
  //     'id': waverBarId,
  //     'company': companyId,
  //     'status': 'Y',
  //   }
  //   const rs = await saveWaverBarNumber(params)
  //   if (rs.status === 201) {
  //     setIsRefresh(true)
  //     alert('정상적으로 등록되었습니다.')
  //   } else {
  //     alert('등록에 실패했습니다.')
  //   }
  //   setWaverBarId('')
  //   setWaverBarIdConfirm(false)
  //   setOnUpload(false)
  // }

  const uploadCompanyExcel = async () => {
    setOnUpload(true)
    if (companyListFile == undefined || companyListFile?.length == 0) { setOnUpload(false); return alert('파일을 등록하세요.') }
    try {
      const params = {
        type: 'xlsx',
        category: 'companyTotalListFile',
        company_id: company,
        member_id: user
      }
      const fd = new FormData();
      fd.append('data', JSON.stringify(params));
      const newFile = new File([companyListFile[0]], `${encodeURI(companyListFile[0].name)}`, { type: companyListFile[0].type })
      if (companyListFile && !_.isEmpty(companyListFile)) {
        fd.append('file_0', newFile);
      }

      const response = await uploadCompanyListExcel(fd)
      if (response.data?.result === 'success') {
        console.log(response.data)
        getDataList()
      }
    } catch (e) {
      console.log(e)
      if (e.response) console.log(e.response);
    }
    setCompanyListFile(undefined)
    setOnUpload(false)
  }

  const deleteChildrenCompany = async (id) => {
    if (confirm("해당 사업장을 삭제하시겠습니까?")) {
      setOnUpload(true)
      {
        const params = {
          total_company: null
        }
        const response = await deleteChildrenCompanyList(id, params);
        console.log(response)
      }
      getDataList()
      setOnUpload(false)
    }
  }

  const registFiles = (e) => {
    setCompanyListFile([...e.target.files])
    console.log(e.target.files[0])
  }

  const deleteFile = (item) => {
    setCompanyListFile(companyListFile.filter(data => data !== item))
  }

  const fileDownload = async () => {
    let url = 'https://waveradmin.ropiklabs.com/media/sample/사업장배출자등록엑셀.xlsx'
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const excelUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = excelUrl;
        link.setAttribute('download', '사업장배출자등록엑셀.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error)
      })
  };

  // useEffect(() => {
  //   nextGetData()
  // }, [pagesGroup])

  useEffect(() => {
    getDataList()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>사업장 목록 관리 |  IoT Monitor</title>
        </MetaTags>
        <div className="account-pages">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="사업장 목록 관리" />

            <Row className="justify-content-center">
              <Col md={8} lg={6} style={{ width: '20%', minWidth: 420 }}>
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-4">
                      <h5 className="text-white font-size-20">사업장 목록 관리</h5>
                      <p className="text-white-50"></p>
                      <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                    </div>
                  </div>

                  <CardBody className="p-4">
                    <div className="p-3">

                      <div>
                        <div className="mb-3" style={{ position: "relative" }}>
                          <p className="noMargin" style={{ fontSize: 16, color: 'black' }}>사업장 목록 엑셀등록</p>
                        </div>
                        <div className="mb-3">
                          <label className="btn btn-primary waves-effect waves-light" htmlFor="ex_file" style={{ height: 36, marginLeft: 6 }}>파일 선택</label>
                          <input style={{ display: "none" }} type="file" accept=".xls,.xlsx" id="ex_file" onChange={(e) => {
                            registFiles(e)
                          }} />
                          {companyListFile?.length > 0 && companyListFile.map((item, index) => {
                            return (
                              <div key={index} style={{ border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6 }}>
                                <p className="noMargin" style={{ paddingLeft: 6 }}>{item.name}</p>
                                <p className="noMargin" style={{ paddingRight: 6, color: "#000", cursor: "pointer" }} onClick={() => {
                                  deleteFile(item)
                                }}>X</p>
                              </div>
                            )
                          })
                          }
                        </div>
                      </div>

                      <div className="mb-3 row">
                        <div className="col-12 text-end">
                          <button
                            style={{ marginRight: 6 }}
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={async () => {
                              fileDownload()
                            }}
                          >
                            예시 다운로드
                          </button>
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={async () => {
                              uploadCompanyExcel()
                            }}
                          >
                            등록하기
                          </button>
                        </div>
                      </div>

                      {/* <div className="mb-3 row">
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-warning waves-effect waves-light"
                            type="submit"
                            style={{ width: '100%', marginTop: 24 }}
                            onClick={async () => {
                              setModalShow(true)
                            }}
                          >
                             IoT Monitor바 추가
                          </button>
                        </div>
                      </div> */}
                      {dataList && dataList.map((item, index) => {
                        return (
                          <div key={index} style={{ marginHorizontal: 20 }}>
                            <div style={{ padding: 12, borderBottomWidth: 1, borderBottomColor: '#48C3B1', borderBottomStyle: "solid", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                              <div style={{ cursor: "pointer" }}>
                                <p style={{ fontSize: 18, color: 'black', fontWeight: 'bold', margin: 0 }}>{item.name}</p>
                              </div>
                              <div style={{ zIndex: 10, textAlign: "right" }}>
                                <p style={{ color: '#ff4949', fontSize: 16, margin: 0, cursor: 'pointer' }} onClick={() => {
                                  deleteChildrenCompany(item.id)
                                }}>{'X'}</p>
                              </div>
                            </div>
                          </div>
                        )
                      })}

                      {/* <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4 btn-group">
                          <Button
                            style={{ opacity: page !== 1 ? 1 : 0, cursor: page !== 1 ? "pointer" : 'auto', padding: "0.5rem 0.5rem" }}
                            type="button"
                            color="primary"
                            size="sm"
                            className="waves-effect"
                            onClick={() => {
                              if (page !== 1) {
                                setPage(page - 1)
                                if (Math.min(...pageArr) === page) {
                                  setPagesGroup(pagesGroup - 5)
                                }
                              }
                            }}
                          >
                            <i className="fa fa-chevron-left" />
                          </Button>
                          {pageArr && pageArr.map((item, index) => {
                            return (
                              <Button
                          key={index}
                          style={{ cursor: 'pointer', padding: "0.5rem 1rem", border: 'none', color: page === item ? 'white' : 'black', backgroundColor: page === item ? '#48C3B1' : 'white' }}
                          type="button"
                          size="sm"
                          className="waves-effect"
                          onClick={() => {
                            setPage(item)
                          }}
                        >
                          {item}
                        </Button>
                            )
                          })}
                          <Button
                            style={{ opacity: page !== contentCount ? 1 : 0, cursor: page !== contentCount ? "pointer" : 'auto', padding: "0.5rem 0.5rem" }}
                            type="button"
                            color="primary"
                            size="sm"
                            className="waves-effect"
                            onClick={() => {
                              if (page !== contentCount) {
                                setPage(page + 1)
                                if (Math.max(...pageArr) === page) {
                                  setPagesGroup(pagesGroup + 5)
                                }
                              }
                            }}
                          >
                            <i className="fa fa-chevron-right" />
                          </Button>
                        </div>
                      </div> */}

                    </div>
                  </CardBody>

                  {/* {modalShow &&
                    <CardBody className="p-4">
                      <div className="p-3">

                        <div style={{ padding: '0.4rem 1rem', borderRadius: '0.2rem', margin: "18px 0", fontSize: 16 }} className="btn btn-primary dropdown-toggle waves-effect waves-light bg-success" onClick={() => { setModalShow(false) }}>뒤로가기
                        </div>

                        <div style={{ display: 'flex' }}>
                          <div className="mb-3" style={{ position: "relative" }}>
                            <p className="noMargin" style={{ fontSize: 16, color: '#9a9a9a' }}>아이디</p>
                            <input className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setWaverBarId(e.target.value), setWaverBarIdConfirm(false) }} />
                          </div>
                          <input type="button" className="btn btn-primary waves-effect waves-light" style={{ height: 35, marginTop: 30, marginLeft: 6 }} value="중복확인" onClick={() => { checkPk() }} />
                        </div>

                        <div className="mb-3 row">
                          <div className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              onClick={() => { saveWaverBar() }}
                            >
                              등록하기
                            </button>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  } */}

                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

// business-list-management-screen.propTypes = {
//   registerUser: PropTypes.func,
//   registerUserFailed: PropTypes.func,
//   registrationError: PropTypes.any,
//   user: PropTypes.any,
// }

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default BusinessCompanyTotalList

// export default connect(mapStatetoProps, {
//   registerUser,
//   apiError,
//   registerUserFailed,
// })(business-list-management-screen)
