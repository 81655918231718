import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import { useSelector } from "react-redux"
import moment from "moment"

//i18n
import { withTranslation } from "react-i18next"
import { alarmDelete, alarmResponse, alarmView } from "crud/auth.crud"

import store from "store"
import { SET_ALARM_COUNT } from "store/auth/data/actionTypes"

const NotificationDropdown = props => {
  const selector = useSelector(state => state)

  const [memberId, setMemberId] = useState(selector.auth?.user?.id);
  const [modalShow, setModalShow] = useState(false);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [size, setSize] = useState(4);
  const [totalElements, setTotalElements] = useState(4);
  const [page, setPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const alarm = selector.Data.alarmCount
  const [alarmCount, setAlarmCount] = useState(0);

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const getDataList = async ()=>{
    if (isFetching || isListEnd) {            
        return;
    }
    setIsFetching(true)
    const params ={
      page : page,
      memberId : memberId
    }
    const response = await alarmResponse(params);
    const content = response.data?.results || [];
    if (content.length > 0) {
      setDataList([...content])
      setIsFetching(false)
    } else {
      setIsFetching(false)
      setIsListEnd(true)
    }
  }

  const setAlarmView = async () => {        
    const params = {
        member_id : memberId
    }
    const response = await alarmView(params);
    if(response.status === 200){
        store.dispatch({ type: SET_ALARM_COUNT, data: 0 });
    }
  }

  const deleteAlarm = async (id) => {
    const params = {
        status : 'N'
    }
    const urlPlus = id
    const response = await alarmDelete(params, urlPlus);
    if(response.status === 200){
      setDataList([]),
      setIsListEnd(false)
      if(page === 1){
        getDataList()
      }else{
        setPage(1)
      }
    }
  }

  const checkNow = (timeData) =>{
    let date = ''
    if(moment(new Date()).format('YYYY-MM-DD') === moment(timeData).format('YYYY-MM-DD')){
        date = moment(timeData).format('HH:mm:ss')
    }else{
        date = moment(timeData).format('YYYY-MM-DD')
    }
    return date;
}

  const unsubscribe = store.subscribe(()=>{
    if(store.getState().Data?.alarmCount > 0){
        getDataList();         
        setAlarmView()
    }
  });

  // useEffect(()=>{
  //   getDataList()
  //   setAlarmView()
  //   unsubscribe()
  // },[])

  // useEffect(()=>{
  //   if(isRefresh){
  //     getDataList();
  //   }
  // }, [isRefresh])

  // useEffect(()=>{
  //     getDataList();
  // }, [page])

  // useEffect(() => {
  //   if (props.refresh === true) {
  //     props.handleRefresh(false);
  //     setDataList([]);
  //     setIsListEnd(false);
  
  //     if (page === 1) {
  //       getDataList();
  //     } else {
  //       setPage(1);
  //     }
  //   }
  // }, [props.refresh, page]);

  // useEffect(() => {
  //   return () => {
  //     unsubscribe();
  //   };
  // }, [menu]);

  // useEffect(()=>{
  //   setAlarmCount(alarm)
  // }, [alarm])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        {/* <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="mdi mdi-bell-outline"></i>
          {alarm !== 0 &&
            <span className="badge bg-danger rounded-pill">{alarmCount > 9 ? '9+' : alarmCount}</span>
          }
        </DropdownToggle> */}

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16"> {props.t("알림")} {dataList.length}</h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {dataList && dataList.map((item, index) => {return (
              <div key={index} className="text-reset notification-item">
                <div className="d-flex">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title rounded-circle font-size-16" style={{backgroundColor: '#48C3B1'}}>
                      {/* <i className="mdi mdi-cart-outline"></i> */}
                    </span>
                  </div>
                  <Link to="/contract-management-screen" className="flex-1">
                    <h6 className="mt-0 mb-1" style={{color: "#000"}}>{item.subject}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{checkNow(item.created_at)}</p>
                    </div>
                  </Link>
                  <div style={{cursor: "pointer"}} onClick={()=>{
                    deleteAlarm(item.id)
                  }}>
                    <p className="text-error">X</p>
                  </div>
                </div>
              </div>
            )})
            }
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            {/* <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>
              {" "}
              {props.t("더 보기")}{" "}
            </Link> */}
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any
}