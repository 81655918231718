import { filter } from "lodash";
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import moment from 'moment';

import { useSelector } from "react-redux";

import { useHistory } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap"
import { retry } from "redux-saga/effects";
import { contractWriteResponse, contractWriteHistory, companyListCall } from "../../crud/auth.crud"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// import images
import loading from "../../assets/images/loading.gif";

import Modal from "../../components/RopikCustom/Modal";

import FileUploads from "../../components/RopikCustom/FileUploads";

const style = { backgroundColor: "#ffffff", padding: "0.5rem", cursor: "pointer", width: '466px', border: '1px solid #bdbdbd', marginTop : '0.5rem', borderRadius: '4px', color : '#333'  };

const hoverStyle = { backgroundColor: "#4DBBAA", color: "#ffffff", width: '466px', border: '1px solid #4DBBAA' };

const ContractWrite = () => {
  const selector = useSelector(state => state)
  const date = new Date();
  const newDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
  const companyData = selector.auth?.user?.member_group?.[0]?.company_group;

  const [contractDate, setContractDate] = useState(newDate);
  const [startDate, setStartDate] = useState(newDate);
  const [endDate, setEndDate] = useState(newDate);
  const [imgArr, setImgArr] = useState([]);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [company, setCompany] = useState(companyData.type === 'treatment' ? [companyData] : []);
  const [companyList, setCompanyList] = useState([]);
  const [emitterCompany, setEmitterCompany] = useState(companyData.type === 'emitter' ? companyData : {});
  const [emitterCompanyList, setEmitterCompanyList] = useState([]);
  const [collectionCompany, setCollectionCompany] = useState(companyData.type === 'collection' ? companyData : {});
  const [collectionCompanyList, setCollectionCompanyList] = useState([]);
  const [search, setSearch] = useState('');
  const [emitterSearch, setEmitterSearch] = useState('');
  const [collectionSearch, setCollectionSearch] = useState('');
  const [onUpload, setOnUpload] = useState(false);
  const [page, setPage] = useState(1);
  const [emitterPage, setEmitterPage] = useState(1);
  const [collectionPage, setCollectionPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isEmitterFetching, setIsEmitterFetching] = useState(false);
  const [isEmitterListEnd, setIsEmitterListEnd] = useState(false);
  const [isEmitterRefresh, setIsEmitterRefresh] = useState(false);
  const [isCollectionFetching, setIsCollectionFetching] = useState(false);
  const [isCollectionListEnd, setIsCollectionListEnd] = useState(false);
  const [isCollectionRefresh, setIsCollectionRefresh] = useState(false);
  const [pricePerL, setPricePerL] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [minPrice, setMinPrice] = useState();
  const [selectEmitter, setSelectEmitter] = useState(false)
  const [selectCollecter, setSelectCollecter] = useState(false)
  const [selectTreatment, setSelectTreatment] = useState(false)
  const [previewImages, setPreviewImages] = useState([])
  const history = useHistory()
  const [searchText, setSearchText] = useState()

  const [isHover, setIsHover] = useState(false); // 계약처 선택 시 마우스 호버 상태

  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);

  const isValiddate = () => {

    if (!emitterCompany || _.isEmpty(emitterCompany)) {
      return alert("배출자를 선택해주세요")
    }
    if (!collectionCompany || _.isEmpty(collectionCompany)) {
      return alert("수거업체를 선택해주세요")
    }
    if (!company || company.length === 0) {
      return alert("처리장을 추가해주세요")
    }
    if (pricePerL === 0) {
      return alert("L당 가격을 입력해주세요")
    }
    if (pricePerKg === 0) {
      return alert("Kg당 가격을 입력해주세요")
    }
    if (minPrice === 0) {
      return alert("기본요금을 입력해주세요")
    }
    if (subject === '') {
      return alert("제목을 입력해주세요")
    }

    return "success"
  }

  const handleWrite = async () => {
    const isValid = isValiddate();
    if (isValid !== 'success') {
      return isValid
    }
    setOnUpload(true)

    try {
      const fd = new FormData();

      for (let i = 0; i < imgArr.length; i++) {
        let newFile = new File([imgArr[i]], `${encodeURI(imgArr[i].name)}`, {type: imgArr[i].type})
        fd.append('file_' + i, newFile);
      }
      console.log("컴파니", company)

      const contractCompanyGroupData = {
        company: [emitterCompany, collectionCompany, ...company]
      }
      fd.append('contract_company_group_data', JSON.stringify(contractCompanyGroupData))

      const contractData = {
        company: companyData?.id,
        start_date: startDate,
        end_date: endDate,
        subject: subject,
        content: content,
        price_per_l: parseInt(pricePerL),
        price_per_kg: parseInt(pricePerKg),
        min_price: parseInt(minPrice),
        state: 'N',
        status: 'Y',
      }
      fd.append('contract_data', JSON.stringify(contractData))

      for (let key of fd.keys()) {
        console.log(key, ":", fd.get(key));
      }
      const response = await contractWriteResponse(fd);
      console.log("response.data >>>", response.data)
      if (response.data?.result === 'success') {
        console.log(response.data?.data)
        const params = {
          contract_id: response.data?.data?.id,
          company_id: companyData?.id,
          target_company_id: JSON.stringify(company),
          start_date: startDate,
          end_date: endDate,
          subject: subject,
          content: content,
          state: 'N',
          status: 'Y',
        }
        const rsHistory = await contractWriteHistory(params)
        console.log("rsHistory >>>", rsHistory)
        setOnUpload(false)
        console.log("최종등록상태 >>>", response)
        alert(response.data.msg)
        // props.route?.params?.handleTabIndex(1)                  
        // props.route?.params?.handleRefresh(true)
        history.push('/contract-management-screen')
        // await this.props.navigation.navigate('contract-management-screen', {
        //     refresh : true,                          
        // });
      } else {
        setOnUpload(false)
      }

    } catch (e) {
      setOnUpload(false)
      console.log("catch(e) :: ", e);
      if (e.response) console.log("e.response ::", e.response);
    }
  }

  const deleteImage = (index) => {    
    setImgArr(imgArr.filter((v, i) => { return i !== index;}))    
    setPreviewImages(previewImages.filter((v, i) => { return i !== index;}))
  }

  const getCompanyList = async () => {
    const params = {
      page: page,
      type: 'treatment',
      search: search
    }
    const rs = await companyListCall(params)
    if (rs.status === 200) {
      if(rs?.data?.count <= 15){
        const content = rs?.data?.results
        if (content.length > 0) {
          setCompanyList([...content])
          setIsFetching(false)
        } else {
          setCompanyList([])
          setIsFetching(false)
          setIsListEnd(true)
        }
      }else{
        const content = rs?.data?.results
        let contentSum = [...content]
        let pageTotal = Math.floor(rs?.data?.count/15);
        for(let i=2; i<=pageTotal+1; i++){
          const param = {
            page: i,
            type: 'treatment',
            search: search
          }
          const response = await companyListCall(param)
          if(response.status === 200){
            let plusContent = response?.data?.results
            contentSum = [...contentSum, ...plusContent]
          }
        }
        setCompanyList([...contentSum])
      }
    }
  }

  const getEmitterCompanyList = async () => {
    const params = {
      page: emitterPage,
      type: 'emitter',
      search: emitterSearch
    }
    const rs = await companyListCall(params);
    if (rs.status === 200) {
      if(rs?.data?.count <= 15){
        const content = rs?.data?.results
        if (content.length > 0) {
          setEmitterCompanyList([...content])
          setIsEmitterFetching(false)
        } else {
          setEmitterCompanyList([])
          setIsEmitterFetching(false)
          setIsEmitterListEnd(true)
        }
      }else{
        const content = rs?.data?.results
        let contentSum = [...content]
        let pageTotal = Math.floor(rs?.data?.count/15);
        for(let i=2; i<=pageTotal+1; i++){
          const param = {
            page: i,
            type: 'emitter',
            search: emitterSearch
          }
          const response = await companyListCall(param)
          if(response.status === 200){
            let plusContent = response?.data?.results
            contentSum = [...contentSum, ...plusContent]
          }
        }
        setEmitterCompanyList([...contentSum])
      }
    }
  }

  const getCollectionCompanyList = async () => {
    const params = {
      page: collectionPage,
      type: 'collection',
      search: collectionSearch
    }
    const rs = await companyListCall(params);
    if (rs.status === 200) {
      if(rs?.data?.count <= 15){
        const content = rs?.data?.results
        if (content.length > 0) {
          setCollectionCompanyList([...content])
          setIsCollectionFetching(false)
        } else {
          setCollectionCompanyList([])
          setIsCollectionFetching(false)
          setIsCollectionListEnd(true)
        }
      }else{
        const content = rs?.data?.results
        let contentSum = [...content]
        let pageTotal = Math.floor(rs?.data?.count/15);
        for(let i=2; i<=pageTotal+1; i++){
          const param = {
            page: i,
            type: 'collection',
            search: collectionSearch
          }
          const response = await companyListCall(param)
          if(response.status === 200){
            let plusContent = response?.data?.results
            contentSum = [...contentSum, ...plusContent]
          }
        }
        setCollectionCompanyList([...contentSum])
      }
    }
  }

  const handleFileChange = (event) => {
    const files = event.target.files;
    let prevTmp = [];
    for (let i = 0; i < files.length; i++) {
      let item = {
        name : files[i].name,
        path :  URL.createObjectURL(files[i]),        
      }
      prevTmp.push(item)
    }
    let tmp = [...imgArr, event.target.files];
    tmp = tmp.filter((v, i) => {return i - 5 < 0})    
    setImgArr(tmp)    
    prevTmp = prevTmp.filter((v, i) => {return i - 5 < 0})
    setPreviewImages(prevTmp);
  };

  const deleteFile = (item) => {
    setCompany(company.filter(data => data !== item))
  }

  const handleSearch = (str) => {
    console.log("handleSearch")
    if (str === 'emitter') {
      setEmitterCompanyList([])
      getEmitterCompanyList()
      console.log("emitterSearch")
    } else if (str === 'collection') {
      setCollectionCompanyList([])
      getCollectionCompanyList()
    } else {
      setCompanyList([])
      getCompanyList()
    }
  }

  const modalClose = () => {
    setModalShow(false)
  }

  const modalClose2 = () => {
    setModalShow2(false)
  }

  useEffect(() => {
    !selectEmitter && getEmitterCompanyList()
  }, [selectEmitter])

  useEffect(() => {
    !selectCollecter && getCollectionCompanyList()
  }, [selectCollecter])

  useEffect(() => {
    !selectTreatment && getCompanyList()
  }, [selectTreatment])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>계약서 등록 |  IoT Monitor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="계약서 등록" />
            <Col sm={6}>
              <div className="float-end d-none d-md-block page-title-box">
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs="12" style={{maxWidth: '28rem'}}>
              {/* Render Email SideBar */}
              {/* <EmailSideBar /> */}
              <div className="email-rightbar mb-3">
                <Card>
                  <CardBody style={{ padding: 24 }}>                    
                    {companyData.type !== 'emitter' &&
                      <div style={{  marginBottom: 24 }}>
                        <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px" }}>배출업체명</p>
                        <div style={{ zIndex: 2 }}>
                          <input placeholder="배출업체를 선택해주세요" type="text" className="is-untouched is-pristine av-invalid form-control" disabled="disabled" style={{ width: 240 }} value={_.isEmpty(emitterCompany) ? '' : emitterCompany.name} />
                          {selectEmitter &&
                            <>
                              <div className="position-relative app-search d-none d-lg-block" style={{ padding: 0, width: 240, borderStyle: "solid", borderWidth: 0, borderBottomWidth: 1 }}>
                                <input
                                  className="form-control"
                                  style={{ backgroundColor: "white", padding: "0.3rem" }}
                                  placeholder="검색어를 입력해주세요..."
                                  onChange={(e) => {
                                    setEmitterSearch(e.target.value.length == 0 ? '' : e.target.value)
                                    console.log(e.target.value)
                                  }}
                                  onKeyPress={(e) => {
                                    if (e.key == 'Enter') {
                                      handleSearch('emitter')
                                      console.log("검색")
                                    }
                                  }}
                                />
                                <span onClick={() => {
                                  handleSearch('emitter')
                                  console.log("검색")
                                }} className="fa fa-search" style={{ lineHeight: '38px', cursor: 'pointer' }} />
                              </div>
                              <div style={{ height: 180, position: "relative" }}>
                                <div style={{ position: "absolute", height: 186, overflowY: emitterCompanyList.length > 6 && "scroll", right: 0, left: 0, overflowX: "hidden" }}>
                                  {selectEmitter && emitterCompanyList.map((item) => {
                                    return (
                                      <div style={isHover == item.id ? { ...style, ...hoverStyle } : { ...style }} key={item.id}
                                        onMouseOver={() => {
                                          setIsHover(item.id)
                                        }}
                                        onMouseLeave={() => {
                                          setIsHover(false)
                                        }}
                                        onClick={() => {
                                          setEmitterCompany(item)
                                          setSelectEmitter(false)
                                        }}>{item.name}
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </>
                          }
                        </div>
                        <input type="button" className="btn btn-primary waves-effect waves-light" style={{ height: 35, marginLeft: 6 }} onClick={() => {
                          setEmitterSearch(''),
                            setSelectEmitter(!selectEmitter),
                            selectCollecter && setSelectCollecter(false),
                            selectTreatment && setSelectTreatment(false)
                        }} value="배출업체 선택" />
                      </div>
                    }
                    {companyData.type !== 'collection' &&
                      <div style={{  marginBottom: 24 }}>
                        <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px", marginBottom: 8 }}>수거업체명</p>
                        <div style={{ zIndex: 1, float: 'left' }}>
                          <input placeholder="수거업체를 선택해주세요"  className="is-untouched is-pristine av-invalid form-control" disabled="disabled" style={{ width: 200, height: 38 }} value={_.isEmpty(collectionCompany) ? '' : collectionCompany.name} />
                        </div>
                        <button name="aweq" type="button"
                          style={{ marginLeft: 8, height: 38 }}
                          className="btn regist-btn" 
                          onClick={()=>{
                            setModalShow(true);
                            setCollectionSearch('');
                          }}
                        >수거업체 선택</button>
                      </div>
                    }
                    <div style={{ marginBottom: 24, position: "relative" }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px", marginBottom: 8 }}>처리장명</p>
                      <input type="button" className="btn regist-btn waves-effect waves-light" style={{ height: 38 }} onClick={() => {
                          setSearch('')
                          // !selectTreatment ? setSelectTreatment(true) : setSelectTreatment(false)
                          // selectEmitter && setSelectEmitter(false)
                          // selectCollecter && setSelectCollecter(false)
                          setModalShow2(true);
                      }} value="처리장 추가" />
                      <div >
                      {company?.length > 0 && company.map((item, index) => {
                        return (
                          <div key={'company_'+index} className="btn btn-secondary waves-effect" style={{height: 38, marginRight: 8, marginTop: 8}}>
                            <div style={{display: 'flex', justifyContent: "space-between" }}>
                              <p className="noMargin" style={{ paddingLeft: 6 }}>{item.name}</p>
                              <p className="noMargin" style={{ paddingRight: 6, color: "#fff", cursor: "pointer", marginLeft: 12 }} onClick={() => {
                                deleteFile(item)
                              }}>X</p>
                            </div>
                          </div>
                        )
                      })
                      }
                      </div>
                    </div>
                    <div style={{  marginBottom: 24 }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px", marginBottom: 8 }}>계약명</p>
                      <input className="is-untouched is-pristine av-invalid form-control" onChange={(e) => {
                        if (e.target.value.length > 0) {
                          setSubject(e.target.value);
                        } else {
                          setSubject('')
                        }
                      }} />
                    </div>
                    <div style={{  marginBottom: 24 }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px", marginBottom: 8 }}>계약일자</p>
                      <input className="is-untouched is-pristine av-invalid form-control" value={contractDate} type="date" readOnly />
                    </div>
                    <div style={{  marginBottom: 24 }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px", marginBottom: 8 }}>계약기간</p>
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <input className="is-untouched is-pristine av-invalid form-control" type="date" style={{  height: 38 }} defaultValue={startDate} onChange={(e) => {
                          setStartDate(e.target.value);
                        }} />
                        <p style={{ lineHeight: '35px', marginBottom: 0 }}>&nbsp;&nbsp;~&nbsp;&nbsp;</p>
                        <input className="is-untouched is-pristine av-invalid form-control" type="date" style={{  height: 38 }} defaultValue={endDate} onChange={(e) => {
                          setEndDate(e.target.value);
                        }} />
                      </div>
                    </div>
                    <div style={{  marginBottom: 24 }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px", marginBottom: 8 }}>L당 가격</p>
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <input className="is-untouched is-pristine av-invalid form-control" placeholder="L당 가격" style={{ height: 38 }} onChange={(e) => {
                          setPricePerL(e.target.value);
                        }} />
                        <p style={{ lineHeight: '35px', marginBottom: 0  }}>&nbsp;원&nbsp;</p>
                      </div>
                    </div>
                    <div style={{  marginBottom: 24 }}>
                      <p style={{ color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px", marginBottom: 8 }}>Kg당 가격</p>
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <input className="is-untouched is-pristine av-invalid form-control" placeholder="Kg당 가격" style={{ height: 38 }} onChange={(e) => {
                          setPricePerKg(e.target.value);
                        }} />
                        <p style={{ lineHeight: '35px', marginBottom: 0  }}>&nbsp;원&nbsp;</p>
                      </div>
                    </div>
                    <div style={{  marginBottom: 24 }}>
                      <p style={{ color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px", marginBottom: 8 }}>기본요금</p>
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <input className="is-untouched is-pristine av-invalid form-control" placeholder="기본요금" defaultValue={minPrice} style={{ height: 38 }} onChange={(e) => {
                          setMinPrice(e.target.value);
                        }} />
                        <p style={{ lineHeight: '35px' }}>&nbsp;원&nbsp;</p>
                      </div>
                    </div>
                    <div style={{  marginBottom: 24 }}>
                      <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px", marginBottom: 8 }}>계약내용</p>
                      <textarea className="is-untouched is-pristine av-invalid form-control" style={{ minHeight: 86 }} onChange={(e) => {
                        if (e.target.value.length > 0) {
                          setContent(e.target.value);
                        } else {
                          setContent('')
                        }
                      }} />
                    </div>
                    <FileUploads title={'계약서 사본'} arr={previewImages} handleChange={handleFileChange} handleDelete={deleteImage} />
                    <div style={{textAlign: 'right'}}>
                    <Button
                      type="button"
                      color="silver"
                      style={{ padding: '0.4rem 1.75rem', borderRadius: '0.2rem', marginRight: 16 }}
                      onClick={() => { history.goBack() }}
                    >
                      목록으로
                    </Button>
                    <Button
                      type="button"
                      color="secondary"
                      style={{ padding: '0.4rem 1.75rem', borderRadius: '0.2rem' }}
                      onClick={() => { handleWrite() }}
                    >
                      등록하기
                    </Button>
                    </div>
                  </CardBody>

                </Card>
              </div>
            </Col>
          </Row>
          <Modal title={'업체 선택'} show={modalShow} onHide={modalClose} size="md" onSubmit={()=>{console.log(1)}}>
            <div style={{ zIndex: 1, minHeight : '300px' }}>                                                  
                <div className="position-relative app-search d-none d-lg-block" style={{ padding: 0, width: 240, borderStyle: "solid", borderWidth: 0, borderBottomWidth: 1, marginBottom : "1rem" }}>
                  <input
                    className="form-control"
                    style={{ backgroundColor: "white", padding: "0.3rem" }}
                    placeholder="검색어를 입력해주세요..."
                    onChange={(e) => {
                      setCollectionSearch(e.target.value.length == 0 ? '' : e.target.value)                      
                    }}
                    onKeyPress={(e) => {
                      if (e.key == 'Enter') {
                        handleSearch('collection')
                        console.log("검색")
                      }
                    }}
                  />
                  <span onClick={() => {
                    handleSearch('collection')
                    console.log("검색")
                  }} className="fa fa-search" style={{ lineHeight: '38px', cursor: 'pointer' }} />
                </div>                          
                <div style={{  position: "relative", minHeight : '300px' }}>
                  <div style={{position: "absolute", minHeight : '300px', overflowY: "auto", right: 0, left: 0, overflowX: "hidden"}}>
                    {collectionCompanyList && collectionCompanyList.map((item) => {
                      return (
                        <div style={isHover == item.id ? { ...style, ...hoverStyle } : { ...style }} key={item.id}
                          onMouseOver={() => {
                            setIsHover(item.id)
                          }}
                          onMouseLeave={() => {
                            setIsHover(false)
                          }}
                          onClick={() => {
                            setCollectionCompany(item)
                            modalClose()
                          }}>{item.name}
                        </div>
                      )
                    })}
                  </div>
                </div>                          
            </div>
          </Modal>
          <Modal title={'이미지 보기'} show={modalShow2} onHide={modalClose2} size="md" onSubmit={()=>{console.log(1)}}>
              <div style={{ zIndex: 1,  }}>                
                  <div className="position-relative app-search d-none d-lg-block" style={{ padding: 0, width: 240, borderStyle: "solid", borderWidth: 0, borderBottomWidth: 1 }}>
                    <input
                      className="form-control"
                      style={{ backgroundColor: "white", padding: "0.3rem" }}
                      placeholder="검색어를 입력해주세요..."
                      onChange={(e) => {
                        setSearch(e.target.value.length == 0 ? '' : e.target.value)                        
                      }}
                      onKeyPress={(e) => {
                        if (e.key == 'Enter') {
                          handleSearch('treatment')
                          console.log("검색")
                        }
                      }}
                    />
                    <span onClick={() => {
                      handleSearch('treatment')
                      console.log("검색")
                    }} className="fa fa-search" style={{ lineHeight: '38px', cursor: 'pointer' }} />
                  </div>

                  <div style={{ minHeight : '300px', position: "relative" }}>
                    <div style={{ position: "absolute", minHeight : '300px', overflowY: "auto", right: 0, left: 0, overflowX: "hidden" }}>
                      {companyList && companyList.map((item) => {
                        return (
                          <div style={isHover == item.id ? { ...style, ...hoverStyle } : { ...style }} key={item.id}
                            onMouseOver={() => {
                              setIsHover(item.id)
                            }}
                            onMouseLeave={() => {
                              setIsHover(false)
                            }}
                            onClick={() => {
                              setCompany([...company, company.map(item => { return item.name }).indexOf(item.name) == -1 && item].filter(item => item != false))
                              console.log("컴파니>>>", company)
                              setModalShow2(false)
                            }}>{item.name}
                          </div>
                        )
                      })}
                    </div>
                  </div>
              </div>
          </Modal>

        </Container>        
      </div>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

export default ContractWrite
