import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { useSelector } from "react-redux";
import store from "../../store"
import { SET_AUTH } from "../../store/auth/profile/actionTypes"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import weaver from "../../assets/images/weaver.png";
import loading from "../../assets/images/loading.gif";

import Emitter from "./Emitter";
import Collection from "./Collection";
import Treatment from "./Treatment";
import Demand from "./Demand";
import { deleteMemberFile, getMyProfile, patchCompanyDetail, postMemberFile, setNewBusinessList } from "crud/auth.crud";

const BusinessManagementScreen = props => {
  const selector = useSelector(state => state)

  // const type = selector.auth.user?.type || "";
  // const detailType = selector.auth?.user?.member_group?.[0]?.company_group.company_detail?.[0]?.type || '';
  // const company = selector.auth?.user?.member_group?.[0]?.company_group;
  const [type, setType] = useState(selector.auth.user?.type || "")
  const [detailType, setDetailType] = useState(selector.auth?.user?.member_group?.[0]?.company_group.company_detail?.[0]?.type || '')
  const [company, setCompany] = useState(selector.auth?.user?.member_group?.[0]?.company_group)
  const item = {
    ...selector.auth?.user?.member_group?.[0]?.company_group
  }
  const [isMulti, setIsMulti] = useState(type === 'emitter' && detailType === 'multi')
  const [dataList, setDataList] = useState([item])
  const [memberId, setMemberId] = useState(selector.auth.user?.id)
  const [onUpload, setOnUpload] = useState(false)

  const handleWrite = async (states) => {
    console.log("states >>>", states)
    console.log('비즈니스삭제 >>>', states.businessPartnersFileDel)
    console.log('비즈니스추가 >>>', states.businessPartnersFileAdd)
    setOnUpload(true)
    
    try {
      const fd = new FormData();    

      const vehicleList = [];
      for(let i=0; i<states.vehicles?.length; i++){                
        let item = {
          [states.vehicles[i].key] : states[states.vehicles[i].key]
        }
        vehicleList.push(item)
      }
      
      const performanceList = [];
      for(let i=0; i<states.performance?.length; i++){
        let item = {
          [states.performance[i].key] : states[states.performance[i].key]
        }
        performanceList.push(item)
      }        
      
      const recycleList = [];
      for(let i=0; i<states.recycle?.length; i++){
        let item = {
          [states.recycle[i].key] : states[states.recycle[i].key]
        }
        recycleList.push(item)
      }      

      const companyDetailData = {                
        'sector' : states.sector || '',
        'collection_sector' : states.collectionSector || '',
        'type' : states.businessType || '',
        'area' : states.area || '',
        'day_eat_count' : parseInt(states.waterPerDay) || 0,
        'day_emission' : parseInt(states.dailyEmission) || 0,
        'collection_amount' : parseInt(states.collection) || 0,
        'collection_day' : JSON.stringify(states.desiredCollectionDays) || '',
        'handling_item' : states.handlingItem || '',
        'employee' : states.employees || '',
        'vehicle' : vehicleList.length > 0 ? JSON.stringify(vehicleList) : '',
        'performance' : performanceList.length > 0 ? JSON.stringify(performanceList) : '',
        'customer' : states.businessPartners || '',
        'facility' : (states.facility1 || '') + (states.facility2 || ''),
        'capacity' : states.capacity || '',
        'recycle_performance' : recycleList.length > 0 ? JSON.stringify(recycleList) : '',
        'recycle_waste' : states.subjectToRecycling || '',
        'recycle_use' : states.recyclingPurpose || '',                
        'status' : 'Y'
      }
                  
      let fileListDel = [];            
      let fileListAdd = [];            
      
      if(states.businessPartnersFileAdd && !_.isEmpty(states.businessPartnersFileAdd)){
        const params = {
          type : 'xlsx',
          category : 'businessPartnersFile',
          company_id : company.id,
          member_id : selector.auth?.user?.id
        }

        const fd2 = new FormData();
        let businessPartnersFile = new File([states.businessPartnersFileAdd[0]], `${encodeURI(states.businessPartnersFileAdd[0].name)}`, { type: states.businessPartnersFileAdd[0].type });
        fd2.append('data', JSON.stringify(params));
        fd2.append('file_0', businessPartnersFile);
        for (let key of fd2.keys()) {
          console.log(key, "(fd2)>>>", fd2.get(key));
        }

        const response = await setNewBusinessList(fd2)
        console.log(response)
        if(response.data?.result === 'success' ){
          console.log('거래처리스트 등록결과 : ', response.data?.result)
        }else{
          return console.log("뭔가 잘못된거같음")
        }
      }

      if(states.businessPartnersFileDel && !_.isEmpty(states.businessPartnersFileDel)){
        console.log("이 로그가 뜨면 if문은 정상임")
        console.log("비즈니스파일del >>>", states.businessPartnersFileDel[0])
        fileListDel.push({file: states.businessPartnersFileDel[0], category : 'businessPartnersFile'})
      }
      
      // if(states.companyListFileAdd && !_.isEmpty(states.companyListFileAdd)){
      //   fileListAdd.push({file: states.companyListFileAdd[0], category : 'companyListFile'})
      // } 

      // if(states.companyListFileDel && !_.isEmpty(states.companyListFileDel)){                
      //   fileListDel.push({file: states.companyListFileDel[0], category : 'companyListFile'})
      // }

      // for(let i=0; i<fileArray.length; i++){
      //   if(fileArray[i].type === 'single' && !_.isEmpty(states[fileArray[i].id+'Add'])){
      //     console.log("싱글파일add >>>", states[fileArray[i].id+'Add'])
      //     fileListAdd.push({file: states[fileArray[i].id+'Add'][0], category : fileArray[i].id })
      //   }else{
      //     console.log("멀티파일add >>>", states[fileArray[i].id+'Add'])
      //     for(let j=0; j<states[fileArray[i].id+'Add']?.length; j++){
      //       fileListAdd.push({file: states[fileArray[i].id+'Add'][j], category : fileArray[i].id})
      //     }
      //   }
      //   if(fileArray[i].type === 'single' && !_.isEmpty(states[fileArray[i].id+'Del'])){
      //     console.log("싱글파일del >>>", states[fileArray[i].id+'Del'])
      //     fileListDel.push({file: states[fileArray[i].id+'Del'][0], category : fileArray[i].id })
      //   }else{
      //     console.log("멀티파일 >>>", states[fileArray[i].id+'Del'])
      //     for(let j=0; j<states[fileArray[i].id+'Del']?.length; j++){
      //       fileListDel.push({file: states[fileArray[i].id+'Del'][j], category : fileArray[i].id})
      //     }
      //   }
      // }
      
      // console.log('fileListAdd >>> ',fileListAdd)
      console.log('fileListDel >>> ',fileListDel)
      
      
      // for(let i=0; i<fileListAdd.length; i++){
      //   let v = fileListAdd[i];
      //   let newFile = new File([v.file], `${encodeURI(v.file.name)}`)
      //   fd.append('file_'+ i, newFile);
      //   fd.append('data_file_'+i, JSON.stringify({
      //     member : memberId,
      //     category : v.category,
      //     type: v.file.type
      //   }))
      // }
      for (let key of fd.keys()) {
        console.log(key, "(fd)>>>", fd.get(key));
      }
      
      // if(fileListAdd.length > 0){
      //   const response = await postMemberFile(fd)
      //   .catch((e) => {
      //     console.log('file_upload_error >>> ',e);
      //   });
      //   console.log('fileUploadResponse >>> ',response.data)
      // }
      const companyDetailResponse = await patchCompanyDetail(states.companyDetailId, companyDetailData);
      console.log('companyDetailResponse >>> ',companyDetailResponse.data)
      for(let i=0; i<fileListDel.length; i++){
        const memberFileDeleteResponse = await deleteMemberFile(fileListDel[i].file.id);
        console.log('memberFileDeleteResponse >>> ',memberFileDeleteResponse)
      }

      const memberResponse = await getMyProfile(selector.auth?.user?.id);
      store.dispatch({ type: SET_AUTH, data: [memberResponse.data] }); 
      console.log("디스패치 이후 설렉터 >>> ", selector)
      setOnUpload(false)
      return alert('정상적으로 수정되었습니다.')

    } catch (e) {
      setOnUpload(false)
      console.log(e)         
      if (e.response) console.log(e.response);
    }
  }

  useEffect(() => {
    console.log("설렉터 >>> ", selector)
    props.apiError("")
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>사업장 관리 |  IoT Monitor</title>
        </MetaTags>
        <div className="account-pages">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="사업장 관리" />

            <Row className="justify-content-center">
              <Col md={8} lg={6} style={{width: '20%', minWidth: 435}}>
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-4">
                      <h5 className="text-white font-size-20">사업장 정보 수정</h5>
                      <p className="text-white-50"></p>
                      <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                    </div>
                  </div>

                  { type === "emitter" && <Emitter handleWrite={handleWrite} />}
                  { type === "collection" && <Collection handleWrite={handleWrite} />}
                  { type === "treatment" && <Treatment handleWrite={handleWrite} />}
                  { type === "demand" && <Demand handleWrite={handleWrite} />}

                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

BusinessManagementScreen.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(BusinessManagementScreen)
