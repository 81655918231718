import PropTypes from 'prop-types'
import React,{useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap"
import "chartist/dist/scss/chartist.scss";

import Board from './Component/Board';

//i18n
import { withTranslation } from "react-i18next"
import { getUnsignDeviceSearchList, getDeviceUnsignSearchList, postDeviceUnsignPairing, postUnsignDevicePairing } from 'crud/auth.crud';

const DeviceMain = () => {

  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [pagesGroup, setPagesGroup] = useState({1: 1, 2: 1})
  const [pageArr, setPageArr] = useState({1: [], 2: []})
  const [contentCount, setContentCount] = useState({1: 0, 2: 0})

  const [pageArr1, setPageArr1] = useState()
  const [pageArr2, setPageArr2] = useState()

  const [unregisteredList, setUnregisteredList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [filteredUnregisteredList, setFilteredUnregisteredList] = useState([]);
  const [filteredStatusList, setFilteredStatusList] = useState([]);
  const [order, setOrder] = useState('id')
  const [searchType, setSearchType] = useState('')
  const [search, setSearch] = useState('')
  const [select1, setSelect1] = useState([false, ''])
  const [select2, setSelect2] = useState([false, ''])

  const makePagenation = (order, count) => {
    const currentCount = count % 15 === 0 ? count / 15 : Math.floor(count / 15) + 1
    if (!contentCount[order] || contentCount[order] !== currentCount) {
      if(currentCount === 0){
        setContentCount(1)
      }else{
        setContentCount(prevState => ({...prevState, [order]: currentCount}))
      }
      let newArr = []
      for (let i = pagesGroup[order] - 1; i < pagesGroup[order] + 4; i++) {
        if (i + 1 <= currentCount) {
          newArr.push(i + 1)
        }
      }
      if(newArr.length === 0) newArr = [1]
      setPageArr(prevState => ({...prevState, [order]: newArr}))
    }
  }

  //수정해야함
  const getDataList = async (page, domKey, searchType, search) => {
    if(domKey === 1){
      setIsLoading1(true)
    }else if(domKey === 2){
      setIsLoading2(true)
    }
    try {      
      const params = {   
        // search: search || '',     
        // page: page || 1,
        // type : searchType || '', 
        // order : order,
      }
      let response
      if(domKey === 1){
        response = await getUnsignDeviceSearchList(params)
        console.log("111",response)
      }else if(domKey === 2){
        response = await getDeviceUnsignSearchList(params)
        console.log("222",response)
        console.log("333",response.data.data)
        setPageArr2(response.data.data)
      }
      makePagenation(domKey, response.data?.count)
      if (response.status === 200) {
        const content = response.data?.results || [];
        if (content.length > 0) {
          if(domKey === 1){
            setUnregisteredList([...content])
          }else if(domKey === 2){
            setStatusList([...content])
            
          }          
        } else {
          if(domKey === 1){
            setUnregisteredList([])
          }else if(domKey === 2){
            setStatusList([])
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    if(domKey === 1){
      setIsLoading1(false)
    }else if(domKey === 2){
      setIsLoading2(false)
    }
  };

  const callback = (id, company, factory_id, unsign_iot_device_mac, domKey) => {
    if(domKey === 1){
      setSelect1([true, unsign_iot_device_mac])
    }else if(domKey === 2){
      setSelect2([true, id])
    }
  }

  const pageCallBack = async (page, domKey) => {
    getDataList(page, domKey)
  }

  const filterList = (type, searchText, domKey) => {
    getDataList(1, domKey, type, searchText)
  }

  const gearing = async () => {
    setIsLoading1(true)
    setIsLoading2(true)
    try {
      const fd = new FormData();
      const gearingData = {
        'unsign_iot_device_mac' : select1[1] || '',
        'id' : select2[1] || '',
      }
      await postDeviceUnsignPairing(gearingData)
      await postUnsignDevicePairing(gearingData)
      // fd.append('gearing_data', JSON.stringify(gearingData))
      // for (let key of fd.keys()) {
      //   console.log('연동할 기기', ">>>", fd.get(key));
      // }
      // console.log(response)
      // console.log(response_unsign)

    } catch (error) {
      console.error(error)
    }
    setIsLoading1(false)
    setIsLoading2(false)

    window.location.reload()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>IOT MONITOR</title>
        </MetaTags>
        <Container fluid>
          <Row>
            
            <Col md={6}>
            <Board
              title={'미등록 기기'}
              form={'post'}
              headers={[
                {key : 'factory_id', value: '임시 제품 등록 번호'},
                {key : 'unsign_iot_device_mac', value: '디바이스 고유 번호(UUID)'}
              ]} 
              dataList={unregisteredList} 
              callback={callback} 
              boardKey={'device'}
              height={'70vh'}
              contentCount={contentCount[1]}
              pageArr={pageArr[1]}
              domKey={1}
              isLoading={isLoading1}
              pageCallBack={pageCallBack}
              filterList={filterList} />
            </Col>

            <Col md={6}>
            <Board
              title={'단말 상태'}
              form={'post'}
              headers={[
                {key : 'id', value: '시리얼 넘버'},
                {key : 'project_name', value: '공사명'},
                {key : 'address', value: '주소'},
                // {key : 'mac', value: 'mac 주소'},
              ]} 
              dataList={pageArr2} 
              callback={callback} 
              boardKey={'status'}
              height={'70vh'}
              contentCount={contentCount[2]}
              pageArr={pageArr[2]}
              domKey={2}
              isLoading={isLoading2}
              pageCallBack={pageCallBack}
              filterList={filterList} />
            </Col>

          </Row>
          <Row>
            <Col md={6}>
            {select1[0] && 
              <Card>
                <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
                  <div className="table-responsive" style={{ overflowX: 'auto' }}>
                    <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                      <thead className="chart-thead position-relative">
                        <tr>
                          <th scope="col" className="bg-dark-ash" colSpan={1}>선택된 미등록 기기</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td scope="col">{select1[1]}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            }
            </Col>

            <Col md={6}>
            {select2[0] && 
              <Card>
                <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
                  <div className="table-responsive" style={{ overflowX: 'auto' }}>
                    <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                      <thead className="chart-thead position-relative">
                        <tr>
                          <th scope="col" className="bg-dark-ash" colSpan={1}>선택된 기기</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td scope="col">{select2[1]}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            }
            </Col>

          </Row>
          
          <div className='text-center'>
            <button
              className="btn btn-primary w-md waves-effect waves-light bg-dark-ash mx-auto"
              type="button"
              onClick={() => { select1[1] && select2[1] ? gearing() : alert('연동할 기기를 선택해주세요.') }}
            >
              기기 연동하기
            </button>
          </div>
          
        </Container>
      </div>
    </React.Fragment>
  )
}

DeviceMain.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(DeviceMain)
