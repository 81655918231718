import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// 로그인
import { getCode, login, userData } from "../../crud/auth.crud"
import store from 'store';
import { SET_CODE } from 'store/auth/data/actionTypes';
// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import { useSelector } from 'react-redux';

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import logoSm from "../../assets/images/logo-sm.png";
import weaver from "../../assets/images/weaver.png";

import persistor from "index"
import { SET_AUTH, CLEAR_AUTH } from "../../store/auth/profile/actionTypes"
import { LOGOUT_USER } from "store/auth/login/actionTypes"
import { CLEAR_DATA } from "store/auth/data/actionTypes"

const Login = props => {
  const [userId, setUserId] = useState('plt')
  const [password, setPassword] = useState('plt123')
  const selector = useSelector(state => state)
  const history = useHistory()
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {    
    //console.log(values)
    // const url = {
    //   pathname : `/dashboard`,
    //   // state : {...prevState, ...values} 
    // }
    // if(memberId && values.memberPassword == values.memberPasswordConfirm){
    //   console.log("if문 확인")
    //   history.push(url);   
    // }
  }

  const checkLogin = async ()=>{
    const params = {
        user_id : userId,
        password : password,
    }

    console.log(params)
    
    await login(params)
    .then( async (result)=>{
      console.log("result",result)
      console.log("daata",result.data)
      console.log("daata result",result.data.result)
      if(result.data.result === 'success'){
        console.log("okokokokokokok :::", result.data.data)
        
        const response = await getCode()
        // const response1 = await userData({search: 'plt5369@hanmail.net'})
        const response1 = await userData({search: userId})
        console.log("login info",response1)
        console.log("login userType",response1.data.results[0].data)
        localStorage.setItem("user_type",response1.data.results[0].data)
  
        if(response1.status === 200) {
          const loginData = localStorage.getItem('authUser');
          console.log('loginData',loginData)
          store.dispatch({ type: SET_CODE, data: [response.data]})
          store.dispatch({ type: SET_AUTH, data: [response1.data.results[0]] })
          props.loginUser(result.data.data, props.history)
        }
        
        // if(loginData){
        //     history.push('/dashboard')
        // } 
      }else{
        alert('아이디 또는 비밀번호가 일치하지 않습니다.') 
      }  

    }).catch(async (e)=>{
      console.log('error',e.response)
      console.log('error',e.response.status === 401)
      if(e.response.status === 401) {
        localStorage.clear()
        // axios.defaults.headers.common.Authorization = '';
        // axios.config.headers.Authorization = '';
        // console.log(axios.defaults)
        localStorage.removeItem('ACCESS_TOKEN')
        localStorage.removeItem('authUser');
        store.dispatch({type: CLEAR_AUTH})
        store.dispatch({type: LOGOUT_USER})
        store.dispatch({type: CLEAR_DATA})
        await persistor.purge()
        alert('인증이 만료되었습니다. 다시 로그인 해 주세요.') 
      }

    })
    // const response = await userData({search: 'plt'})

    
    
      
  }
  const [isLogin, setIsLogin] = useState(false);

  // useEffect(()=>{    
  //   const loginData = localStorage.getItem('authUser');
  //   if(loginData){
  //       history.push('/dashboard')
  //   }    
  // },[isLogin])

  return (
    <React.Fragment>
      <MetaTags>
        <title>로그인 |  IoT Monitor</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                    환영합니다 !
                        </h5>
                    <p className="text-white-50">
                      로그인 해주세요.
                        </p>
                    <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3 loginInput">
                        <AvField
                          name="userId"
                          label="아이디"
                          value="plt"
                          className="form-control"
                          placeholder="아이디를 입력해주세요."
                          type="text"
                          required
                          onChange={(e) => {
                            setUserId(e.target.value)
                          }}
                        />
                      </div>

                      <div className="mb-3 loginInput">
                        <AvField
                          name="password"
                          label="비밀번호"
                          value="plt123"
                          type="password"
                          required
                          placeholder="비밀번호를 입력해주세요."
                          onChange={(e) => {
                            setPassword(e.target.value)
                          }}
                        />
                      </div>

                      <Row className="mb-3">
                        <Col sm={6}>
                          {/* <div className="col-12" style={{marginTop: 6}}>
                          <Link to="/forgot-password">
                            <i className="mdi mdi-lock"></i> 아이디/비밀번호 찾기
                          </Link>
                        </div> */}
                          {/* <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customControlInline" />
                            <label className="form-check-label" htmlFor="customControlInline">아이디 기억하기</label>
                          </div> */}
                        </Col>
                        <Col sm={6} className="text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={() => {checkLogin()}}
                          >                            
                              로그인
                              </button>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-0 row">
                      </Row>
                      
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center">
                <p>
                  계정이 없으십니까?
                  <Link
                    to="terms"
                    className="fw-medium text-primary"
                  >
                    {" "}
                    지금 계정을 만드세요.{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} IoT Monitor
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.auth
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}