import { fromRsData, getSettlement, postTaxPdf, registTaxBill, toRsData } from "crud/auth.crud";
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';

import { Link, useLocation } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody } from "reactstrap"
import { useSelector } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import moment from "moment";
import Util from "pages/Dashboard/Common/Util";
import loading from "../../assets/images/loading.gif";

const SettlementDetails = () => {
  const location = useLocation()
  const selector = useSelector(state => state)
  const [isLoading, setIsLoading] = useState(false)
  const [dataList, setDataList] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [user, setUser] = useState(selector.auth.user)
  const [detailData, setDetailData] = useState()
  const [page, setPage] = useState(1)
  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1);
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4]);
  const [contentCount, setContentCount] = useState();
  const [pdfUrl, setPdfUrl] = useState();
  const [detailType, setDetailType] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.company_detail?.[0]?.type || "")

  const nextGetContract = async () => {
    setOnUpload(true)
    let newArr = []
    console.log("pageArr >>> ", pageArr)
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      console.log("페이지 일치 여부", page, i + 1)
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    console.log("newArr >>> ", newArr)
    setPageArr(newArr)
    setOnUpload(false)
  }

  const getDataList = async () => {
    setOnUpload(true)
    const params = {
      page: page,
      company: location.state?.companyId || user.member_group?.[0]?.company_group?.id,
    }
    console.log("params >>>", params)
    let url = '/payment_report/data_to_list/?page=' + params.page + '&company=' + params.company + '&type=collect';

    const response = await getSettlement(url);
    console.log("response >>> ", response)
    if (!contentCount || contentCount !== Math.floor(response.data.count / 15) + 1) {
      setContentCount(Math.floor(response.data.count / 15) + 1)
      let newArr = []
      for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
        console.log("페이지 일치 여부", page, i + 1)
        if (i + 1 <= Math.floor(response.data.count / 15) + 1) {
          newArr.push(i + 1)
        }
      }
      setPageArr(newArr)
    }
    if (response.status === 200) {
      const content = response.data?.results || [];
      if (content.length > 0) {
        console.log([...content])
        setDataList([...content])
      } else {
        setDataList(false)
      }
    }
    setOnUpload(false)
  }

  const clickList = async (item) => {
    const params = {
      id: item.id
    }
    const rs = await postTaxPdf(params);
    console.log("pdf 주소", rs.data)
    setDetailData(item);
    setModalShow(true)
    setPdfUrl(rs.data?.data || '')
  }

  const saveTaxBill = async () => {
        
    setIsLoading(true)
    
    const toDate = moment(new Date()).format('YYYYMMDDhhmmss');
    const mgtKey = toDate + '-' + detailData?.id;

    const fromRs = await fromRsData(detailData?.company_from.id);
    const fromData = fromRs.data || {}

    const toRs = await toRsData(detailData?.company_to.id);
    const toData = toRs.data || {}
    
    const params = {
      payment_report_id : detailData?.id,
      company_from_number : fromData.company_number,
      company_from_name : fromData.name,
      company_from_user_name : fromData.username,
      company_from_address : fromData.address1 + fromData.address2,
      company_from_email : fromData.email,
      company_from_phone : fromData.phone,
      company_from_mobile : fromData.mobile_phone,
      company_from_type : fromData.type,
      company_from_sector : fromData.type,
      company_to_number : toData.company_number,
      company_to_name : toData.name,
      company_to_user_name : toData.username,
      company_to_address : toData.address1 + toData.address2,
      company_to_email : toData.email,
      company_to_phone : toData.phone,
      company_to_mobile : toData.mobile_phone,
      company_to_fax : toData.fax,
      company_to_type : toData.type,
      company_to_sector : toData.type,
      price : parseInt(detailData?.payed_price * (10 / 11)),
      vat : detailData?.payed_price - parseInt(detailData?.payed_price * (10 / 11)),
      total_price : detailData?.total_payment_price,            
      mgt_key : mgtKey,
      mgt_date : toDate,
    }
    
    const response = await registTaxBill(params)
    console.log(response.data)
    console.log(response.data?.msg || 'nothing')
    setIsLoading(false)
  }

  const downloadPdf = () => {
    const url = pdfUrl;
    const win = window.open(url, '_blank');
    win.focus();
  };

  // const downloadPdf = () => {
  //   const url = pdfUrl;
  //   console.log(pdfUrl)
  //   fetch(url
  //     // , {mode: 'no-cors'}
  //     )
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const pdf = window.URL.createObjectURL(new Blob([blob]));
  //       const link = document.createElement('a');
  //       link.href = pdf;
  //       link.setAttribute('download', '세금계산서.pdf');
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((error) => {
  //       console.error('에러내용 >>> ', error)
  //     })
  // };

  useEffect(() => {
    nextGetContract()
  }, [pagesGroup])

  useEffect(() => {
    getDataList()
  }, [])

  return (
    // <React.Fragment>
    //   <div className="page-content">
    //     <MetaTags>
    //       <title>정산상세내역 /  IoT Monitor</title>
    //     </MetaTags>
    //     <Container fluid>
    //       {/* Render Breadcrumbs */}
    //       <Breadcrumbs maintitle="홈" title="청구서" breadcrumbItem="정산상세내역" />

    <>
    <Row>
      <Col xs="12">
        {/* Render Email SideBar */}
        {/* <EmailSideBar /> */}
        <div className="email-rightbar mb-3">
          <Card>
              <CardBody style={{padding: 0, borderRadius: 4, overflow: 'hidden'}}>                 
                <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                      <thead className="chart-thead">
                      <tr>
                        <th scope="col" >(#) <span>ID</span></th>
                        <th scope="col" >업체명</th>
                        <th scope="col" >거래일자</th>
                        <th scope="col" >배출량</th>
                        <th scope="col" >총 금액</th>
                        <th scope="col" >완납여부</th>
                      </tr>
                    </thead>
                    <tbody className="chart-tbody clickable">
                    {
                      dataList && dataList.map((item, index) => {
                        return (
                          <tr key={item.id + '_' + index} onClick={() => {clickList(item)}}>
                            <td scope="row">{item.id}</td>
                            <td>{item.company_from.name}</td>
                            <td>{moment(item.summary_date).format('YYYY-MM-DD')}</td>
                            <td>{Util.setCommaPrice(item.total_qty_l)} L</td>
                            <td>{Util.setCommaPrice(item.total_payment_price)} 원</td>
                            <td>{item.state === 'Y' || item.total_price === 0 ? '완납' : item.state === 'N' && item.total_price > 0 ? '미납' : '부분납부'}</td>
                          </tr>
                        )
                      })
                    }
                    {!dataList &&
                      <tr >
                        <td scope="row" colSpan="6">데이터가 없습니다.</td>                              
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
            <Row>
              <Col xs="12" style={{ textAlign: "center" }}>
                <div className="btn-group mt-4 waver-pagination-btn-group">
                  <Button                          
                    type="button"                          
                    size="sm"
                    className={page !== 1 ? 'waves-effect left-btn active' : 'waves-effect left-btn' }
                    onClick={() => {
                      if (page !== 1) {
                        setPage(page - 1)
                        if (Math.min(...pageArr) === page) {
                          setPagesGroup(pagesGroup - 5)
                        }
                      }
                    }}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>
                  {pageArr && pageArr.map((item, index) => {
                    return (
                      <Button
                        key={index}                              
                        type="button"
                        size="sm"
                        className={page === item ? 'waves-effect center-btn active' : 'waves-effect center-btn' }                              
                        onClick={() => {
                          setPage(item)
                        }}
                      >
                        {item}
                      </Button>
                    )
                  })}
                  <Button                        
                    type="button"                          
                    size="sm"
                    className={page !== contentCount ? 'waves-effect right-btn active' : 'waves-effect right-btn' }    
                    onClick={() => {
                      if (page !== contentCount) {
                        setPage(page + 1)
                        if (Math.max(...pageArr) === page) {
                          setPagesGroup(pagesGroup + 5)
                        }
                      }
                    }}
                  >
                    <i className="fa fa-chevron-right" />
                  </Button>
                </div>
              </Col>
            </Row>
        </div>
      </Col>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
      {modalShow && <div style={{
        position: "fixed",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(0, 0, 0, 0.4)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <div style={{ width: 480, height: 492, backgroundColor: "#fff", zIndex: 1000, padding: 36 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ fontSize: 20, color: 'black', fontWeight: 'bold' }}>납부 내역 상세</p>
            <p style={{ cursor: "pointer", fontSize: 20 }} onClick={() => {
              setModalShow(false)
            }}>X</p>
          </div>
          <div style={{ padding: 12 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>업체명</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{detailData?.company_from.name}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>당월 미정산액</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.total_price)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>이전 미정산액</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.prev_price)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>총 금액</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.total_payment_price)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>납부 금액</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.payed_price)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>미납 금액</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.total_payment_price - detailData?.payed_price)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>완납 여부</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{detailData?.state === 'Y' || detailData?.total_price === 0 ? '완납' : detailData?.state === 'N' && detailData?.total_price > 0 ? '미납' : '부분납부'}</p>
            </div>
            {detailData?.tax_state === 'Y' &&
            <button style={{ margin: "12px auto", display: "block" }} className="btn btn-primary w-md waves-effect waves-light" type="submit" onClick={() => {
              downloadPdf()
            }}>세금계산서 다운로드
            </button>
            }
            {detailData?.tax_state === 'N' && detailData?.total_payment_price === detailData?.payed_price &&
            <button style={{ margin: "12px auto", display: "block" }} className="btn btn-primary w-md waves-effect waves-light" type="submit" onClick={() => {
              saveTaxBill()
            }}>세금계산서 작성
            </button>
            }
          </div>
        </div>
      </div>}
    </Row>
    {isLoading && <div style={{
      position: "absolute",
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      background: 'rgba(255, 255, 255, 0.6)',
      zIndex: 999,
      display: "flex",
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <img src={loading} alt="로딩중입니다." width={'5%'}></img>
    </div>}
    </>
    //     </Container>
    //   </div>
    // </React.Fragment>
  )
}

export default SettlementDetails
