import {
  SET_ALARM_COUNT,
  SET_INTRO,
  SET_REFRESH,
  SET_CODE,
  SET_CONTRACT_STATE,
  SET_EXCHANGE_STATE,
  CLEAR_DATA
} from "./actionTypes"

import { persistReducer, PERSIST, PURGE } from "redux-persist";
import storage from 'redux-persist/lib/storage'

const initialState = {
  alarmCount : 0,
  intro : true,
  refresh : '',
  code : null,
  contractState : 'uncontracted',
  exchangeState : 'sell',
};

const Data = 
  persistReducer(
  { storage , key: "data", whitelist: ["code", "contractState", "exchangeState"] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_ALARM_COUNT:
        return {
          ...state,
          alarmCount : action.data,
        };
      case SET_INTRO:        
        return {
          ...state,
          intro : action.data,
        };    
      case SET_REFRESH:        
        return {
          ...state,
          refresh : action.data,
        };
      case SET_CODE:
        return {
          ...state,
          code : action.data,
        };
      case SET_CONTRACT_STATE:
        return {
          ...state,
          contractState : action.data,
        };
      case SET_EXCHANGE_STATE:
        return {
          ...state,
          exchangeState : action.data,
        };
      case CLEAR_DATA:
        return initialState
      default:
        return { ...state }     
    }
  }
);

export default Data
