import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button
} from "reactstrap"
import "chartist/dist/scss/chartist.scss";

import store from 'store';
import { SET_CODE } from 'store/auth/data/actionTypes';

import Board from './Component/Board';
import loading from "assets/images/loading.gif";

//i18n
import { withTranslation } from "react-i18next"
import { postIotDevice, postIotDeviceHistoryList, postCallSocketTypeA, postPvVoltUpdate, postUpdateIotDevice, postPositionUpdate, postAsStatusUpdate } from 'crud/auth.crud';

const Dashboard = props => {
  const location = useLocation()

  const [dataList, setDataList] = useState([]);
  const [markerList, setMarkerList] = useState([]);
  const [inputData, setInputData] = useState({});
  const [page, setPage] = useState(1);
  const [centerMarker, setCenterMarker] = useState({ lat: 36.44699, lng: 127.09598 })
  const [screenList, setScreenList] = useState(['0', false])
  const [isLoading, setIsLoading] = useState(false)
  const [contentCount, setContentCount] = useState(0)
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([])
  const [dimmingValue, setDimmingValue] = useState('')
  const [numberOfReport, setNumberOfReport] = useState('')
  const [deviceInfo, setDeviceInfo] = useState()
  const [inputUpdate, setInputUpdate] = useState({})
  const [alter, setAlter] = useState('')

  const alterArr = [
    {key : 'primary', text: '정상'},
    {key : 'alternative', text: 'AS 진행중'},
  ]

  const updatePvVolt = async() => {

    if (isNaN(parseFloat(inputUpdate['솔라 기준전압']))) {
      alert("문자는 입력할 수 없습니다.")
      return
    }

    let params = {}
    if(parseFloat(inputUpdate['솔라 기준전압']) > 5.99 ) {
      params = {
        id : location.state.id,
        pv_value : inputUpdate['솔라 기준전압'] || parseFloat(inputData.solar_reference_voltage),
      }
  
    } else {
      params = {
        id : location.state.id,
        pv_value : '7.0',
      }
  
    }

    const response = await postPvVoltUpdate(params)

    if(response.data.result == 'success'){
      alert("전송 성공")
    } else {
      alert("전송 실패")
    }

  }

  const updateAsState = async() => {
    let params = {
      id : location.state.id,
      note : inputUpdate['노트'] || inputData.note,
      process : alter === 'primary' ? 'ok' : 'AS'
    }

    const response = await postAsStatusUpdate(params)

    if(response.data.result == 'success'){
      alert("전송 성공")
    } else {
      alert("전송 실패")
    }

  }

  const updatePosition = async() => {
    let params = {
      sn : location.state.id
    }

    const response = await postPositionUpdate(params)

    if(response.data.result == 'success'){
      alert("GPS값 요청 완료.")
    } else {
      alert("GPS값 요청 오류. 잠시후 시도 해 주세요.")
    }

    window.location.reload()

  }


  const getDataList = async (page) => {
    setIsLoading(true)
    try {
      const response_device = await postIotDevice({ id: location.state.device || location.state.id})
      const content_device = response_device.data?.data || [];
      setInputData(content_device[0])
      setMarkerList(content_device)
      console.log("this device ... ",content_device)
      console.log("this device construct_at... ",content_device[0].construct_at)

      if (inputData.battery_status === 'AS' && inputData.solar_status === 'AS') {
        setAlter('alternative')
      } else {
        setAlter('primary')
      }
      
      const response = await postIotDeviceHistoryList({ iot_device_id: location.state.device || location.state.id, page: page || 1 })
      console.log("this device event list ... ",response)
      let dataCount = response.data.count % 15 === 0 ? response.data.count / 15 : Math.floor(response.data.count / 15) + 1
      if (!contentCount || contentCount !== dataCount) {
        if(dataCount === 0){
          setContentCount(1)
        }else{
          setContentCount(dataCount)
        }
        let newArr = []
        for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
          if (i + 1 <= dataCount) {
            newArr.push(i + 1)
          }
        }
        if(newArr.length === 0) newArr = [1]
        setPageArr(newArr)
      }
      if (response.status === 200) {
        const content = response.data?.results || [];
        console.log("show content...",content)
        if (content.length > 0) {
          setDataList([...content])
          setDimmingValue([...content].splice(0, 1)[0].dimming_value)
          // setNumberOfReport([...content].splice(0, 1)[0].iot_device_model.number_of_report)
          setNumberOfReport(2)

          var centerLat = [...content].splice(0, 1)[0].lat
          var centerLng = [...content].splice(0, 1)[0].lon

          if(centerLat > 38.3640 || centerLat < 33.0643 || centerLng > 124.3636 || centerLng < 131.5210 || (centerLat).isNaN() || (centerLng).isNaN()) {
            centerLat = 36.44699
            centerLng = 127.09598
          }
          setCenterMarker({ lat: centerLat, lng: centerLng })
        } else {
          setDataList([])
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false)
  };

  const updateDeviceInfo = async() => {
    let params = {
      id : location.state.id,
      // note : inputData['비고'] || inputData.note,
      company : inputUpdate['발주처'] || markerList[0].company?.name,
      comapnyDepart : inputUpdate['담당부서'] || inputData.order_department,
      address : inputUpdate['위치'] || inputData.address,
      created_at : inputUpdate['설치일'] || inputData.construct_at,
      project_name : inputUpdate['공사명'] || inputData.project_name,
      // lat : inputUpdate['위도'] || inputData.lat,
      // lng : inputUpdate['경도'] || inputData.lon,
      signage_standard : inputUpdate['크기'] || inputData.signage_standard,
      sign_content : inputUpdate['내용'] || inputData.sign_content,
      holding_type : inputUpdate['지주방식'] || inputData.holding_type,
      solar_capacity : inputUpdate['쏠라'] || inputData.solar_capacity,
      battery_capacity : inputUpdate['배터리'] || inputData.battery_capacity,
      order_company : inputUpdate['발주처'] || inputData.order_company,
      order_department : inputUpdate['담당부서'] || inputData.order_department,
    }
    // console.log("ppp:::",params)
    const response = await postUpdateIotDevice({ params : params})
    alert("정보 업데이트 요청 완료.")
    window.location.reload()

  }

  const callback = (id, company, device) => {
    // 게시판 클릭 콜백함수 로직
  }

  const pageCallBack = async (page, domKey) => {
    switch (domKey) {
      case '2':
        await getDataList(page)
        break
      default:
        null
    }
  }

  const fullScreen = (key) => {
    if (screenList[0] === key) {
      setScreenList(['0', false])
    } else {
      setScreenList([key, true])
    }
  }

  const call_socket_type_a = async () => {

    var params = {
      iot_device_id: location.state['id'],
      mac: inputData.mac,
      lte: inputData.lte,
      dimming_value: inputData.dimming_value,
      number_of_report: inputData.number_of_report,
    }

    const response = await postCallSocketTypeA(params)
  }

  const changeState = (key, value) => {
    setInputUpdate(prevState => ({ ...prevState, [key]: value }))
  }

  useEffect(() => {
  }, [pagesGroup])

  useEffect(() => {
    getDataList()
  }, [page])

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <div className='content-header d-flex align-items-center noMargin text-black font-size-18 justify-content-between'>
        <div>
          <p className='font-weight-bold'>전체</p>
        </div>
        <div className='d-flex pointer'>
          <div className='padding-left-12 padding-right-12'>
            <p>발광형 표시 단말기<i className="mdi mdi-menu-down" /></p>
          </div>
          <div className='padding-left-12 padding-right-12'>
            <p><i className="mdi mdi-devices" /> 개체</p>
          </div>
          <div className='padding-left-12 padding-right-12'>
            <p><i className="mdi mdi-filter-variant font-size-20" /> Filters</p>
          </div>
          <div className='padding-left-12 padding-right-12'>
            <p><i className="mdi mdi-clock-outline" /> 기록 - 과거 0분</p>
          </div>
          <div className='padding-left-12 padding-right-12'>
            <p><i className="mdi mdi-download" /></p>
          </div>
        </div>
      </div> */}
        <MetaTags>
          <title>IOT MONITOR</title>
        </MetaTags>
        <Container fluid>
          {/* <div className="page-title-box">
            <Row className="align-items-center">
              <Col xl={8}>
                <h6 className="page-title font-size-24">관리 홈</h6>
                <ol className="breadcrumb m-0">
                  <li className="font-size-16" style={{color : '#8A8A8A'}}>'국내최초' 음식물류 폐기물 스마트 수거 플랫폼</li>
                </ol>
              </Col>
            </Row>
          </div> */}
          <Row style={screenList[1] ? { display: 'none' } : {}}>
            <Col xl={3} lg={6}>
              <Row>
                <Col xl={12}>
                  <Card className="position-relative">
                    <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
                      <div className="table-responsive" style={{ height: '62vh', minHeight: '752px' }}>
                        <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                          <thead className="chart-thead">
                            <tr>
                              <th scope="col" className="font-size-16 bg-dark-ash">기기 정보</th>
                            </tr>
                          </thead>
                        </table>
                        <div className="w-100" style={{ padding: 24 }}>
                        <div className="mb-4">
                            <p className="noMargin text-black font-weight-bold font-size-16">SN</p>
                            <input className="is-touched is-dirty av-valid form-control mt-1" value={location.state.id} />
                          </div>
                          <div className="mb-4">
                            <p className="noMargin text-black font-weight-bold font-size-16">UUID</p>
                            <input className="is-touched is-dirty av-valid form-control mt-1" value={inputData.mac} />
                          </div>
                          <div className="mb-4">
                            <p className="noMargin text-black font-weight-bold font-size-16">상태</p>
                            <input className="is-touched is-dirty av-valid form-control mt-1" value={inputData.active_status} />
                          </div>
                          <div className="mb-4">
                            <p className="noMargin text-black font-weight-bold font-size-16">솔라 기준전압</p>
                            <div className='d-flex mt-1'>
                              <div style={{ flexGrow: 1 }}>
                                <input defaultValue={inputData.solar_reference_voltage} className="is-touched is-dirty av-valid form-control"
                                  onChange={(e) => {
                                    changeState('솔라 기준전압', e.target.value)
                                  }}
                                />
                              </div>
                              <div className="text-end">
                                <button
                                  className="btn btn-primary w-xs waves-effect waves-light bg-dark-ash margin-left-8" style={{height: 38}}
                                  onClick={() => { updatePvVolt() }}
                                >
                                  설정
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="mb-4">
                            <div className='d-flex justify-content-between'>
                                <div>
                                <div style={{ flexGrow: 1 }}>
                                <input placeholder="비고를 입력해 주세요" defaultValue={inputData.note} className="is-touched is-dirty av-valid form-control"
                                  onChange={(e) => {
                                    changeState('노트', e.target.value)
                                  }}
                                />
                              </div>
                                {alterArr && alterArr.map((item, index) => { return (
                                  <div className="form-check form-check-inline mt-2" key={item.key+'_'+index}>
                                    <input className="is-untouched is-pristine av-valid form-check-input" checked={alter == item.key} type='checkbox' id={item.key} name="alter" value={item.key} onChange={()=>{setAlter(item.key)}} />
                                    <label className="noMargin form-check-label font-size-16 text-black" htmlFor={item.key} >{item.text}</label>
                                  </div>
                                )})}
                                </div>
                                
                                
                                {/* <div className="form-check form-check-inline mt-2" key={'primary_0'}>
                                    <input className="is-untouched is-pristine av-valid form-check-input" checked={alter == 'primary'} type='checkbox' id={'primary'} name="alter" value={'primary'} onChange={()=>{setAlter('primary')}} />
                                    <label className="noMargin form-check-label font-size-16 text-black" htmlFor={'primary_0'} > 활성기기</label>
                                </div>
                                <div className="form-check form-check-inline mt-2" key={'alternative_1'}>
                                    <input className="is-untouched is-pristine av-valid form-check-input" checked={alter == 'alternative'} type='checkbox' id={'alternative'} name="alter" value={'alternative'} onChange={()=>{setAlter('alternative')}} />
                                    <label className="noMargin form-check-label font-size-16 text-black" htmlFor={'alternative_1'} > 교체대상</label>
                                </div> */}
                                <button
                                  className="btn btn-primary w-xs waves-effect waves-light bg-dark-ash mt-1"
                                  onClick={() => {updateAsState()}}
                                >
                                  설정
                                </button>
                              </div>
                          </div>
                          
                          {/* <div className="mb-4">
                            <p className="noMargin text-black font-weight-bold font-size-16">디밍 적용여부</p>
                            <Row>
                              <Col style={{flexGrow: 1}}>
                                <div className="btn-group mt-1 waver-pagination-btn-group">
                                  <Button size='sm' className='waves-effect left-btn btn btn-secondary btn-sm' style={{ backgroundColor: dimmingValue === 'Y' && '#1C2F1A', color: dimmingValue === 'Y' && 'white' }} onClick={() => {
                                    setDimmingValue('Y')
                                  }}>
                                    ㅤYㅤ
                                  </Button>
                                  <Button size='sm' className='waves-effect right-btn btn btn-secondary btn-sm' style={{ backgroundColor: dimmingValue === 'N' && '#1C2F1A', color: dimmingValue === 'N' && 'white' }} onClick={() => {
                                    setDimmingValue('N')
                                  }}>
                                    ㅤNㅤ
                                  </Button>
                                </div>
                              </Col>
                              <Col>
                              <div className="col-12 text-end">
                                <button
                                  className="btn btn-primary w-xs waves-effect waves-light bg-dark-ash mt-1"
                                  onClick={() => {
                                  }}
                                >
                                  설정
                                </button>
                              </div>
                              </Col>
                            </Row>
                          </div> */}
                          {/* <div className="mb-4">
                            <p className="noMargin text-black font-weight-bold font-size-16">교체대기</p>
                            <div className='d-flex justify-content-between'>
                              <div>
                              {alterArr && alterArr.map((item, index) => { return (
                                <div className="form-check form-check-inline mt-2" key={item.key+'_'+index}>
                                  <input className="is-untouched is-pristine av-valid form-check-input" checked={alter == item.key} type='checkbox' id={item.key} name="alter" value={item.key} onChange={()=>{setAlter(item.key)}} />
                                  <label className="noMargin form-check-label font-size-16 text-black" htmlFor={item.key} >{item.text}</label>
                                </div>
                              )})}
                              </div>
                              
                               <div className="form-check form-check-inline mt-2" key={'primary_0'}>
                                  <input className="is-untouched is-pristine av-valid form-check-input" checked={alter == 'primary'} type='checkbox' id={'primary'} name="alter" value={'primary'} onChange={()=>{setAlter('primary')}} />
                                  <label className="noMargin form-check-label font-size-16 text-black" htmlFor={'primary_0'} > 활성기기</label>
                              </div>
                              <div className="form-check form-check-inline mt-2" key={'alternative_1'}>
                                  <input className="is-untouched is-pristine av-valid form-check-input" checked={alter == 'alternative'} type='checkbox' id={'alternative'} name="alter" value={'alternative'} onChange={()=>{setAlter('alternative')}} />
                                  <label className="noMargin form-check-label font-size-16 text-black" htmlFor={'alternative_1'} > 교체대상</label>
                              </div>
                              <button
                                className="btn btn-primary w-xs waves-effect waves-light bg-dark-ash mt-1"
                                onClick={() => {
                                }}
                              >
                                설정
                              </button>
                            </div>
                          </div>
                          <div className="mb-4">
                            <p className="noMargin text-black font-weight-bold font-size-16">비고</p>
                            <input className="is-touched is-dirty av-valid form-control mt-1" defaultValue={inputData.note} onChange={(e) => { changeState('비고', e.target.value) }} />
                          </div>
                          <div className="row">
                            <div className="col-12 text-end mt-4">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light bg-dark-ash margin-right-8"
                                onClick={() => { updatePosition() }}
                              >
                                GPS 갱신
                              </button>
                              <button
                                className="btn btn-primary w-md waves-effect waves-light bg-dark-ash"
                                onClick={() => {
                                  alert("기기 재시작 요청 완료.")
                                }}
                              >
                                Reboot
                              </button>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </CardBody>
                    {isLoading && <div style={{
                      position: "absolute",
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                      background: 'rgba(255, 255, 255, 0.6)',
                      zIndex: 999,
                      display: "flex",
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      <img src={loading} alt="로딩중입니다." width={'5%'}></img>
                    </div>}
                  </Card>
                </Col>
                {/* <Col xl={12}>
                  <Board
                  title={'기기 설정 값'}
                  form={'input'}
                  height={'60vh'}
                  inputContent={[
                    // {title: 'MACADDR', value: inputData.mac},
                    // {title: 'LTEID', value: inputData.lte},
                    {title: 'Solar값', value: `"${inputData.solar_version}"`},
                    {key: 'dimming_value', title: '디밍값(dIm)', value: `"${inputData.dimming_value}"`},
                    {key: 'number_of_report', title: '일 통신횟수(nRT)', value: `"${inputData.number_of_report}"`},]} />
                </Col> */}
                {/* <Col xl={6}>
                  <Board
                    title={'Solar 설정값'}
                    form={'input'}
                    height={'15vh'}
                    inputContent={[
                      {key: 'created_at', title: '', value: `"${inputData.solar_version}"`},]} />
                </Col>
                <Col xl={6}>
                  <Board
                    title={''}
                    form={'input'}
                    height={'15vh'}
                    inputContent={[
                      {title: '', button: 'GPS Start'},]} />
                </Col> */}
              </Row>
            </Col>
            {/* <Col xl={3} lg={6}>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
                      <div className="table-responsive" style={{ height: '55vh', minHeight: '100px' }}>
                        <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                          <thead className="chart-thead position-relative">
                            <tr>
                              <th scope="col" className="font-size-16 bg-dark-ash">기기 설정 값</th>
                            </tr>
                          </thead>
                        </table>
                        <div className="w-100" style={{ padding: 24 }}>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">디밍 값 (dLM)</p>
                              <input value={dimmingValue} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setDimmingValue(e.target.value) }} />
                            </>
                          </div>
                          <div className="mb-4">
                            <>
                              <p className="noMargin text-black font-weight-bold font-size-16">일일 통신 횟수 (nRT)</p>
                              <input value={numberOfReport} className="is-touched is-dirty av-valid form-control" style={{ marginTop: 3 }} onChange={(e) => { setNumberOfReport(e.target.value) }} />
                            </>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <button
                    className="btn btn-primary w-md waves-effect waves-light font-size-16 bg-dark-ash" 
                    style={{width:'100%'}}
                    type="submit"
                    // onClick={() => { call_socket_type_a() }}
                    onClick={() => { alert("협의 후 사용 가능한 기능입니다.") }}
                  >
                      기기 설정값 업데이트
                    </button>
                </Col>
              </Row>
            </Col> */}
            <Col xl={9}>
              <Row>
                <Col xl={5} lg={6}>
                  <Board
                    title={'발주처'}
                    form={'input'}
                    height={'30vh'}
                    changeState={changeState}
                    inputContent={[
                      { title: '발주처', value: inputData.order_company },
                      { title: '담당부서', value: inputData.order_department }, 
                    ]} />
                </Col>
                <Col xl={7}>
                  <Board
                    title={'설치정보'}
                    form={'input'}
                    height={'30vh'}
                    changeState={changeState}
                    inputContent={[
                      { title: '위치', value: inputData.address },
                      { title: '공사명', value: inputData.project_name }, 
                      { title: '설치일', value: inputData.construct_at },]} />
                </Col>

              </Row>
              <Row>
                <Col xl={3} lg={6}>
                  <Board
                    title={'좌표설정'}
                    form={'input'}
                    height={'30vh'}
                    changeState={changeState}
                    inputContent={[
                      { title: '위도', value: inputData.lat },
                      // lng -> lon으로 변경
                      { title: '경도', value: inputData.lon },]} />
                </Col>
                <Col xl={5} lg={6}>
                  <Board
                    title={'표지판 규격'}
                    form={'input'}
                    height={'30vh'}
                    changeState={changeState}
                    inputContent={[
                      { title: '크기', value: inputData.signage_standard },
                      { title: '내용', value: inputData.sign_content },
                      { title: '지주방식', value: inputData.holding_type }]} />
                </Col>
                <Col xl={4}>
                  <Board
                    title={'규격 용량'}
                    form={'input'}
                    height={'30vh'}
                    changeState={changeState}
                    inputContent={[
                      { title: '쏠라', value: inputData.solar_capacity },
                      { title: '배터리', value: inputData.battery_capacity },]} />
                </Col>

              </Row>
              <button
                className="btn btn-primary w-md waves-effect waves-light font-size-16 bg-dark-ash w-100 mb-2"
                type="submit"
                // onClick={() => {alert("테스트 중인 기능입니다... 실제기기 연동시 통신됩니다.")}}
                onClick={() => {updateDeviceInfo()}}
                
              >
                기기설정 업데이트
              </button>
            </Col>
          </Row>
          <Row>
            <Col xl={screenList[0] !== '1' ? 4 : 12} style={(screenList[0] !== '1' && screenList[1]) ? { display: 'none' } : {}}>
              <Board
                title={'단말 위치'}
                form={'map'}
                dataList={markerList}
                boardKey={'device'}
                height={screenList[0] !== '1' ? '85vh' : '125vh'}
                centerMarker={centerMarker}
                domKey={'1'}
                fullScreen={fullScreen} />
            </Col>
            <Col xl={screenList[0] !== '2' ? 8 : 12} style={(screenList[0] !== '2' && screenList[1]) ? { display: 'none' } : {}}>
              <Board
                title={'단말 상태'}
                form={'post'}
                headers={[
                  { key: 'updated_at', value: '이벤트시간', class: 'current-id' },
                  { key: 'event_type', value: 'LED상태' },
                  { key: 'solar_voltage', value: '쏠라전압' },
                  { key: 'solar_reference_voltage', value: '쏠라기준전압' },
                  { key: 'battery_voltage', value: '배터리전압' },
                  { key: 'channel_output', value: 'LED 총전류값' },
                  // { key: 'digital_2', value: 'digital_2' },
                  // { key: 'digital_12', value: 'digital_12' },
                  // { key: 'pwm_dim_13', value: 'pwm_13' },
                  // { key: 'pwm_dim_14', value: 'pwm_14' },
                  // { key: 'voltage_pv', value: 'Voltage_pv' },
                  // { key: 'voltage_bat', value: 'Voltage_bat' },
                  // { key: 'led01', value: 'led 1' },
                  // { key: 'led02', value: 'led 2' },
                  // { key: 'led03', value: 'led 3' },
                  // { key: 'led04', value: 'led 4' },
                ]}
                dataList={dataList}
                callback={callback}
                boardKey={'device'}
                height={screenList[0] !== '2' ? '85vh' : '125vh'}
                domKey={'2'}
                pageCallBack={pageCallBack}
                fullScreen={fullScreen}
                isLoading={isLoading}
                contentCount={contentCount}
                pageArr={pageArr} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)
