import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

import Util from "pages/Dashboard/Common/Util";

import store from "store"
import { SET_ACCESS_TOKEN, SET_AUTH } from "store/auth/profile/actionTypes"

// availity-reactstrap-validation
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

// action
import { registerUser, apiError, registerUserFailed } from "store/actions"

import { register } from "crud/auth.crud"

// Redux
import { connect } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"

// import images
import weaver from "assets/images/weaver.png";
import loading from "assets/images/loading.gif";
// import loading from

// import components
import Emitter from "./Components/Emitter";
import Demand from "./Components/Demand";
import Collection from "./Components/Collection";
import Treatment from "./Components/Treatment";
import { compareByFieldSpec } from "@fullcalendar/react";

const RegisterStep3 = props => {
  // handleValidSubmit
  // const handleValidSubmit = (event, values) => {
  //   props.registerUser(values)
  // }

  const [prevState, setPrevState] = useState({})
  const [pushToken, setPushToken] = useState('')
  const [signFile, setSignFile] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const userType = location.state.userType

  const handlePress = async (states, fileArray) => {
    try {
      setIsLoading(true)

      const fd = new FormData();  

      const memberData = {
        'user_id' : prevState.memberId || '',
        'password' : prevState.memberPassword || '',
        'username' : prevState.memberName || '',
        'email' : prevState.memberEmail || '',
        'mobile_phone' : Util.removeHyphens(prevState.memberCellphone) || '',
        'type' : prevState.userType || '',
        'push_token' : pushToken || '',
        'status' : 'Y'
      }
  
      const companyData = {
        'name' : prevState.companyName || '',
        'username' : prevState.memberName || '',
        'email' : prevState.memberEmail || '',
        'company_number' : Util.removeHyphens(prevState.companyNumber) || '',
        'zipcode' : prevState.zipCode || '',
        'address1' : prevState.companyAddress1 || '',
        'address2' : prevState.companyAddress2 || '',
        'fax' : Util.removeHyphens(prevState.memberFax) || '',
        'phone' : Util.removeHyphens(prevState.memberTelephone) || '',
        'mobile_phone' : Util.removeHyphens(prevState.memberCellphone) || '',
        'type' : prevState.userType || '',
        'status' : 'Y'
      }
  
      const vehicleList = [];
      for(let i=0; i<prevState.vehicles?.length; i++){                
        let item = {
          [prevState.vehicles[i]] : prevState[prevState.vehicles[i]]
        }
        vehicleList.push(item)
      }            
      console.log(vehicleList)
      
      const performanceList = [];
      for(let i=0; i<prevState.performance?.length; i++){
        let item = {
          [prevState.performance[i]] : prevState[prevState.performance[i]]
        }
        performanceList.push(item)
      }        
      
      const recycleList = [];
      for(let i=0; i<prevState.recycle?.length; i++){
        let item = {
          [prevState.recycle[i]] : prevState[prevState.recycle[i]]
        }
        recycleList.push(item)
      }    
      
      const companyDetailData = {
        'driver' : prevState.driver || '',
        'sector' : prevState.sector || '',
        'type' : prevState.businessType || '',
        'area' : states.area || '',
        'day_eat_count' : parseInt(states.waterPerDay) || 0,
        'day_emission' : parseInt(states.dailyEmission) || 0,
        'collection_amount' : parseInt(states.collection) || 0,
        'collection_day' : JSON.stringify(states.desiredCollectionDays) || '',
        'handling_item' : states.handlingItem || '',
        'employee' : prevState.employees || '',
        'vehicle' : vehicleList.length > 0 ? JSON.stringify(vehicleList) : '',
        'performance' : performanceList.length > 0 ? JSON.stringify(performanceList) : '',
        'customer' : prevState.businessPartners || '',
        'facility' : (prevState.facility1 || '') + (prevState.facility2 || ''),
        'capacity' : prevState.capacity || '',
        'recycle_performance' : recycleList.length > 0 ? JSON.stringify(recycleList) : '',
        'recycle_waste' : prevState.subjectToRecycling || '',
        'recycle_use' : prevState.recyclingPurpose || '',
        'status' : 'Y'
      }
  
      fd.append('member_data', JSON.stringify(memberData))
      fd.append('company_data', JSON.stringify(companyData))
      fd.append('company_detail_data', JSON.stringify(companyDetailData))
  
      let fileList = [];    
  
      if(prevState.businessPartnersFile && !_.isEmpty(prevState.businessPartnersFile)){
        fileList.push({file : prevState.businessPartnersFile[0], category : 'businessPartnersFile'})
      } 

      if(prevState.companyTotalListFile && !_.isEmpty(prevState.companyTotalListFile)){
        fileList.push({file : prevState.companyTotalListFile[0], category : 'companyTotalListFile'})       
      }
      
      if(prevState.companyListFile && !_.isEmpty(prevState.companyListFile)){
        fileList.push({file : prevState.companyListFile[0], category : 'companyListFile'})       
      } 

      if(prevState.companyTotalListFile && !_.isEmpty(prevState.companyTotalListFile)){
        fileList.push({file : prevState.companyTotalListFile[0], category : 'companyTotalListFile'})       
      }
  
      for(let i=0; i<fileArray.length; i++){      
        if(fileArray[i].type === 'single' && !_.isEmpty(states[fileArray[i].id])){
          fileList.push({file : states[fileArray[i].id][0], category : fileArray[i].id})  
        }else{
          for(let j=0; j<states[fileArray[i].id]?.length; j++){              
            fileList.push({file : states[fileArray[i].id][j], category : fileArray[i].id}) 
          }
        }
      }
  
      console.log("fileList >>>", fileList)
      
      if (fileList.length > 0) {
        fileList.forEach((v, i) => { 
          let newFile = new File([v.file], `${encodeURI(v.file.name)}`, { type: v.file.type }) 
          fd.append('file_'+ i, newFile);
          fd.append('data_file_'+i, JSON.stringify({
            category : v.category,
            type: v.file.type
          }))
        });
        if(signFile && !_.isEmpty(signFile)){
          fd.append('file_'+fileList.length, signFile[0]);
          fd.append('data_file_'+fileList.length, JSON.stringify({
            category : 'signFile',
            type: signFile[0].type,
          }))
        }

        for (let key of fd.keys()) {
          console.log(key, ">>>", fd.get(key));
        }
        
        const response = await register(fd);
        console.log(response)
        if(response.data.result && response.data.result === 'success'){                    
          const data = response.data?.data;
          localStorage.setItem('ACCESS_TOKEN', data?.access);
          await store.dispatch({ type: SET_ACCESS_TOKEN, data: data });                    
          await store.dispatch({ type: SET_AUTH, data: [data?.profile] }); 
          setIsLoading(false)
          alert('회원가입이 완료되었습니다.')
          history.push('/login')
        }else{setIsLoading(false); alert('회원가입이 실패하였습니다.')}
      }
    } catch (e) {
      console.log(e)
      if (e.response) console.log(e.response)
    }

  }

  useEffect(() => {
    setPrevState(location.state)
    props.apiError("")
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>회원가입 |  IoT Monitor</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">회원가입</h5>
                    <p className="text-white-50"></p>
                    <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="p-3">

                  { userType === 'emitter' && <Emitter handlePress={handlePress} /> }
                  { userType === 'collection' && <Collection handlePress={handlePress} /> }
                  { userType === 'treatment' && <Treatment handlePress={handlePress} /> }
                  { userType === 'demand' && <Demand handlePress={handlePress} /> }

                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  이미 계정이 있습니까 ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    로그인
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isLoading && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

RegisterStep3.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(RegisterStep3)

