import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import { setupAxios } from "./common/util";
import axios from "axios";
import store from "./store"
import { persistStore } from "redux-persist"
import { PersistGate } from 'redux-persist/integration/react'
import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'

// import persistor from './store/auth/login/store'
// import store from './store/auth/login/store'

const persistor = persistStore(store);
export default persistor

setupAxios(axios, store);

const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()