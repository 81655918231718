import { func } from "prop-types";
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';

import { Link } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody } from "reactstrap"

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { getBusinessList, patchBusinessList, setNewBusinessList } from "crud/auth.crud";

import _ from "lodash";
import moment from "moment";

// import images
import weaver from "../../assets/images/weaver.png";
import loading from "../../assets/images/loading.gif";

const BusinessListManagementScreen = () => {
  const history = useHistory()
  const selector = useSelector(state => state)
  const newDate = new Date()
  const nextDate = new Date()

  const [dataList, setDataList] = useState([]);
  const [sumList, setSumList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [pricePerL, setPricePerL] = useState(50);
  const [user, setUser] = useState(selector.auth?.user);
  const [company, setCompany] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.id);
  const [startDate, setStartDate] = useState(moment(newDate).format('YYYY-MM') + '-01 00:00:00');
  const [endDate, setEndDate] = useState(moment(nextDate.setMonth(nextDate.getDate()+1)).format('YYYY-MM') + '-01 00:00:00');
  const [modalShow, setModalShow] = useState(false);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(4);
  const [totalElements, setTotalElements] = useState(4);
  const [page, setPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const [companyListFile, setCompanyListFile] = useState(undefined);
  const [onUpload, setOnUpload] = useState(false);

  const getDataList = async () => {
    try {            
      const response = await getBusinessList(`?id=${company}`);    
      if (response.data?.result === 'success') {
        const dataList = response?.data?.data;
        setDataList(dataList.filter(item => item.id !== company))               
      }
    } catch (error) {
      console.error(error);
    }
  }

  const deleteChildrenCompany = async (id) =>{
    if(confirm("해당 사업장을 삭제하시겠습니까?")){
      const params = {parents_company : null}
      await patchBusinessList(id, params)
      getDataList()
    }
  }

  // const deleteFile = () => {}

  const changebusiness_list = async () => {
    setOnUpload(true)
                                        
    try {
      const params = {
        type : 'xlsx',
        category : 'companyListFile',
        company_id : company,
        member_id : user.id
      } 
      const fd = new FormData();
      fd.append('data', JSON.stringify(params));
      const newFile = new File([companyListFile[0]], `${encodeURI(companyListFile[0].name)}`, { type: companyListFile[0].type })
      if(companyListFile && !_.isEmpty(companyListFile)){
        fd.append('file_0', newFile);
      }

      const response = await setNewBusinessList(fd)
      if(response.data?.result === 'success' ){
        getDataList()
      }
        

    } catch (e) {   
        console.log(e)         
        if (e.response) console.log(e.response);
    }
    setOnUpload(false)
  }

  const registFiles = (e) => {
    setCompanyListFile([...e.target.files])
    // let filteredFiles
    // for(let i=0;i<e.target.files.length;i++){
    //   if(companyListFile?.length > 0 && companyListFile?.map(item => item.name).indexOf(e.target.files[i].name) != -1){
    //     filteredFiles = (filteredFiles || [...e.target.files]).filter(data => data !== [...e.target.files][i]);
    //   }
    // }
    // setCompanyListFile([...companyListFile || '', ...(filteredFiles || e.target.files)])
  }

  const deleteFile = (item) => {
    setCompanyListFile(companyListFile.filter(data => data !== item))
  }

  useEffect(()=>{
    getDataList()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
      <MetaTags>
        <title>사업장 목록 관리 |  IoT Monitor</title>
      </MetaTags>
      <div className="account-pages">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="사업장 목록 관리" />

          <Row className="justify-content-center">
            <Col md={8} lg={6} style={{width: '20%', minWidth: 435}}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">사업장 목록 관리</h5>
                    <p className="text-white-50"></p>
                    <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="p-3">

                    <div>
                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: 'black'}}>다수사업장 리스트 업로드<br />(변경사항 샘플 엑셀 참조)</p>
                      </div>
                      <div className="mb-3">
                        <label className="btn btn-primary waves-effect waves-light" htmlFor="ex_file" style={{height: 36, marginLeft: 6}}>파일 선택</label>
                        <input style={{display: "none"}} type="file" accept=".xls,.xlsx" id="ex_file" onChange={(e)=>{
                          registFiles(e)
                        }}/> 
                        {companyListFile?.length > 0 && companyListFile.map((item, index) => {
                          return (
                            <div key={index} style={{border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6}}>
                              <p className="noMargin" style={{paddingLeft: 6}}>{item.name}</p>
                              <p className="noMargin" style={{paddingRight: 6, color: "#000", cursor: "pointer"}} onClick={() => {
                                deleteFile(item)
                              }}>X</p>
                            </div>
                          )
                        })
                        }
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <div className="col-12 text-end">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                          onClick={ async () => {
                            changebusiness_list()
                          }}
                        >
                          사업장 변경 등록
                        </button>
                      </div>
                    </div>
                      
                    {dataList && dataList.map((item, index)=>{return (
                      <div key={index} style={{marginHorizontal: 20}}>
                        <div style={{padding: 12, marginBottom: 12, border: "1px solid #48C3B1", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderRadius: 5, alignItems: "center"}} >
                          <div>
                            <p style={{fontSize: 20, color: 'black', fontWeight: 'bold', width: 180, margin: 0}}>{item.name}</p>
                          </div>
                          <div style={{zIndex: 10}} onClick={()=>{deleteChildrenCompany(item.id)}}>
                            <p style={{color: '#ff4949', fontSize: 16, margin: 0, cursor: 'pointer'}}>{'X'}</p>
                          </div>                    
                        </div>
                      </div>
                    )})}

                      {/* {!pwModalShow &&
                      <div style={{display: 'flex'}}>
                        <div className="mb-3" style={{position: "relative"}}>
                          <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>비밀번호</p>
                          <input value={'********'} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} disabled />
                        </div>
                        <input type="button" className="btn btn-primary waves-effect waves-light" style={{height: 35, marginTop: 30, marginLeft: 6}} onClick={() => {
                          setPwModalShow(true)
                        }} value="비밀번호 변경" />
                      </div>
                      }

                      {pwModalShow &&
                      <>
                        <div className="mb-3" style={{position: "relative"}}>
                          <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>비밀번호</p>
                          <input placeholder="현재 비밀번호" className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                            setOldPassword(e.target.value)
                          }} />
                          <input placeholder="새 비밀번호" className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                            setPassword(e.target.value)
                          }} />
                          <input placeholder="새 비밀번호 확인" className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                            setPasswordConfirm(e.target.value)
                          }} />
                        </div>
                        <div style={{display: 'flex'}}>
                          <input type="button" className="btn btn-warning waves-effect waves-light" style={{height: 35, marginTop: 30, marginLeft: 6}} onClick={async () => {
                            await pwChange()
                          }} value="비밀번호 변경" />
                          <input type="button" className="btn btn-primary waves-effect waves-light" style={{height: 35, marginTop: 30, marginLeft: 6}} onClick={() => {
                            setPwModalShow(false)
                          }} value="취소" />
                        </div>
                      </>
                      }

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>업체명</p>
                        <input defaultValue={companyName} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                          setCompanyName(e.target.value)
                        }} />
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>대표자명</p>
                        <p style={{fontSize: 16, color: 'black', paddingTop: 6}}>{memberName}</p>
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>사업자등록번호</p>
                        <p style={{fontSize: 16, color: 'black', paddingTop: 6}}>{companyNumber}</p>
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <div style={{display: 'flex'}}>
                          <div>
                            <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>주소</p>
                            <input value={zipCode} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} disabled />
                          </div>
                          <input type="button" className="btn btn-primary waves-effect waves-light" style={{height: 35, marginTop: 30, marginLeft: 6}} onClick={() => {
                            if(!modalShow){
                              setModalShow(true)
                            }else{
                              setModalShow(false)
                            }
                          }} value="주소 찾기" />
                        </div>
                        <div className="address_search" >
                          {modalShow && <Post></Post>}
                        </div>
                        <input className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} value={companyAddress1} disabled />
                        <input defaultValue={companyAddress2} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                          setCompanyAddress2(e.target.value)
                        }} />
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>이메일</p>
                        <input defaultValue={memberEmail} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                          setMemberEmail(e.target.value)
                        }} />
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>전화번호</p>
                        <input defaultValue={memberTelephone} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                          setMemberTelephone(e.target.value)
                        }} />
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>팩스</p>
                        <input defaultValue={memberFax} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                          setMemberFax(e.target.value)
                        }} />
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>휴대폰 번호</p>
                        <input defaultValue={memberCellphone} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                          setMemberCellphone(e.target.value)
                        }} />
                      </div>

                      <div className="mb-3" style={{position: "relative"}}>
                        <p className="noMargin" style={{fontSize: 16, color: '#9a9a9a'}}>서명 이미지</p>
                        <div style={{display: "flex"}}>
                        {signFile?.path ?
                        <img src={"http://Iot_monitor.ropiklabs.com" + signFile.path} style={{height: 48, border: "1px solid #ced4da", borderRadius: "0.25rem"}} />
                        :
                        <img src={signFile} style={{height: 48, border: "1px solid #ced4da", borderRadius: "0.25rem"}} />
                        }
                        <label className="btn btn-primary waves-effect waves-light" htmlFor="sign" style={{height: 36, marginLeft: 6}}>파일 선택</label>
                        <input id="sign" type="file" accept="image/*" style={{display: "none"}} onChange={saveImgFile} />
                        </div>
                      </div>

                      <div className="mb-3 row">
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={ async () => {
                              handleSubmit()
                            }}
                          >
                              회원정보 변경하기
                          </button>
                        </div>
                      </div> */}
                      

                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                        </div>
                      </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      </div>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

// business-list-management-screen.propTypes = {
//   registerUser: PropTypes.func,
//   registerUserFailed: PropTypes.func,
//   registrationError: PropTypes.any,
//   user: PropTypes.any,
// }

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default BusinessListManagementScreen

// export default connect(mapStatetoProps, {
//   registerUser,
//   apiError,
//   registerUserFailed,
// })(business-list-management-screen)
