import React, { useEffect, useState } from "react"
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import store from "store";

class Collection extends React.PureComponent {

    constructor(props) {
        super(props);        
        this.state = {
            collectionSector : '',
            handlingItem : '',
            employees : '',
            vehicles : [],
            special5 : '',
            cargo35 : '',
            cargo12 : '',
            performance : [],
            businessPartners : '',
            inputText: '',
            previous: '',
            current: '',
            monthlycurrent: '',
            businessPartnersFile: []
        }
        const code = store.getState().Data?.code[0] || []
        this.collectionSectors = code.filter(item => item.code === 'collectionSectors')
        this.handlingItems = code.filter(item => item.code === 'handlingItems')
        this.employees = code.filter(item => item.code === 'employees')
        this.vehicles = code.filter(item => item.code === 'vehicles')
        this.performances = code.filter(item => item.code === 'performances')
        this.businessPartners = code.filter(item => item.code === 'businessPartners')
    }

    deleteFile = (str) =>{
        this.setState({ [str] : undefined})
    }    

    isValiddate = () => {  
        
        if(this.state.sector === ''){
            alert("업종을 선택 해주세요.")
            return("")
        }

        if(this.state.handlingItem === ''){
            alert("취급품목을 선택해주세요.")
            return("")
        }

        if(this.state.employees === ''){
            alert("종업원수를 선택해주세요.")
            return("")
        }

        for(let i=0; i<this.state.vehicles.length; i++){
            const item = this.state.vehicles[i];
            if(this.state[item] === ''){
                // alert(item.text.replace("(", "")+" 수량을 입력해주세요.")
                alert("보유차량 수를 입력해주세요.")
                return("")
            }
        }
        
        for(let i=0; i<this.state.performance.length; i++){
            const item = this.state.performance[i];
            if(this.state[item] === ''){
                // alert("처리실적 "+ item.text.replace("(", "")+" 값을 입력해주세요.")
                alert("처리실적을 입력해주세요.")
                return("")
            }
        }

        if(this.state.businessPartners === ''){
            alert("거래처 수를 선택해주세요.")
            return("")
        }

        if(this.state.businessPartnersFile.length < 1){
            alert("거래처 리스트를 업로드해주세요.")
            return("")
        }

        return "success"
    }

    ifVehiclesUnchecked = (e) => {
        this.setState({vehicles : this.state.vehicles.filter(item => item !== e.target.id), [e.target.id] : ''})                          
    }

    ifPerformancesUnchecked = (e) => {
        this.setState({performance : this.state.performance.filter(item => item !== e.target.id), [e.target.id] : ''})                          
    }

    registFiles = (e) => {
        this.setState({businessPartnersFile : [...e.target.files]})
    }

    deleteFile = () => {
        this.setState({businessPartnersFile : []})
    }

    fileDownload = async (str) => {
        let url = `https://waveradmin.ropiklabs.com/media/sample/${str}.xlsx`
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const excelUrl = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = excelUrl;
                link.setAttribute('download', `${str}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.error(error)
            })
    };

    render() {
        return (
        <>
            <div className="mb-3">
                <legend>업종선택</legend>
            {   
            this.collectionSectors && this.collectionSectors.map((item, index) => { return (
                <div className="form-check form-check-inline" key={item.key+'_'+index}>
                    <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name="sectors" value={item.key} onChange={()=>{this.setState({collectionSector : item.key})}} />
                    <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
            )})
            }
            </div>

            <div className="mb-3">
                <legend>취급품목</legend>
            {   
            this.handlingItems && this.handlingItems.map((item, index) => { return (
                <div className="form-check form-check-inline" key={item.key+'_'+index}>
                    <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name="handlingItems" value={item.key} onChange={()=>{this.setState({handlingItem : item.key})}} />
                    <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
            )})
            }
            </div>
            
            <div className="mb-3">
                <legend>종업원 수</legend>
            {   
            this.employees && this.employees.map((item, index) => { return (
                <div className="form-check form-check-inline" key={item.key+'_'+index}>
                    <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name="employees" value={item.key} onChange={()=>{this.setState({employees : item.key})}} />
                    <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
            )})
            }
            </div>

            <div className="mb-3">
                <legend>보유차량 수</legend>
            {   
            this.vehicles && this.vehicles.map((item, index) => { return (
                <div className="form-check form-check-inline" style={{display: "flex"}} key={item.key + '_' + index}>
                    <input className="is-untouched is-pristine av-valid form-check-input" type='checkbox' id={item.key} name="vehicles" value={item.key} onChange={(e)=>{   
                            e.target.checked ? this.setState({vehicles : [ ...this.state.vehicles, item.key]}) : this.ifVehiclesUnchecked(e)
                        }}
                    />
                    <label className="form-check-label" htmlFor={item.key} style={{marginLeft: '0.5rem'}}>{item.text}</label>
                    <p style={{fontWeight: 200, lineHeight: 1.5}}>{' ('}</p>
                    <input style={{width: 64, height: 23, padding: 6, marginLeft: '0.5rem', marginRight: 6}} className="form-control" value={this.state[item.key]} disabled={this.state.vehicles.indexOf(item.key) > -1 ? false : "disabled"}
                        onChange={(e)=>{
                            this.setState({[item.key] : e.target.value})
                        }}/>
                    <p style={{fontWeight: 200, lineHeight: 1.5}}>대)</p>
                </div>
            )})
            }
            </div>

            <div className="mb-3">
                <legend>처리실적</legend>
            {   
            this.performances && this.performances.map((item, index) => { return (
                <div className="form-check form-check-inline" style={{display: "flex"}} key={item.key+'_'+index}>
                    <input className="is-untouched is-pristine av-valid form-check-input" type='checkbox' id={item.key} name="performances" value={item.key} onChange={(e)=>{   
                            e.target.checked ? this.setState({performance : [ ...this.state.performance, item.key]}) : this.ifPerformancesUnchecked(e)
                        }}/>
                    <label className="form-check-label" htmlFor={item.key} style={{marginLeft: '0.5rem'}}>{item.text}</label>
                    <p style={{fontWeight: 200, lineHeight: 1.5}}>{' ('}</p>
                    <input style={{width: 64, height: 23, padding: 6, marginLeft: '0.5rem', marginRight: 6}} className="form-control" value={this.state[item.key]} disabled={this.state.performance.indexOf(item.key) > -1 ? false : "disabled"}
                        onChange={(e)=>{
                            this.setState({[item.key] : e.target.value})
                        }}></input>
                    <p style={{fontWeight: 200, lineHeight: 1.5}}>Ton)</p>
                </div>
            )})
            }
            </div>

            <div className="mb-3">
                <legend>거래처 수</legend>
            {   
            this.businessPartners && this.businessPartners.map((item, index) => { return (
                <div className="form-check form-check-inline" key={item.key+'_'+index}>
                    <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} name="businesspartners" value={item.key} onChange={()=>{this.setState({businessPartners : item.key})}} />
                    <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
            )})
            }
            </div>

            <div>
                <div className="mb-3">
                    <p className="noMargin" style={{fontSize: 18, color: 'black', marginTop: 12}}>거래처 리스트 업로드</p>
                </div>
                <div className="mb-3">
                    <p style={{marginBottom: 6, color: "black", fontSize: 15}}>- 거래처명/대표자명/주소/연락처/올바로등록대상업체 여부 포함 필수</p>
                    <label className="btn btn-primary waves-effect waves-light" htmlFor="ex_file" style={{height: 36, marginLeft: 6}}>파일 선택</label>
                    <label className="btn btn-primary waves-effect waves-light" style={{ height: 36, marginLeft: 6 }} onClick={() => { this.fileDownload('거래처등록엑셀') }}>샘플 받기</label>
                    <input style={{display: "none"}} type="file" accept=".xls,.xlsx" id="ex_file" onChange={(e)=>{
                        this.registFiles(e)
                    }}/> 
                    {this.state.businessPartnersFile?.length > 0 && this.state.businessPartnersFile.map((item, index) => {
                    return (
                        <div key={index} style={{border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6}}>
                        <p className="noMargin" style={{paddingLeft: 6}}>{item.name}</p>
                        <p className="noMargin" style={{paddingRight: 6, color: "#000", cursor: "pointer"}} onClick={() => {
                            this.deleteFile()
                        }}>X</p>
                        </div>
                    )
                    })
                    }
                </div>
            </div>

            <div className="mb-3 row">
                <div className="col-12 text-end">
                    <button
                    className="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                    onClick={()=>{
                        if(this.isValiddate() === 'success'){
                        this.props.handlePress({
                        ...this.state
                    })}}}
                    >                        
                        회원가입 완료하기                   
                    </button>
                </div>
            </div>
        </>
        );
    }
};

export default Collection;