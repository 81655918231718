import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import rootReducer from "./reducers"
import rootSaga from "./sagas"
import storage from 'redux-persist/lib/storage'/*로컬스토리지*/
import { configureStore } from '@reduxjs/toolkit'

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistConfig = {
  key: 'root',
  version: 1,
  storage, /* rootDuck에 있는 rootReducer값을 로컬스토리지에 저장할것인지 세션스토리지 저장할것인지 변경. */
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, REGISTER],
      },
    }).concat(sagaMiddleware),
})
// const store = createStore(
//   persistedReducer,
//   composeEnhancers(applyMiddleware(sagaMiddleware))
// )
sagaMiddleware.run(rootSaga)

export default store
