import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { useSelector } from "react-redux";
import store from "../../store"
import { SET_AUTH } from "../../store/auth/profile/actionTypes"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

import { companyResponse, memberResponse, signChange, passwordChange, postSignFile, patchCompanyData, patchProfileData } from "../../crud/auth.crud"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import Util from "pages/Dashboard/Common/Util";

// import images
import weaver from "../../assets/images/weaver.png";
import loading from "../../assets/images/loading.gif";

// 주소 찾기
import DaumPostcode from 'react-daum-postcode';

const ProfileScreen = props => {
  const selector = useSelector(state => state)

  const [memberId, setMemberId] = useState('');
  const [memberName, setMemberName] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyAddress1, setCompanyAddress1] = useState('');
  const [companyAddress2, setCompanyAddress2] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [memberEmail, setMemberEmail] = useState('');
  const [memberTelephone, setMemberTelephone] = useState('');
  const [memberFax, setMemberFax] = useState('');
  const [memberCellphone, setMemberCellphone] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [dateModalShow, setDateModalShow] = useState(false);
  const [viewHeight3, setViewHeight3] = useState(0);
  const [myProfileData, setMyProfileData] = useState(selector.Profile?.auth?.[0] || selector.auth?.user || {});
  const [companyData, setCompanyData] = useState({});
  const [pwModalShow, setPwModalShow] = useState(false);
  const [signFile, setSignFile] = useState(undefined);
  const [oldSignFile, setOldSignFile] = useState(undefined);
  const [signFilePreview, setSignFilePreview] = useState('');  
  const [onUpload, setOnUpload] = useState(false);
  
  useEffect(() => {            
    const companyData = myProfileData?.member_group?.[0]?.company_group;
    setCompanyData(companyData || {}),
    setMemberId(myProfileData['user_id'] || '')
    setMemberName(myProfileData['username'] ||'')
    setCompanyName(companyData['name'] || '')
    setMemberEmail(myProfileData['email'] || '')
    setCompanyNumber(companyData['company_number'] || '')
    setZipCode(companyData['zipcode'] || '')
    setCompanyAddress1(companyData['address1'] || '')
    setCompanyAddress2(companyData['address2'] || '')
    setMemberTelephone(companyData['phone'] || '')
    setMemberFax(companyData['fax'] || '')
    setMemberCellphone(companyData['mobile_phone'] || '')
    setPassword('')
    setOldPassword('')
    setSignFile(myProfileData['member_file']?.filter(item => item.category === 'signFile')?.[0])
    setSignFilePreview(myProfileData['member_file']?.filter(item => item.category === 'signFile')?.[0])
    setOldSignFile(myProfileData['member_file']?.filter(item => item.category === 'signFile')?.[0])
  }
, [])

  const handleSubmit = async () => {
    setOnUpload(true)
  
    const email = memberEmail || myProfileData.email;
    const phone = memberTelephone || companyData.phone;
    const fax = memberFax || companyData.fax;
    const mobile = memberCellphone || companyData.mobile_phone;
    const newCompanyName = companyName || companyData.company_name;
    const newZipCode = zipCode || companyData.zipcode;
    const newCompanyAddress1 = companyAddress1 || companyData.address1;
    const newCompanyAddress2 = companyAddress2 || companyData.address2;
  
    const memberParams = { email, mobile_phone: mobile };
    const companyParams = {
      email,
      phone,
      fax,
      mobile_phone: mobile,
      name: newCompanyName,
      zipcode: newZipCode,
      address1: newCompanyAddress1,
      address2: newCompanyAddress2,
    };
  
    if (signFile && !_.isEmpty(signFile) && signFile !== oldSignFile) {
      const fd = new FormData();      
      fd.append('file_0', signFile);
      fd.append('data_file_0', JSON.stringify({
        id: oldSignFile.id,
        member: myProfileData.id,
        category: 'signFile',
        type: oldSignFile.type,
      }));
      for (let key of fd.keys()) {
        console.log(key, ">>>", fd.get(key));
      }
      const response = await postSignFile(fd)
        .catch((e) => {
          console.log(e);
        });
  
      console.log('fileResponse >>> ', response);
    }
  
    const companyResponse = await patchCompanyData(companyData.id, companyParams);
    console.log('companyResponse >>> ', companyResponse.data);
  
    const memberResponse = await patchProfileData(myProfileData.id, memberParams);
    console.log('memberResponse >>> ', memberResponse)
    console.log('memberResponse >>> ', memberResponse.data);

    await store.dispatch({ type: SET_AUTH, data: [memberResponse.data] });
    setOnUpload(false)
    return alert('정상적으로 수정되었습니다.')
  }

  const pwChange = async () => {
    if(password !== passwordConfirm){
      return alert('새 비밀번호와 비밀번호 확인이 같지 않습니다.')
    }
    setOnUpload(true)
    const params = {
        id : myProfileData['id'],
        old_password : oldPassword,
        password : password,
    }
    console.log("비밀번호 변경 데이터", params)
    const rs = await passwordChange(params)
    console.log("rs :::", rs)
    if(rs.data?.result === 'success'){
      setOldPassword('')
      setPassword('')
      setPasswordConfirm('')
      setPwModalShow(false)
      setOnUpload(false)
      alert("비밀번호가 정상적으로 변경되었습니다.")
    }else{
      setOnUpload(false)
      alert(rs?.data?.msg || "비밀번호를 정확히 입력해주세요.")
    }
  }

  // useEffect(() => {
  //   props.apiError("")
  // }, []);

  useEffect(()=>{
    console.log("selector >>>", selector)
    console.log("selector.Profile?.auth >>>", selector.Profile?.auth)
  }, [])

  useEffect(()=>{
    setModalShow(false)
  }, [companyAddress1])

  // 주소찾기 API
  const Post = () => {

    const complete = (data) =>{
      // data.zonecode는 우편번호, data.roadAddress는 도로명주소
      let zipAddress = data.zonecode;
      let roadAddress = data.roadAddress;
      console.log("우편번호 ::", data.zonecode)
      console.log("도로명주소 ::", data.roadAddress)

      setZipCode(zipAddress)
      setCompanyAddress1(roadAddress)
    }

    return (
      <div>
        <DaumPostcode
          className="postmodal"
          autoClose
          onComplete={complete} />
      </div>
    );
  };

  // const saveImgFile = (event) => {
  //   setSignFile(event.target.files[0])
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     setSignFilePreview(reader.result);
  //   };
  // };

  return (
    <React.Fragment>
      <div className="page-content">
      <MetaTags>
        <title>회원정보 |  IoT Monitor</title>
      </MetaTags>
      <div className="account-pages">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="회원정보" />

          <Row className="">
            <Col md={8} lg={3} style={{minWidth: 435}}>
              <Card className="overflow-hidden">
                <CardBody className="p-4">
                  <div className="p-3">

                      <div className="mb-4">
                        <p className="noMargin text-black font-weight-bold font-size-16">아이디</p>
                        <p className="text-black font-size-16 padding-top-6">{memberId}</p>
                      </div>

                      {!pwModalShow &&
                      <div className="d-flex mb-4">
                        <div style={{flexGrow: 1}}>
                          <p className="noMargin text-black font-weight-bold font-size-16">비밀번호</p>
                          <input type="password" value={'●●●●●●●●'} className="is-touched is-dirty av-valid form-control margin-top-8 font-size-16" disabled />
                        </div>
                        <div className="waver-pagination-btn-group" >
                          <button className="waves-effect submit-btn btn btn-secondary margin-top-35 margin-left-6 font-size-16"
                            onClick={() => {
                            setPwModalShow(true)
                            }}
                          >비밀번호변경</button>
                        </div>
                      </div>
                      }

                      {pwModalShow &&
                      <>
                        <div className="mb-4">
                          <p className="noMargin text-black font-weight-bold font-size-16">비밀번호</p>
                          <input type="password" placeholder="현재 비밀번호" className="is-touched is-dirty av-valid form-control margin-top-8 font-size-16"
                          onChange={(e)=>{
                            setOldPassword(e.target.value)
                          }} />
                          <input type="password" placeholder="새 비밀번호" className="is-touched is-dirty av-valid form-control margin-top-8 font-size-16" onChange={(e)=>{
                            setPassword(e.target.value)
                          }} />
                          <input type="password" placeholder="새 비밀번호 확인" className="is-touched is-dirty av-valid form-control margin-top-8 font-size-16" onChange={(e)=>{
                            setPasswordConfirm(e.target.value)
                          }} />
                          <div className="d-flex margin-top-20">
                            <button className="waves-effect btn btn-silver font-size-16" onClick={() => {
                              setPwModalShow(false)
                            }}>취소</button>
                            <button className="waves-effect btn btn-primary font-size-16" onClick={async () => {
                              await pwChange()
                            }}>비밀번호변경</button>
                          </div>
                        </div>
                      </>
                      }

                      <div className="mb-4">
                        <p className="noMargin text-black font-weight-bold font-size-16">업체명</p>
                        <input defaultValue={companyName} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                          setCompanyName(e.target.value)
                        }} />
                      </div>

                      <div className="mb-4">
                        <p className="noMargin text-black font-weight-bold font-size-16">대표자명</p>
                        <p style={{fontSize: 16, color: 'black', paddingTop: 6}}>{memberName}</p>
                      </div>

                      <div className="mb-4">
                        <p className="noMargin text-black font-weight-bold font-size-16">사업자등록번호</p>
                        <p style={{fontSize: 16, color: 'black', paddingTop: 6}}>{Util.checkBusinessNumber(companyNumber)}</p>
                      </div>

                      <div className="mb-4">
                        <div style={{display: 'flex'}}>
                          <div>
                            <p className="noMargin text-black font-weight-bold font-size-16">주소</p>
                            <input value={zipCode} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} disabled />
                          </div>
                          <input type="button" className="btn btn-primary waves-effect waves-light" style={{height: 35, marginTop: 30, marginLeft: 6}} onClick={() => {
                            if(!modalShow){
                              setModalShow(true)
                            }else{
                              setModalShow(false)
                            }
                          }} value="주소 찾기" />
                        </div>
                        <div className="address_search" >
                          {modalShow && <Post></Post>}
                        </div>
                        <input className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} value={companyAddress1} disabled />
                        <input defaultValue={companyAddress2} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                          setCompanyAddress2(e.target.value)
                        }} />
                      </div>

                      <div className="mb-4">
                        <p className="noMargin text-black font-weight-bold font-size-16">이메일</p>
                        <input defaultValue={memberEmail} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                          setMemberEmail(e.target.value)
                        }} />
                      </div>

                      <div className="mb-4">
                        <p className="noMargin text-black font-weight-bold font-size-16">전화번호</p>
                        <input value={Util.handlePhoneNumberChange(memberTelephone)} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                          setMemberTelephone(Util.handlePhoneNumberChange(e.target.value))
                        }} />
                      </div>

                      <div className="mb-4">
                        <p className="noMargin text-black font-weight-bold font-size-16">팩스</p>
                        <input value={Util.handlePhoneNumberChange(memberFax)} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                          setMemberFax(Util.handlePhoneNumberChange(e.target.value))
                        }} />
                      </div>

                      <div className="mb-4">
                        <p className="noMargin text-black font-weight-bold font-size-16">휴대폰 번호</p>
                        <input value={Util.handleMobileNumberChange(memberCellphone)} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3}} onChange={(e)=>{
                          setMemberCellphone(Util.handleMobileNumberChange(e.target.value))
                        }} />
                      </div>

                      {/* <div className="mb-4">
                        <p className="noMargin">서명 이미지</p>
                        <div style={{display: "flex"}}>
                        {signFile?.path ?
                        <img src={"http://Iot_monitor.ropiklabs.com" + signFilePreview.path} style={{height: 48, border: "1px solid #ced4da", borderRadius: "0.25rem"}} />
                        :
                        <img src={signFilePreview} style={{height: 48, border: "1px solid #ced4da", borderRadius: "0.25rem"}} />
                        }
                        <label className="btn btn-primary waves-effect waves-light" htmlFor="sign" style={{height: 36, marginLeft: 6}}>파일 선택</label>
                        <input id="sign" type="file" accept="image/*" style={{display: "none"}} onChange={saveImgFile} />
                        </div>
                      </div> */}

                      <div className="mb-4 row">
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={ async () => {
                              handleSubmit()
                            }}
                          >
                              회원정보 변경하기
                          </button>
                        </div>
                      </div>
                      

                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                        </div>
                      </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      </div>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

ProfileScreen.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(ProfileScreen)
