import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { useSelector } from "react-redux";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import weaver from "../../assets/images/weaver.png";

const BusinessManagementScreen = props => {
  const selector = useSelector(state => state)

  const [companyDetailId, setCompanyDetailId] = useState('');
  const [collectionSector, setCollectionSector] = useState('');
  const [handlingItem, setHandlingItem] = useState('');
  const [employees, setEmployees] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [special5, setSpecial5] = useState('');
  const [cargo35, setCargo35] = useState('');
  const [cargo12, setCargo12] = useState('');
  const [performance, setPerformance] = useState([]);
  const [businessPartners, setBusinessPartners] = useState('');
  const [inputText, setInputText] = useState('');
  const [previous, setPrevious] = useState('');
  const [current, setCurrent] = useState('');
  const [monthlycurrent, setMonthlycurrent] = useState('');
  const [businessPartnersFile, setBusinessPartnersFile] = useState(undefined);
  const [oldBusinessPartnersFile, setOldBusinessPartnersFile] = useState([])

  const code = selector.Data?.code[0] || []
  const collectionSectors = code.filter(item => item.code === 'collectionSectors')
  const handlingItems = code.filter(item => item.code === 'handlingItems')
  const employeesArr = code.filter(item => item.code === 'employees')
  const vehiclesArrProto = code.filter(item => item.code === 'vehicles')
  const performancesProto = code.filter(item => item.code === 'performances')
  const businessPartnersArr = code.filter(item => item.code === 'businessPartners')

  const vehiclesArr = vehiclesArrProto.map((vehicle) => ({
    ...vehicle,
    text: `${vehicle.text}(`,
    currentState: eval(vehicle.key),
    changeState: eval('set' + vehicle.key.charAt(0).toUpperCase() + vehicle.key.slice(1)),
  }));

  const performances = performancesProto.map((performance) => ({
    ...performance,
    text: `${performance.text}(`,
    currentState: eval(performance.key),
    changeState: eval('set' + performance.key.charAt(0).toUpperCase() + performance.key.slice(1)),
  }));

  useEffect(() => {
    const memberData = selector.Profile?.auth?.[0] || selector.auth?.user;
    const companyList = memberData?.member_group;
    const mainCompany = companyList[0];
    const mainCompanyDetail = mainCompany.company_group.company_detail[0];
    const vehicles = mainCompanyDetail.vehicle ? JSON.parse(mainCompanyDetail.vehicle) : [];
    const performance = mainCompanyDetail.performance ? JSON.parse(mainCompanyDetail.performance) : [];

    let vehicleArr = []
    for (let i = 0; i < vehicles.length; i++) {
      let val = {
        'key': Object.keys(vehicles[i])[0],
        'text': vehicles.filter(itm => itm.key === Object.keys(vehicles[i])[0])?.[0]?.text
      }
      vehicleArr.push(val)
    }

    let performanceArr = []
    for (let i = 0; i < performance.length; i++) {
      let val = {
        'key': Object.keys(performance[i])[0],
        'text': performances.filter(itm => itm.key === Object.keys(performance[i])[0])?.[0]?.text
      }
      performanceArr.push(val)
    }

    setCompanyDetailId(mainCompanyDetail.id || '');
    setCollectionSector(mainCompanyDetail.collection_sector || '');
    setHandlingItem(mainCompanyDetail.handling_item || '');
    setEmployees(mainCompanyDetail.employee || '');
    setVehicles(vehicleArr);
    setSpecial5(vehicles.filter(itm => Object.keys(itm)[0] === 'special5')?.[0]?.special5 || '');
    setCargo35(vehicles.filter(itm => Object.keys(itm)[0] === 'cargo35')?.[0]?.cargo35 || '');
    setCargo12(vehicles.filter(itm => Object.keys(itm)[0] === 'cargo12')?.[0]?.cargo12 || '');
    setPerformance(performanceArr)
    setBusinessPartners(mainCompanyDetail.customer || '')
    setInputText('')
    setPrevious(performance.filter(itm => Object.keys(itm)[0] === 'previous')?.[0]?.previous || '')
    setCurrent(performance.filter(itm => Object.keys(itm)[0] === 'current')?.[0]?.current || '')
    setMonthlycurrent(performance.filter(itm => Object.keys(itm)[0] === 'monthlycurrent')?.[0]?.monthlycurrent || '')
    setBusinessPartnersFile(memberData.member_file.filter(item => item.category === 'businessPartnersFile') || [])
    setOldBusinessPartnersFile(memberData.member_file.filter(item => item.category === 'businessPartnersFile') || [])
  }, []);

  const isValiddate = () => {
    // if (driver === '') {
    //   alert("직종을 선택해주세요.")
    //   return ""
    // }
    if (collectionSector === '') {
      alert("업종을 선택 해주세요.")
      return ""
    }
    if (handlingItem === '') {
      alert("취급품목을 선택해주세요.")
      return ""
    }
    if (employees === '') {
      alert("종업원 수를 업로드해주세요.")
      return ""
    }
    for (let i = 0; i < vehicles.length; i++) {
      const item = vehicles[i];
      if (item.key === '') {
        alert(item.text.replace("(", "") + " 수량을 입력해주세요.")
        return ""
      }
    }
    for (let i = 0; i < performance.length; i++) {
      const item = performance[i];
      if (item.key === '') {
        alert("처리실적 " + item.text.replace("(", "") + " 수량을 입력해주세요.")
        return ""
      }
    }
    return "success";
  }

  // 위에는 거래처 목록 파일 삭제, 아래껀 필수 업로드 서류 삭제
  const deleteFile = (item) => {
    setBusinessPartnersFile(businessPartnersFile.filter(data => data !== item))
  }

  const deleteDocument = (item, data) => {
    item.changeState(item.currentState.filter(element => element.name !== data.name))
  }

  // 중복되는 파일을 제외하고 파일등록(위에껀 거래처 목록 파일, 아래껀 필수 업로드 서류)
  const registFiles = (e) => {
    setBusinessPartnersFile([...e.target.files])
    // let filteredFiles
    // for(let i=0;i<e.target.files.length;i++){
    //   if(businessPartnersFile?.length > 0 && businessPartnersFile?.map(item => item.name).indexOf(e.target.files[i].name) != -1){
    //     filteredFiles = (filteredFiles || [...e.target.files]).filter(data => data !== [...e.target.files][i]);
    //   }
    // }
    // setBusinessPartnersFile([...businessPartnersFile, ...(filteredFiles || e.target.files)])
  }

  const registDocument = (e, item) => {
    let filteredFiles
    for (let i = 0; i < e.target.files.length; i++) {
      if (item.currentState?.length > 0 && item.currentState?.map(item => item.name).indexOf(e.target.files[i].name) != -1) {
        filteredFiles = (filteredFiles || [...e.target.files]).filter(data => data !== [...e.target.files][i]);
      }
    }
    item.changeState(item.type === 'multi' ? [...item.currentState, ...(filteredFiles || e.target.files)] : [...e.target.files])
  }

  const handleCheckBoxAndTextInput = (str, item, set) => {
    const dupItem = { ...item }
    if (str.filter(itm => itm.key === item.key).length > 0) {
      set(str.filter(value => value.key !== item.key))
      item.changeState('')
    } else {
      delete dupItem.currentState
      delete dupItem.changeState
      set([...str, dupItem])
    }
  }

  const fileDownload = async () => {
    let url = 'https://waveradmin.ropiklabs.com/media/sample/거래처등록엑셀.xlsx'
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const excelUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = excelUrl;
        link.setAttribute('download', '거래처등록엑셀.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error)
      })
  };

  const thisState = {
    companyDetailId : companyDetailId,
    collectionSector : collectionSector,
    handlingItem : handlingItem,
    employees : employees,
    vehicles : vehicles,
    special5 : special5,
    cargo35 : cargo35,
    cargo12 : cargo12,
    performance : performance,
    businessPartners : businessPartners,
    inputText : inputText,
    previous : previous,
    current : current,
    monthlycurrent : monthlycurrent,
    businessPartnersFile : businessPartnersFile,
    oldBusinessPartnersFile : oldBusinessPartnersFile
  }

  const addFileData = (items) => {
    if (items.oldBusinessPartnersFile.length === items.businessPartnersFile.length) {
      console.log("for문 돌아감")
      for (let i = 0; i < items.businessPartnersFile.length; i++) {
        console.log("if문 돌아감")
        if (items.businessPartnersFile[i] !== items.oldBusinessPartnersFile[i]) {
          items.businessPartnersFileDel = items.oldBusinessPartnersFile;
          items.businessPartnersFileAdd = items.businessPartnersFile;
          console.log("***items.businessPartnersFile !== items.oldBusinessPartnersFile***")
          break;
        }
      }
    } else {
      items.businessPartnersFileDel = items.oldBusinessPartnersFile;
      items.businessPartnersFileAdd = items.businessPartnersFile;
      console.log("***items.businessPartnersFile !== items.oldBusinessPartnersFile***")
    }
  }

  const resetFileData = (items) => {
    items.businessPartnersFileDel = {};
    items.businessPartnersFileAdd = {};
  }

  return (
    // <React.Fragment>
    //   <div className="page-content">
    //   <MetaTags>
    //     <title>사업장 정보 수정 |  IoT Monitor</title>
    //   </MetaTags>
    //   <div className="account-pages">
    //     <Container fluid>
    //       {/* Render Breadcrumbs */}
    //       <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="사업장 정보 수정" />

    //       <Row className="justify-content-center">
    //         <Col md={8} lg={6} style={{width: '20%'}}>
    //           <Card className="overflow-hidden">
    //             <div className="bg-primary">
    //               <div className="text-primary text-center p-4">
    //                 <h5 className="text-white font-size-20">사업장 정보</h5>
    //                 <p className="text-white-50"></p>
    //                 <a href="index.html" className="logo logo-admin">
    //                   <div className="logo logo-admin">
    //                    <img src={weaver} height="24" alt="logo" />
    //                  </div>
    //                 </a>
    //               </div>
    //             </div>
    <CardBody className="p-4">
      <div className="p-3">

        {props.registrationError &&
          props.registrationError ? (
          <Alert color="danger">
            {props.registrationError}
          </Alert>
        ) : null}

        {/* <div className="mb-3">
          <legend>직종선택</legend>
          {
            drivers && drivers.map((item, index) => {
              return (
                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                  <input className="is-untouched is-pristine av-valid form-check-input" checked={driver == item.key} type='radio' id={item.key} name='drivers' value={item.key} onChange={() => { setDriver(item.key) }} />
                  <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
              )
            })
          }
        </div> */}

        <div className="mb-3">
          <legend>업종선택</legend>
          {
            collectionSectors && collectionSectors.map((item, index) => {
              return (
                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                  <input className="is-untouched is-pristine av-valid form-check-input" checked={collectionSector == item.key} type='radio' id={item.key} name='collectionSectors' value={item.key} onChange={() => { setCollectionSector(item.key) }} />
                  <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
              )
            })
          }
        </div>

        <div className="mb-3">
          <legend>취급품목</legend>
          {
            handlingItems && handlingItems.map((item, index) => {
              return (
                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                  <input className="is-untouched is-pristine av-valid form-check-input" checked={handlingItem == item.key} type='radio' id={item.key} name="handlingItems" value={item.key} onChange={() => { setHandlingItem(item.key) }} />
                  <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
              )
            })
          }
        </div>

        <div className="mb-3">
          <legend>종업원 수</legend>
          {
            employeesArr && employeesArr.map((item, index) => {
              return (
                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                  <input className="is-untouched is-pristine av-valid form-check-input" checked={employees == item.key} type='radio' id={item.key} name="employees" value={item.key} onChange={() => { setEmployees(item.key) }} />
                  <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
              )
            })
          }
        </div>

        <div className="mb-3">
          <legend>보유차량 수</legend>
          {
            vehiclesArr && vehiclesArr.map((item, index) => {
              return (
                <div className="form-check form-check-inline" style={{ display: "flex" }} key={item.key + '_' + index}>
                  <input className="is-untouched is-pristine av-valid form-check-input" checked={vehicles.filter(itm => itm.key === item.key).length > 0} type='checkbox' id={item.key} name="vehicles" value={item.key} onChange={(e) => {
                    handleCheckBoxAndTextInput(vehicles, item, setVehicles)
                  }}
                  />
                  <label className="form-check-label" htmlFor={item.key} style={{ marginLeft: '0.5rem' }}>{item.text}</label>
                  <input style={{ width: 64, height: 23, padding: 6, marginLeft: '0.5rem', marginRight: 6 }} className="form-control" value={item.currentState} disabled={vehicles.filter(itm => itm.key === item.key).length > 0 ? false : "disabled"} onChange={(e) => {
                    item.changeState(e.target.value)
                  }} />
                  <p>대)</p>
                </div>
              )
            })
          }
        </div>

        <div className="mb-3">
          <legend>처리실적</legend>
          {
            performances && performances.map((item, index) => {
              return (
                <div className="form-check form-check-inline" style={{ display: "flex" }} key={item.key + '_' + index}>
                  <input className="is-untouched is-pristine av-valid form-check-input" checked={performance.filter(itm => itm.key === item.key).length > 0} type='checkbox' id={item.key} name="performances" value={item.key} onChange={(e) => {
                    handleCheckBoxAndTextInput(performance, item, setPerformance)
                  }}
                  />
                  <label className="form-check-label" htmlFor={item.key} style={{ marginLeft: '0.5rem' }}>{item.text}</label>
                  <input style={{ width: 64, height: 23, padding: 6, marginLeft: '0.5rem', marginRight: 6 }} className="form-control" value={item.currentState} disabled={performance.filter(itm => itm.key === item.key).length > 0 ? false : "disabled"} onChange={(e) => {
                    item.changeState(e.target.value)
                  }} />
                  <p>L)</p>
                </div>
              )
            })
          }
        </div>

        <div className="mb-3">
          <legend>거래처 수</legend>
          {
            businessPartnersArr && businessPartnersArr.map((item, index) => {
              return (
                <div className="form-check form-check-inline" key={item.key + '_' + index}>
                  <input className="is-untouched is-pristine av-valid form-check-input" type='radio' id={item.key} checked={businessPartners == item.key} name="businessPartners" value={item.key} onChange={() => { setBusinessPartners(item.key) }} />
                  <label className="form-check-label" htmlFor={item.key} >{item.text}</label>
                </div>
              )
            })
          }
        </div>

        <div>
          <div className="mb-3">
            <p className="noMargin" style={{ fontSize: 18, color: 'black', marginTop: 12 }}>거래처 리스트 업로드</p>
          </div>
          <div className="mb-3">
            <p style={{ marginBottom: 6, color: "black", fontSize: 15 }}>- 거래처명/대표자명/주소/연락처/올바로등록대상업체 여부 포함 필수</p>
            <label className="btn btn-primary waves-effect waves-light" htmlFor="ex_file" style={{ height: 36, marginLeft: 6 }}>파일 선택</label>
            <input style={{ display: "none" }} type="file" accept=".xls,.xlsx" id="ex_file" onChange={(e) => {
              registFiles(e)
            }} />
            <label className="btn btn-primary waves-effect waves-light" htmlFor="sample" style={{height: 36, marginLeft: 6}}>샘플 받기</label>
            <input style={{display: "none"}} type="button" id="sample" onClick={()=>{
              fileDownload()
            }}/> 
            {businessPartnersFile?.length > 0 && businessPartnersFile.map((item, index) => {
              return (
                <div key={index} style={{ border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6 }}>
                  <p className="noMargin" style={{ paddingLeft: 6 }}>{item.name}</p>
                  <p className="noMargin" style={{ paddingRight: 6, color: "#000", cursor: "pointer" }} onClick={() => {
                    deleteFile(item)
                  }}>X</p>
                </div>
              )
            })
            }
          </div>
        </div>

        {/* <div className="mb-3">
          <p className="noMargin" style={{ fontSize: 18, color: 'black', marginTop: 12 }}>필수 업로드 서류</p>
        </div>
        {
          touchFileUploadButtonData && touchFileUploadButtonData.map((item, index) => {
            return (
              <div className="mb-3" key={item.key + '_' + index}>
                <p style={{ marginBottom: 6, color: "black", fontSize: 15 }}>{item.name}</p>
                <label className="btn btn-primary waves-effect waves-light" htmlFor={item.id} style={{ height: 36, marginLeft: 6 }}>파일 선택</label>
                <input style={{ display: "none" }} type="file" multiple={item.type === 'multi' ? "multiple" : ""} accept=".pdf" id={item.id} onChange={(e) => {
                  registDocument(e, item)
                }} />
                {item.currentState?.length > 0 && item.currentState?.map((data, index) => {
                  return (
                    <div key={index} style={{ border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6 }}>
                      <p className="noMargin" style={{ paddingLeft: 6 }}>{data.name}</p>
                      <p className="noMargin" style={{ paddingRight: 6, color: "#000", cursor: "pointer" }} onClick={() => {
                        deleteDocument(item, data)
                      }}>X</p>
                    </div>
                  )
                })
                }
              </div>
            )
          })
        } */}

        <div className="mb-3 row">
          <div className="col-12 text-end">
            <button
              style={{ marginTop: 24 }}
              className="btn btn-primary w-md waves-effect waves-light"
              type="submit"
              onClick={() => {
                if (isValiddate() === 'success') {
                  let items = { ...thisState }
                  addFileData(items)
                  props.handleWrite(items)
                  resetFileData(items)
                }
              }}
            >
              변경사항 저장하기
            </button>
          </div>
        </div>

        <div className="mt-2 mb-0 row">
          <div className="col-12 mt-4">
          </div>
        </div>
      </div>
    </CardBody>
    //           </Card>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </div>
    //   </div>
    // </React.Fragment>
  )
}

BusinessManagementScreen.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(BusinessManagementScreen)
