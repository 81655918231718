import PropTypes from 'prop-types'
import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap"
import { Link, useLocation } from "react-router-dom"
// import  Util  from "./Common/Util"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import moment from 'moment';

// import images
import client from "../../assets/images/client.png";
import transactionAmount from "../../assets/images/transactionAmount.png";
import transactionPrice from "../../assets/images/transactionPrice.png";
import tradeHistory from "../../assets/images/거래내역.jpg";
import news from "../../assets/images/news.jpg";

import { useSelector } from 'react-redux';

// Charts
import LineAreaChart from "../AllCharts/apex/lineareachart";
import ChartistGraph from 'react-chartist';

import "chartist/dist/scss/chartist.scss";

//i18n
import { withTranslation } from "react-i18next"
import { chartResponse } from 'crud/auth.crud';
import { useEffect } from 'react';

import EachSiteChart from './EachSiteChart';
import EachSiteTrade from './EachSiteTrade';
import Emitter from 'pages/Management/Emitter'
// import EachSiteAllbaro from './EachSiteAllbaro';

const style = { width: '50%', fontSize: 14 };

const clickedStyle = { backgroundColor: "#48C3B1", color: "#ffffff" };

const EachSiteHistory = () => {
  const selector = useSelector(state => state)
  const location = useLocation()

  const [type, setType] = useState(selector.auth.user?.type || "")
  const [detailType, setDetailType] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.company_detail?.[0]?.type || "")
  const [managementState, setManagementState] = useState('chart')
  const [company, setCompany] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.id);
  const [myCompany, setMyCompany] = useState(company === location.state.companyId)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{managementState === 'chart' ? '차트' : managementState === 'trade' ? '거래내역' : '올바로 처리내역'} |  IoT Monitor</title>
        </MetaTags>
        <Container fluid>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Breadcrumbs maintitle="홈" title="관리대장" breadcrumbItem={managementState === 'chart' ? '차트' : managementState === 'trade' ? '거래내역' : '올바로 처리내역'} />
          <div className="float-end btn-group" style={{ display: 'flex', width: '12%', margin: "36px 0 0", height: 35, minWidth: 272 }}>
            <Button
              style={managementState == 'chart' ? {...clickedStyle, ...style} : {...style}}
              type="button"
              size="sm"
              className="waves-effect"
              onClick={() => {
                setManagementState('chart')
              }}
            >
              차트
            </Button>
            <Button
              style={managementState == 'trade' ? {...clickedStyle, ...style} : {...style}}
              type="button"
              size="sm"
              className="waves-effect"
              onClick={() => {
                setManagementState('trade')
              }}
            >
              거래내역
            </Button>
            {myCompany && detailType === 'total' &&
            <Button
              style={managementState == 'allbaro' ? {...clickedStyle, ...style} : {...style}}
              type="button"
              size="sm"
              className="waves-effect"
              onClick={() => {
                setManagementState('allbaro')
              }}
            >
              올바로
            </Button>
            }
          </div>
          </div>
          {managementState === 'chart' && <EachSiteChart />}
          {managementState === 'trade' && <EachSiteTrade />}
          {managementState === 'allbaro' && <Emitter />}
        </Container>
      </div>

    </React.Fragment>
  )
}

EachSiteHistory.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(EachSiteHistory)
