import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"
import { register } from "../../../crud/auth.crud"

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper"
// import {
//   postFakeRegister,
//   postJwtRegister,
// } from "../../../helpers/fakebackend_helper"

// initialize relavant method of both Auth
// const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: params }) {
  try {
    const response = yield register(params)
    console.log("리스폰스", response)
    if(response.data.result && response.data.result === 'success'){
      const data = response.data?.data;
      localStorage.setItem("ACCESS_TOKEN", data?.access);
      yield put(registerUserSuccessful(data?.access))
    }
    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const response = yield call(
    //     fireBaseBackend.registerUser,
    //     user.email,
    //     user.password
    //   )
    //   yield put(registerUserSuccessful(response))
    // } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
    //   const response = yield call(postJwtRegister, "/post-jwt-register", user)
    //   yield put(registerUserSuccessful(response))
    // } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
    //   const response = yield call(postFakeRegister, user)
    //   yield put(registerUserSuccessful(response))
    // }
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
