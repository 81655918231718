import { getSettlement, postTaxPdf } from "crud/auth.crud";
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';

import { Link, useLocation } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody } from "reactstrap"
import { useSelector } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import moment from "moment";
import Util from "pages/Dashboard/Common/Util";
import loading from "../../assets/images/loading.gif";

const SettlementDetails = () => {
  const location = useLocation()
  const selector = useSelector(state => state)
  const [dataList, setDataList] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [user, setUser] = useState(selector.auth.user)
  const [detailData, setDetailData] = useState()
  const [page, setPage] = useState(1)
  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1);
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4]);
  const [contentCount, setContentCount] = useState();
  const [pdfUrl, setPdfUrl] = useState();
  const [detailType, setDetailType] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.company_detail?.[0]?.type || "")

  const nextGetContract = async () => {
    setOnUpload(true)
    let newArr = []
    console.log("pageArr >>> ", pageArr)
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      console.log("페이지 일치 여부", page, i + 1)
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    console.log("newArr >>> ", newArr)
    setPageArr(newArr)
    setOnUpload(false)
  }

  const getDataList = async () => {
    setOnUpload(true)
    const params = {
      page: page,
      company: location.state?.companyId || user.member_group?.[0]?.company_group?.id,
    }

    console.log("params >>>", params)

    let url = '/summary_waste_collect/data_list/?page=' + params.page + '&company=' + params.company;

    const response = await getSettlement(url);
    console.log("response >>> ", response)
    if (!contentCount || contentCount !== Math.floor(response.data.count / 15) + 1) {
      setContentCount(Math.floor(response.data.count / 15) + 1)
      let newArr = []
      for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
        console.log("페이지 일치 여부", page, i + 1)
        if (i + 1 <= Math.floor(response.data.count / 15) + 1) {
          newArr.push(i + 1)
        }
      }
      setPageArr(newArr)
    }
    if (response.status === 200) {
      const content = response.data?.results || [];
      if (content.length > 0) {
        console.log([...content])
        setDataList([...content])
      } else {
        setDataList(false)
      }
    }
    setOnUpload(false)
  }

  const clickList = async (item) => {
    setDetailData(item);
    setModalShow(true)
  }

  useEffect(() => {
    nextGetContract()
  }, [pagesGroup])

  useEffect(() => {
    getDataList()
  }, [])

  return (
    <Row>
      <Col xs="12">
        <div className="email-rightbar mb-3">
          <Card>
              <CardBody style={{padding: 0, borderRadius: 4, overflow: 'hidden'}}>                 
                <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                      <thead className="chart-thead">
                      <tr>
                        <th scope="col" >(#) <span>ID</span></th>
                        <th scope="col" >업체명</th>
                        <th scope="col" >거래일자</th>
                        <th scope="col" >배출량</th>
                        <th scope="col" >고지금액</th>
                        <th scope="col" >완납여부</th>
                      </tr>
                    </thead>
                    <tbody className="chart-tbody clickable">

                      {
                        dataList && dataList.map((item, index) => {
                          return (
                            <tr key={item.id + '_' + index} onClick={() => {clickList(item)}}>
                              <td scope="row">{item.id}</td>
                              <td>{item.collection_company.name}</td>
                              <td>{moment(item.summary_date).format('YYYY-MM-DD')}</td>
                              <td>{Util.setCommaPrice(item.qty_l)} L</td>
                              <td>{Util.setCommaPrice(item.total_price)} 원</td>
                              <td>{item.state === 'Y' ? '완납' : item.state === 'N' ? '미납' : '부분납부'}</td>
                            </tr>
                          )
                        })
                      }
                      {!dataList &&
                        <tr >
                          <td scope="row" colSpan="6">데이터가 없습니다.</td>                              
                        </tr>
                      }

                    </tbody>
                  </table>
                </div>
              </CardBody>

            </Card>
            <Row>
              <Col xs="12" style={{ textAlign: "center" }}>
                <div className="btn-group mt-4 waver-pagination-btn-group">
                  <Button                          
                    type="button"                          
                    size="sm"
                    className={page !== 1 ? 'waves-effect left-btn active' : 'waves-effect left-btn' }
                    onClick={() => {
                      if (page !== 1) {
                        setPage(page - 1)
                        if (Math.min(...pageArr) === page) {
                          setPagesGroup(pagesGroup - 5)
                        }
                      }
                    }}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>
                  {pageArr && pageArr.map((item, index) => {
                    return (
                      <Button
                        key={index}                              
                        type="button"
                        size="sm"
                        className={page === item ? 'waves-effect center-btn active' : 'waves-effect center-btn' }                              
                        onClick={() => {
                          setPage(item)
                        }}
                      >
                        {item}
                      </Button>
                    )
                  })}
                  <Button                        
                    type="button"                          
                    size="sm"
                    className={page !== contentCount ? 'waves-effect right-btn active' : 'waves-effect right-btn' }    
                    onClick={() => {
                      if (page !== contentCount) {
                        setPage(page + 1)
                        if (Math.max(...pageArr) === page) {
                          setPagesGroup(pagesGroup + 5)
                        }
                      }
                    }}
                  >
                    <i className="fa fa-chevron-right" />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        {onUpload && <div style={{
          position: "absolute",
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          background: 'rgba(255, 255, 255, 0.6)',
          zIndex: 999,
          display: "flex",
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <img src={loading} alt="로딩중입니다." width={'5%'}></img>
        </div>}
        {modalShow && <div style={{
        position: "fixed",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(0, 0, 0, 0.4)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <div style={{ width: 480, height: 480, backgroundColor: "#fff", zIndex: 1000, padding: 36 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ fontSize: 20, color: 'black', fontWeight: 'bold' }}>정산 내역 상세</p>
            <p style={{ cursor: "pointer", fontSize: 20 }} onClick={() => {
              setModalShow(false)
            }}>X</p>
          </div>
          <div style={{ padding: 12 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>업체명</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{detailData?.collection_company.name}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>배출량</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.qty_l)} L</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>금액</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.price)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>VAT</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.vat)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>고지 금액</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.total_price)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>납부 금액</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.payed_price)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>미납 금액</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{Util.setCommaPrice(detailData?.total_price - detailData?.payed_price)} 원</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: 16 }}>완납 여부</p>
              <p style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}>{detailData?.state === 'Y' ? '완납' : detailData?.state === 'N' ? '미납' : '부분납부'}</p>
            </div>
          </div>
        </div>
      </div>}
    </Row>       
     
    //     </Container>
    //   </div>
    // </React.Fragment>
  )
}

export default SettlementDetails
