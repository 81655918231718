import React, { useEffect, useState } from "react"
import { Row, Col, Container } from "reactstrap"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { faqResponse, tradeHistoryCall } from "../../crud/auth.crud"

const ServiceCenterScreen = props => {

  const [dataList, setDataList] = useState([
    { title: '도움이 필요하신가요?', data: 'Tel. 1588-0000' },
    { title: '카카오톡 채널', data: '@000000' },
  ]);
  // const [btnList, setBtnList] = useState([
  //   { title: '서식자료', image: assets.remoteSupport },
  //   { title: '원격지원', image: assets.formData },
  //   { title: 'Q&A', image: assets.QandA },
  // ]);
  const [faqList, setFaqList] = useState([]);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  // const animation = useRef(new Animated.Value(0)).current;
  // const position = useRef(new Animated.ValueXY({ x: 0, y: 0 })).current;

  const getDataList = async ()=>{
    if (isFetching || isListEnd) {            
        return;
    }

    setIsFetching(true)
    const response = await faqResponse();
    const content = response.data?.results || [];
    console.log("content ::", content)
    if (content.length > 0) {
      setFaqList([...content]);
      setIsFetching(false);
    } else {
      setIsFetching(false);
      setIsListEnd(true);
    }
  }

  useEffect(()=>{
    getDataList()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <MetaTags>
          <title>고객센터 |  IoT Monitor</title>
        </MetaTags>
          <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="고객센터" />

          <Row className="email-template">
            <Col md={12}>          

              <table
                className="body-wrap"
                style={{
                  fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                  boxSizing: "border-box",
                  fontSize: "14px",
                  width: "100%",
                  backgroundColor: "#f6f6f6",
                  margin: "0",
                }}
                bgcolor="#f6f6f6"
              >
                <tbody>
                  <tr
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}
                  >                    
                    <td
                      className="container"
                      width="600"
                      style={{
                        fontFamily:
                          "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        display: "block",
                        maxWidth: "796px",
                        clear: "both",
                        margin: "0",
                      }}
                      valign="top"
                    >
                      <div
                        className="content"
                        style={{
                          fontFamily:
                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                          boxSizing: "border-box",
                          fontSize: "14px",
                          maxWidth: "796px",
                          display: "block",
                          margin: "0 auto",
                          padding: "26px 0 0 0 ",
                        }}
                      >
                        <table
                          className="main"
                          width="100%"
                          cellPadding="0"
                          cellSpacing="0"
                          style={{
                            fontFamily:
                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                            boxSizing: "border-box",
                            fontSize: "14px",
                            borderRadius: "7px",
                            backgroundColor: "#fff",
                            margin: "0",
                            boxShadow: "0 0.75rem 1.5rem rgba(18,38,63,.03)",
                          }}
                        >
                          <tbody>
                            <tr
                              style={{
                                fontFamily:
                                  "Helvetica Neue,Helvetica,Arial,sans-serif",
                                boxSizing: "border-box",
                                fontSize: "14px",
                                margin: "0",
                              }}
                            >
                            </tr>
                            <tr
                              style={{
                                fontFamily:
                                  "Helvetica Neue,Helvetica,Arial,sans-serif",
                                boxSizing: "border-box",
                                fontSize: "14px",
                                margin: "0",
                              }}
                            >
                              <td
                                className="content-wrap"
                                style={{
                                  fontFamily:
                                    "Helvetica Neue,Helvetica,Arial,sans-serif",
                                  boxSizing: "border-box",
                                  fontSize: "14px",
                                  verticalAlign: "top",
                                  margin: "0",
                                  padding: "24px",
                                }}
                                valign="top"
                              >
                                <table
                                  width="100%"
                                  cellPadding="0"
                                  cellSpacing="0"
                                  style={{
                                    fontFamily:
                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                    boxSizing: "border-box",
                                    fontSize: "14px",
                                    margin: "0",
                                  }}
                                >
                                  
                                    <tr
                                      style={{
                                        fontFamily:
                                          "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        margin: "0",
                                      }}
                                    >
                                      <td
                                        className="content-block"
                                        style={{
                                          color: '#333',
                                          fontFamily:
                                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                                          boxSizing: "border-box",
                                          fontSize: "20px",
                                          fontWeight: 600,
                                          verticalAlign: "top",
                                          margin: "0",
                                          padding: "0 0 16px",
                                        }}
                                        valign="top"
                                      >
                                        도움이 필요하신가요?
                                      </td>
                                    </tr>
                                    <tr
                                      style={{
                                        fontFamily:
                                          "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        margin: "0",
                                      }}
                                    >
                                      <td
                                        className="content-block"
                                        style={{
                                          color: 'black',
                                          fontWeight: 'bold',
                                          fontFamily:
                                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                                          boxSizing: "border-box",
                                          fontSize: "16px",
                                          verticalAlign: "top",
                                          margin: "0",
                                          padding: "0",
                                        }}
                                        valign="top"
                                      >
                                        <Row>
                                          <Col md={6}>
                                            <div style={{maxWidth: 362, height: 122, position: 'relative', background: '#4DBBAA', borderRadius: 4}}>
                                              <p style={{fontSize : 16, color: '#fff', fontWeight: 400, position:'absolute', top: 24, left: 24}}>상담전화</p>
                                              <p style={{fontSize : 36, color: '#fff', fontWeight: 600, position:'absolute', height: 36, bottom: 24, right: 24}}>1588-0000</p>
                                            </div>
                                          </Col>
                                          <Col md={6}>
                                            <div style={{maxWidth: 362, height: 122, position: 'relative', background: '#BF9C46', borderRadius: 4}}>
                                            <p style={{fontSize : 16, color: '#fff', fontWeight: 400, position:'absolute', top: 24, left: 24}}>카카오톡 채널</p>
                                            <p style={{fontSize : 36, color: '#fff', fontWeight: 600, position:'absolute', height: 36, bottom: 24, right: 24}}>@000000</p>
                                            </div>
                                          </Col>
                                        </Row>                                        
                                      </td>
                                    </tr>
                                    <tr
                                      style={{
                                        fontFamily:
                                          "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        margin: "0",
                                      }}
                                    >
                                      <td
                                        className="content-block"
                                        style={{
                                          color: '#8A8A8A',
                                          fontFamily:
                                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                                          boxSizing: "border-box",
                                          fontSize: "14px",
                                          verticalAlign: "top",
                                          margin: "0",
                                          padding: "16px 0 0 0",
                                        }}
                                        valign="top"
                                      >
                                        *상담시간: 오전 10시 ~ 오후 5시
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{fontSize: 24, color: "#000", paddingTop: 24, paddingBottom: 16}}>FAQ</td>
                                    </tr>
                                    <tbody className="waver-faq-tr">
                                    {faqList && faqList.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>
                                            <div className={item.subject == selectedAnswer ? 'waver-faq-div active' : 'waver-faq-div'}>
                                              <div style={{cursor: "pointer", display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}  onClick={() => {
                                                  selectedAnswer == item.subject ? setSelectedAnswer('') : setSelectedAnswer(item.subject)                                                                                                    
                                                }}>
                                                <p style={{marginTop: 18, paddingLeft: 16, fontSize: 16}}>Q. {item.subject}</p>
                                                <div style={{paddingRight: 16, marginTop: 18}}>
                                                  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    {
                                                      item.subject !== selectedAnswer ?
                                                      <path d="M1.5 1.25L9 8.75L16.5 1.25" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                      :
                                                      <path d="M1.5 8.75L9 1.25L16.5 8.75" stroke="#4DBBAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    }                                                    
                                                  </svg>                                                      
                                                </div>
                                              </div>
                                              {item.subject == selectedAnswer && 
                                                <div className="waver-faq-answer" >
                                                  <p style={{fontSize : 16, color: '#333'}}><span style={{color : '#BF9C46'}}>A.</span> {item.content}</p>
                                                </div>
                                              }                                                           
                                            </div>

                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ServiceCenterScreen
