import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import PropTypes from 'prop-types'
import { FormGroup } from "reactstrap"

import { connect } from "react-redux"
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  showSettingbarAction,
} from "../../store/actions"

// availity-reactstrap-validation
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import store from "../../store"
import { SET_AUTH, CLEAR_AUTH } from "../../store/auth/profile/actionTypes"
import { LOGOUT_USER } from "store/auth/login/actionTypes"
import { CLEAR_DATA } from "store/auth/data/actionTypes"
// import PURGE
import persistor from "index"

//SimpleBar
import SimpleBar from "simplebar-react"

import { Link, useHistory } from "react-router-dom"

//Import images
import arrowRight from "../../assets/images/arrow_right.png";
import logOut from "../../assets/images/logout.png";
import { settingChange, settingConfirm } from "crud/auth.crud"

const RightSidebar = props => {
  const history = useHistory()
  const selector = useSelector(state => state)
  // console.log(selector)
  // console.log(selector.Profile?.auth?.[0]?.agree_email)

  const [memberId, setMemberId] = useState(selector.Profile?.auth?.[0]?.id || selector.auth?.user?.id)
  const [email, setEmail] = useState(selector.Profile?.auth?.[0]?.agree_email || selector.auth?.user?.agree_email || "N")
  const [push, setPush] = useState(selector.Profile?.auth?.[0]?.agree_push || selector.auth?.user?.agree_push || "N")
  const [isChange, setIsChange] = useState(false)

  const changePreference = async (check, type) => {
    setIsChange(true)
    let params = {}
    if(type === 'email'){
        params = {
          agree_email : check,
          agree_push : push
      }
    }
    if(type === 'push'){
      params = {
        agree_email : email,
        agree_push : check
      }
    }
    console.log(params)
    const url = memberId
    const rs = await settingChange(params, url);     
    // console.log("rs >>>", rs)   
    if(rs.status === 200){
        const urlPlus = selector.auth?.user?.id;
        const memberResponse = await settingConfirm(urlPlus);
        // console.log([memberResponse.data])
        store.dispatch({ type: SET_AUTH, data: [memberResponse.data] }); 
    }
    setIsChange(false)
  }

  const handleLogout = async() => {
    localStorage.removeItem('ACCESS_TOKEN')
    localStorage.removeItem('authUser');
    store.dispatch({type: CLEAR_AUTH})
    store.dispatch({type: LOGOUT_USER})
    store.dispatch({type: CLEAR_DATA})
    await persistor.purge()
    //history.push('main')
  }

  useEffect(()=>{

  }, [])

  return (
    <React.Fragment>
      <div className="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault()
                  props.showSettingbarAction(false)
                }}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0">설정</h5>
            </div>

            <hr className="my-0" />
                  
            <div className="p-4">
              <div className="radio-toolbar" style={{display: "flex", justifyContent: "space-between"}}>
                <span className="mb-2 d-block">이메일 수신 동의</span>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={email == "Y"}
                  onChange={(e)=>{
                    if(!isChange){
                      changePreference(e.target.checked ? "Y" : "N", 'email')
                    }else{
                      e.target.checked ? e.target.checked = false : e.target.checked = true
                    }
                  }} />
                </div>
              </div>

              <hr className="mt-1" />

              <div className="radio-toolbar" style={{display: "flex", justifyContent: "space-between"}}>
                <span className="mb-2 d-block">앱 푸시 알람 수신 동의</span>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={push == "Y"}
                  onChange={(e)=>{
                    if(!isChange){
                      changePreference(e.target.checked ? "Y" : "N", 'push')
                    }else{
                      e.target.checked ? e.target.checked = false : e.target.checked = true
                    }
                  }} />
                </div>
              </div>
              <hr className="mt-1" />

              <div className="radio-toolbar" style={{display: "flex", justifyContent: "space-between"}}>
                <span className="mb-2 d-block"> IoT Monitor 버전</span>
                <span className="mb-2 d-block">1.0</span>
              </div>
              <hr className="mt-1" />

              <div className="radio-toolbar" style={{display: "flex", justifyContent: "space-between", cursor: "pointer"}} onClick={(e)=>{
                e.preventDefault()
                props.showSettingbarAction(false)
                handleLogout()
              }}>
                <span className="mb-2 d-block">로그아웃 하기</span>
                <img src={logOut} width={16} height={16} />
              </div>
              <hr className="mt-1" />
              
            </div>
          </div>
        </SimpleBar>
      </div>
      <Link
        style={{cursor: "auto"}}
        to="#"
        onClick={e => {
          e.preventDefault()
          props.showSettingbarAction(false)
        }}
        className="rightbar-overlay"
      />
    </React.Fragment>
  )
}

RightSidebar.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  layoutType: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showSettingbarAction: PropTypes.func,
  topbarTheme: PropTypes.any
}

const mapStateToProps = state => {
  return { ...state.Layout }
}

export default connect(mapStateToProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  showSettingbarAction,
})(RightSidebar)
