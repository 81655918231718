import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { useSelector } from "react-redux";

import { Link } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import moment from 'moment';
import { postMyContract, tradeHistoryCall } from "../../crud/auth.crud"
import Util from "pages/Dashboard/Common/Util";

import loading from "../../assets/images/loading.gif";

const TradeHistory = () => {

  const selector = useSelector(state => state)

  // 드롭다운
  const [setting_Menu, setsetting_Menu] = useState(false)

  // useState가 list일 땐 목록창, detail일 땐 디테일창, modify일 땐 수정창
  const [form, setForm] = useState('list')

  // 체크박스 상태
  const [onChecked, setOnChecked] = useState([])

  const newDate = new Date()
  const getDayText = (day) => {
    const dayArray = ['일', '월', '화', '수', '목', '금', '토'];
    return dayArray[day];
  }

  const [dataList, setDataList] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);
  const [date, setDate] = useState(newDate);
  const [letDate, setLetDate] = useState(newDate);
  const [oldDate, setOldDate] = useState(newDate);
  const [dateText, setDateText] = useState(moment(newDate).format("MM/DD"));
  const [year, setYear] = useState(parseInt(moment(newDate).format("YYYY")) + '');
  const [newMonth, setNewMonth] = useState('');
  const [day, setDay] = useState(moment(newDate).day());
  const [dayText, setDayText] = useState(getDayText(moment(newDate).day()));
  const [checkedDateType, setCheckedDateType] = useState('');
  const [yearModalShow, setYearModalShow] = useState(false);
  const [monthModalShow, setMonthModalShow] = useState(false);
  const [priceList, setPriceList] = useState([]);
  const [pricePerL, setPricePerL] = useState(170);
  const [companyId, setCompanyId] = useState(selector.auth?.user?.member_group?.[0]?.company_group.id)
  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState()

  // 드롭다운 map용
  const yearSelect = [
    moment(newDate).format("YYYY") - 3, moment(newDate).format("YYYY") - 2, moment(newDate).format("YYYY") - 1, moment(newDate).format("YYYY")
  ]
  const monthSelect = [
    ...Array(12).keys()
  ].map(x => x + 1)

  const handleYearClick = (e) => {
    setYear(e.target.value)
    setIsFetching(false),
      setIsListEnd(false),
      setDataList([]),
      setNewMonth(''),
      setPage(1)
    setIsRefresh(true)
  }

  const handleMonthClick = (e) => {
    let value = e.target.value
    //  < 10 ? '0'+e.target.value : e.target.value
    // if(e.target.value === ''){
    //   value = ''
    // }
    console.log(value)
    setNewMonth(value)
    setIsFetching(false),
      setIsListEnd(false),
      setDataList([]),
      setPage(1),
      setIsRefresh(true)
  }

  const nextGetData = async () => {
    setOnUpload(true)
    let newArr = []
    console.log("pageArr >>> ", pageArr)
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      console.log("페이지 일치 여부", page, i + 1)
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    console.log("newArr >>> ", newArr)
    setPageArr(newArr)
    setOnUpload(false)
  }

  const getDataList = async () => {
    if (isFetching || isListEnd) {
      return;
    }
    let month = newMonth < 10 ? '0' + newMonth : newMonth;
    let start_ym = newMonth ? year + '-' + month + '-01' : year + '-01-01';
    let newDate = new Date(start_ym)
    let end_ym = newMonth ? moment(newDate.setMonth(newDate.getMonth() + 1)).format("YYYY-MM") + '-01' : (parseInt(year) + 1) + '-01-01';
    const params = {
      company: selector.auth?.user?.member_group?.[0]?.company_group.id,
      start_ym,
      end_ym,
      page: page
    }
    console.log("params>>>", params)
    setIsFetching(true)
    const response = await tradeHistoryCall(params)
    if (!contentCount || contentCount !== Math.floor(response.data.count / 15) + 1) {
      setContentCount(Math.floor(response.data.count / 15) + 1)
      let newArr = []
      for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
        if (i + 1 <= Math.floor(response.data.count / 15) + 1) {
          newArr.push(i + 1)
        }
      }
      setPageArr(newArr)
    }
    const content = response.data?.results || []
    if (content.length > 0) {
      setDataList([...content])
      setIsFetching(false)
    } else {
      setIsFetching(false)
      setIsListEnd(true)
      setDataList(false)
    }
  }

  const getMyContract = async () => {

    try {
      const params = {
        ids: JSON.stringify([companyId]),
      }
      let tmp = []
      const response = await postMyContract(params);
      if (response.data?.result === 'success') {
        const dataList = response?.data?.data;
        for (let i = 0; i < dataList.length; i++) {
          const tmpList = dataList[i]
          for (let j = 0; j < tmpList.data.length; j++) {
            const tmpData = tmpList.data[j]
            if (tmpData.contract_company_group_data.type === 'collection' || tmpData.contract_company_group_data.type === 'emitter') {
              tmp.push({
                id: dataList[i].id,
                minPrice: tmpData.contract_group_data.min_price,
                pricePerL: tmpData.contract_group_data.price_per_l,
              })
            }
            break;
          }
        }
        setPriceList(tmp)
        setPricePerL(tmp[0]?.pricePerL || 170)
      }

    } catch (error) {
      console.error(error);
    }
  }

  const ifUnchecked = (e) => {
    setOnChecked(onChecked.filter(item => item !== e.target.name))
  }

  useEffect(() => {
    nextGetData()
  }, [pagesGroup])

  useEffect(() => {
    if (isRefresh) {
      setIsRefresh(false)
    }
    getDataList()
    getMyContract()
  }, [isRefresh, page])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>거래내역 /  IoT Monitor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs maintitle="홈" title="관리대장" breadcrumbItem="거래내역" />

          <div style={{ marginTop: '26px' }}>
            {/* <h4 className="card-title mb-4">최근 거래내역</h4> */}
            <select id="yearselect" name="yearselect" className='dropdown-toggle waves-effect waves-light mb-4 waver-select-box' onChange={(e) => {
              handleYearClick(e)
              console.log(e.target.value)
            }}>
              <option value="" selected disabled hidden>{moment(newDate).format("YYYY") + '년'}</option>
              {yearSelect && yearSelect.map((item, index) => {
                return (
                  <option key={index} value={item}>{item + "년"}</option>
                )
              })}
            </select>
            <select id="monthselect" name="monthselect" className='dropdown-toggle waves-effect waves-light waver-select-box' style={{ marginLeft: 16}} value={newMonth} onChange={(e) => {
              handleMonthClick(e)
              console.log(e.target.value)
            }}>
              <option value="" selected disabled hidden>전체</option>
              <option value={''}>전체</option>
              {monthSelect && monthSelect.map((item, index) => {
                return (
                  <option key={index} value={item}>{item + "월"}</option>
                )
              })}
            </select>
          </div>

          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
              {/* <EmailSideBar /> */}
              <div className="email-rightbar mb-3">
                <Card>
                  <CardBody style={{padding: 0, borderRadius: 4, overflow: 'hidden'}}>                    
                    <div className="table-responsive">
                      <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                        <thead className="chart-thead">
                          <tr>
                            <th scope="col" >(#) <span>ID</span></th>
                            <th scope="col" >거래처명</th>
                            <th scope="col" >거래일자</th>
                            <th scope="col" >배출량</th>
                            <th scope="col" >정산금액</th>
                            <th scope="col" >정산여부</th>
                          </tr>
                        </thead>
                        <tbody className="chart-tbody">
                          {dataList && dataList.map((item, index) => {
                            return (
                              <tr key={item.id + '_' + index}>
                                <td scope="row">{item.id}</td>
                                <td>{item.company?.name}</td>
                                <td>{item.created_at.substr(0, 10)}</td>
                                <td>{item.qty} L</td>
                                <td>{Util.setCommaPrice(item.qty * pricePerL)} 원</td>
                                <td>{"미정산"}</td>
                              </tr>
                            )
                          })
                          }
                          {!dataList &&
                            <tr >
                              <td scope="row" colSpan="6">데이터가 없습니다.</td>                              
                            </tr>
                          }

                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
                {form === 'list' &&
                  <Row>
                    <Col xs="12" style={{ textAlign: "center" }}>
                      <div className="btn-group mt-4 waver-pagination-btn-group">
                        <Button                          
                          type="button"                          
                          size="sm"
                          className={page !== 1 ? 'waves-effect left-btn active' : 'waves-effect left-btn' }
                          onClick={() => {
                            if (page !== 1) {
                              setPage(page - 1)
                              if (Math.min(...pageArr) === page) {
                                setPagesGroup(pagesGroup - 5)
                              }
                            }
                          }}
                        >
                          <i className="fa fa-chevron-left" />
                        </Button>
                        {pageArr && pageArr.map((item, index) => {
                          return (
                            <Button
                              key={index}                              
                              type="button"
                              size="sm"
                              className={page === item ? 'waves-effect center-btn active' : 'waves-effect center-btn' }                              
                              onClick={() => {
                                setPage(item)
                              }}
                            >
                              {item}
                            </Button>
                          )
                        })}
                        <Button                        
                          type="button"                          
                          size="sm"
                          className={page !== contentCount ? 'waves-effect right-btn active' : 'waves-effect right-btn' }    
                          onClick={() => {
                            if (page !== contentCount) {
                              setPage(page + 1)
                              if (Math.max(...pageArr) === page) {
                                setPagesGroup(pagesGroup + 5)
                              }
                            }
                          }}
                        >
                          <i className="fa fa-chevron-right" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {onUpload && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

export default TradeHistory
