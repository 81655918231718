import PropTypes from 'prop-types'
import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap"
import { Link } from "react-router-dom"
// import  Util  from "./Common/Util"
import Util from 'pages/Dashboard/Common/Util';

import moment from 'moment';
import { useHistory } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// import images
import client from "../../assets/images/client.png";
import transactionAmount from "../../assets/images/transactionAmount.png";
import transactionPrice from "../../assets/images/transactionPrice.png";
import tradeHistory from "../../assets/images/거래내역.jpg";
import img1 from "../../assets/images/small/img-1.jpg"
import defaultImg from "../../assets/images/default.png"

// Charts
import LineAreaChart from "../AllCharts/apex/lineareachart";
import ChartistGraph from 'react-chartist';

import "chartist/dist/scss/chartist.scss";

import { useSelector } from 'react-redux';

//i18n
import { withTranslation } from "react-i18next"
import { dashboardResponse, getProductList, getSellProductList, mainResponse, newsResponse } from 'crud/auth.crud';
import { useEffect } from 'react';

const style = { fontSize: 14 };

const clickedStyle = { backgroundColor: "#48C3B1", color: "#ffffff" };

const Dashboard = props => {
  const history = useHistory()
  const selector = useSelector(state => state)
  const newDate = new Date()

  const [isFetching, setIsFetching] = useState(false)
  const [isListEnd, setIsListEnd] = useState(false)
  const [page, setPage] = useState(1)
  const [companyCount, setCompanyCount] = useState();
  const [collectSum, setCollectSum] = useState();
  const [collectMoneySum, setCollectMoneySum] = useState();
  const [newsList, setNewsList] = useState();
  const [isRefresh, setIsRefresh] = useState(false)
  const [dataList, setDataList] = useState([])
  const [searchText, setSearchText] = useState('')
  const [search, setSearch] = useState('')
  const [exchangeState, setExchangeState] = useState('sell')
  // 드롭다운
  const [settingMenu, setSettingMenu] = useState(false)

  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState()

  const nextGetData = async () => {
    setOnUpload(true)
    let newArr = []
    console.log("pageArr >>> ", pageArr)
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      console.log("페이지 일치 여부", page, i + 1)
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    console.log("newArr >>> ", newArr)
    setPageArr(newArr)
    setOnUpload(false)
  }

  // 상품목록 가져오는 용
  const getDataList = async () => {

    let params = {
      page: page,
      search: searchText
    }

    setIsFetching(true)

    const response = await getSellProductList(params);
    console.log("검색결과", response.data)
    if (!contentCount || contentCount !== Math.floor(response.data.count / 15) + 1) {
      setContentCount(Math.floor(response.data.count / 15) + 1)
      let newArr = []
      for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
        console.log("페이지 일치 여부", page, i + 1)
        if (i + 1 <= Math.floor(response.data.count / 15) + 1) {
          newArr.push(i + 1)
        }
      }
      setPageArr(newArr)
    }
    const content = response.data?.results || [];
    console.log(response.data.results)
    if (content.length > 0) {
      setDataList([...content])
      setIsFetching(false)
    } else {
      setIsFetching(false)
      setIsListEnd(true)
      setDataList([])
    }
  }

  const setPrice = (price) => {
    return Math.round(price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  useEffect(() => {
    nextGetData()
  }, [pagesGroup])

  useEffect(() => {
    getDataList();
    console.log("설렉터", selector)
  }, [])

  useEffect(() => {
    getDataList();
  }, [searchText, page])

  const clickList = (item) => {
    // setDetailData({...item});
    console.log("item ::", item)
    const url = {
      pathname: `/sell-detail-screen`,
      state: { ...item }
    }
    history.push(url);
  }

  return (
    // <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title>거래소 |  IoT Monitor</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Breadcrumbs maintitle="홈" title="거래소" breadcrumbItem="상품 리스트" />
          <Col sm={6}>
            <div className="float-end page-title-box" style={{ display: "flex", alignItems: 'center', width: '57%', minWidth: 640 }}>
              <div className="position-relative app-search d-none d-lg-block" style={{ padding: "calc(18px / 2) 0" }}>
                <input
                  // type="text"
                  className="form-control"
                  placeholder="검색어를 입력해주세요..."
                  onChange={(e) => {
                    setSearch(e.target.value.length == 0 ? '' : e.target.value)
                    console.log(e.target.value)
                  }}
                  onKeyPress={(e) => {
                    if (e.key == 'Enter') {
                      setSearchText(search)
                    }
                  }}
                />
                <span onClick={() => {
                  setSearchText(search)
                }} className="fa fa-search" style={{ lineHeight: '56px', cursor: 'pointer' }} />
              </div>
              <div className="float-end btn-group" style={{ display: 'flex', width: '42%', margin: "0 12px", height: 35 }}>
                <Button
                  style={exchangeState == 'sell' ? { ...clickedStyle, ...style } : { ...style }}
                  type="button"
                  size="sm"
                  className="waves-effect"
                  onClick={() => {
                    setExchangeState('sell')
                    props.stateChange("sell")
                  }}
                >
                  판매리스트
                </Button>
                <Button
                  style={exchangeState == 'buy' ? { ...clickedStyle, ...style } : { ...style }}
                  type="button"
                  size="sm"
                  className="waves-effect"
                  onClick={() => {
                    setExchangeState('buy')
                    props.stateChange("buy")
                  }}
                >
                  구매리스트
                </Button>
              </div>
              <Link to="/buy-request-write"
                className="btn btn-primary dropdown-toggle waves-effect waves-light bg-primary"
                style={{ fontSize: 14, width: 100, height: 36 }}
              >구매 등록
              </Link>
            </div>
          </Col>
        </Row>

        <Row>
          {dataList.length > 0 && dataList.map((item, index) => {
            return (
              <Col xl={4} md={6} key={index}>
                <Card className="directory-card">
                  <CardBody style={{ cursor: 'pointer' }} onClick={() => { clickList(item) }}>
                    <div className="d-flex">
                      <img
                        src={"http://Iot_monitor.ropiklabs.com" + item.trade_file?.[0]?.path}
                        alt="사진"
                        className="img-fluid img-thumbnail avatar-lg"
                        style={{ width: 6.5 + 'rem' }}
                        onError={(e) => e.target.src = defaultImg}
                      />
                      <div className="flex-1 ms-3">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <h5 className="text-primary font-size-18 mt-0 mb-1">
                            {item.subject ? item.subject : ''}
                          </h5>
                          <h5 className="font-size-18 mt-0 mb-1" style={{ color: item.state === 'N' ? 'blue' : item.state === 'U' ? 'red' : '#48C3B1' }}>
                            {item.state === 'N' ? '판매대기' : item.state === 'U' ? '판매중' : '판매완료'}
                          </h5>
                        </div>
                        <p className="font-size-12 mb-2">{item.created_at.substr(11, 8)}</p>
                        <p className="mb-0">{item.created_at.substr(0, 10)}</p>
                      </div>
                    </div>
                    <hr />
                    <div className="mb-0">
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <b>수량 : {item.qty}Kg</b>
                        <b>가격 : {setPrice(item.qty * item.price_per_kg)}원</b>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )
          })
          }
          {dataList.length === 0 &&
            <Col xl={4} md={6}>
              <Card className="directory-card">
                <CardBody>
                  <div className="d-flex">
                    <img
                      src={defaultImg}
                      alt="사진"
                      className="img-fluid img-thumbnail avatar-lg"
                      style={{ width: 6.5 + 'rem' }}
                    />
                    <div className="flex-1 ms-3">
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h5 className="text-primary font-size-18 mt-0 mb-1">
                          {'상품이 존재하지 않습니다.'}
                        </h5>
                        <h5 className="font-size-18 mt-0 mb-1" style={{ color: 'black' }}>
                          {'상품 없음'}
                        </h5>
                      </div>
                      <p className="font-size-12 mb-2">{''}</p>
                      <p className="mb-0">{''}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="mb-0">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <b>수량 : 0Kg</b>
                      <b>가격 : 0원</b>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            }
        </Row>
        <Row>
          <Col xs="12" style={{ textAlign: "center" }}>
            <div className="btn-group mt-4">
              <Button
                style={{ opacity: page !== 1 ? 1 : 0, cursor: page !== 1 ? "pointer" : 'auto', padding: "0.5rem 1rem" }}
                type="button"
                color="primary"
                size="sm"
                className="waves-effect"
                onClick={() => {
                  if (page !== 1) {
                    setPage(page - 1)
                    if (Math.min(...pageArr) === page) {
                      setPagesGroup(pagesGroup - 5)
                    }
                  }
                }}
              >
                <i className="fa fa-chevron-left" />
              </Button>
              {pageArr && pageArr.map((item, index) => {
                return (
                  <Button
                    key={index}
                    style={{ cursor: 'pointer', padding: "0.5rem 1rem", border: 'none', color: page === item ? 'white' : 'black', backgroundColor: page === item ? '#48C3B1' : 'white' }}
                    type="button"
                    size="sm"
                    className="waves-effect"
                    onClick={() => {
                      setPage(item)
                    }}
                  >
                    {item}
                  </Button>
                )
              })}
              <Button
                style={{ opacity: page !== contentCount ? 1 : 0, cursor: page !== contentCount ? "pointer" : 'auto', padding: "0.5rem 1rem" }}
                type="button"
                color="primary"
                size="sm"
                className="waves-effect"
                onClick={() => {
                  if (page !== contentCount) {
                    setPage(page + 1)
                    if (Math.max(...pageArr) === page) {
                      setPagesGroup(pagesGroup + 5)
                    }
                  }
                }}
              >
                <i className="fa fa-chevron-right" />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

    // </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)
