import PropTypes from 'prop-types'
import React,{useEffect, useState} from "react"
import { useHistory } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import "chartist/dist/scss/chartist.scss";

import store from 'store';
import { SET_CODE } from 'store/auth/data/actionTypes';

import Board from './Component/Board';

//i18n
import { withTranslation } from "react-i18next"
import { getIotDeviceBatteryList, getIotDeviceList, getIotDeviceSolarList, getDimDeviceList } from 'crud/auth.crud';

const Dashboard = props => {
  const history = useHistory()

  const [dataList, setDataList] = useState([])
  const [allDataList, setAllDataList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [filteredCompanyList, setfilteredCompanyList] = useState([])
  const [markerList, setMarkerList] = useState([])
  const [filteredMarkerList, setFilteredMarkerList] = useState([])
  const [filteredDataList, setFilteredDataList] = useState([])
  const [order, setOrder] = useState('id')
  const [batteryError, setBatteryError] = useState([])
  const [solarError, setSolarError] = useState([])
  const [dimmingList, setDimmingList] = useState([])
  const [centerMarker, setCenterMarker] = useState({ lat: 36.44699, lng: 127.09598 })
  const [screenList, setScreenList] = useState(['0', false])
  const [isLoading, setIsLoading] = useState([false, false, false, false])
  const [contentCount, setContentCount] = useState({1: 0, 2: 0, 3: 0, 4: 0, 5: 1})
  const [pagesGroup, setPagesGroup] = useState({1: 1, 2: 1, 3: 1, 4: 1, 5: 1})
  const [pageArr, setPageArr] = useState({1: [], 2: [], 3: [], 4: [], 5: [1]})

  const makePagenation = (order, count) => {
    const currentCount = count % 15 === 0 ? count / 15 : Math.floor(count / 15) + 1
    if (!contentCount[order] || contentCount[order] !== currentCount) {
      if(currentCount === 0){
        setContentCount(1)
      }else{
        setContentCount(prevState => ({...prevState, [order]: currentCount}))
      }
      let newArr = []
      for (let i = pagesGroup[order] - 1; i < pagesGroup[order] + 4; i++) {
        if (i + 1 <= currentCount) {
          newArr.push(i + 1)
        }
      }
      if(newArr.length === 0) newArr = [1]
      setPageArr(prevState => ({...prevState, [order]: newArr}))
    }
  }

  // //배터리 오류
  // const getBatteryList = async (page) => {
  //   setIsLoading(prevState => [prevState[0], prevState[1], !prevState[2], prevState[3]])
  //   try{
  //     const response = await getIotDeviceBatteryList()
  //     makePagenation(3, response.data?.count)
  //     if (response?.data?.results?.length > 0){
  //       setBatteryError(response?.data?.results)
  //     }else{
  //       setBatteryError([])
  //     };
  //   } catch (error) {
  //     console.error(error)
  //   }
  //   setIsLoading(prevState => [prevState[0], prevState[1], !prevState[2], prevState[3]])
  // }

  // //쏠라 오류
  // const getSolarList = async (page) => {
  //   setIsLoading(prevState => [prevState[0], prevState[1], prevState[2], !prevState[3]])
  //   try{
  //     const response = await getIotDeviceSolarList()
  //     makePagenation(4, response.data?.count)
  //     if (response?.data?.results?.length > 0){
  //       setSolarError(response?.data?.results)
  //     }else{
  //       setSolarError([])
  //     }
  //   } catch (error) {
  //     console.error(error)
  //   }
  //   setIsLoading(prevState => [prevState[0], prevState[1], prevState[2], !prevState[3]])
  // }

  //단말 상태
  const getDataList = async (page, type, search) => {
    setIsLoading(prevState => [prevState[0], !prevState[1], prevState[2], prevState[3]])
    try {
      const params = {
        search: search || '',
        page: page || 1,
        type : type || '', 
        order : order
      }
      const response = await getIotDeviceList(params)
      if (response.status === 200) {
        const content = response.data?.data || []
        makePagenation(2, content.length)
        if (content.length > 0) {
          console.log("content list",content)
          setDataList([...content])
          setMarkerList([...content])
          setFilteredDataList([...content])
          setFilteredMarkerList([...content])
        } else {
          setDataList([])
        }
        // 발주처 중복 제거
        getAgencyList(content)
        //////
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(prevState => [prevState[0], !prevState[1], prevState[2], prevState[3]])
  };

  //디밍 단말
  const getDimList = async (page, type, search) => {
    setIsLoading(prevState => [prevState[0], !prevState[1], prevState[2], prevState[3]])
    try {
      const params = {
        search: search || '',
        page: page || 1,
        type : type || '', 
        order : order
      }
      const response = await getDimDeviceList(params)
      if (response.status === 200) {
        const content = response.data?.data || []
        if (content.length > 0) {
          content.sort((a, b) => {
            return b.id - a.id
          })
          setDimmingList([...content])
        } else {
          setDimmingList([])          
        }
        //////
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(prevState => [prevState[0], !prevState[1], prevState[2], prevState[3]])
  };

  // 발주처 목록
  const getAgencyList = async (data) => {
    setIsLoading(prevState => [!prevState[0], prevState[1], prevState[2], prevState[3]])
    let obj = {}
    data.forEach( (i) => {
      obj = {...obj, [i.order_company] : obj[i.order_company] ? obj[i.order_company] + 1 : 1}
    })
    setAllDataList(data)
    let filteredArr = []
    let arr = []
    let dupContent = [...data]
    for(let i=0; i<dupContent.length; i++){
      if(arr.indexOf(dupContent[i].order_company) === -1){
        arr.push(dupContent[i].order_company)
        filteredArr.push({company : dupContent[i].order_company})
        filteredArr[i]['count'] = filteredArr[i]['company'] + ` (${obj[filteredArr[i]['company']]})`
      }else{
        dupContent.splice(i, 1)
        i -= 1
      }
    }
    filteredArr = filteredArr.sort((a, b) => {
      const companyA = a.company.toLowerCase();
      const companyB = b.company.toLowerCase();
      if (companyA < companyB) {
        return -1;
      }
      if (companyA > companyB) {
        return 1;
      }
      return 0;
    })
    makePagenation(1, filteredArr.length)
    if (filteredArr.length > 0) {
      setCompanyList([...filteredArr])
      setfilteredCompanyList([...filteredArr])
    } else {
      setCompanyList([])
      
    }
    setIsLoading(prevState => [!prevState[0], prevState[1], prevState[2], prevState[3]])
  }

  // 단말 위치 마커 및 검색 단말
  const createMarker = (company) => { 
    let arr = []
    if(company.length > 0){
      company.forEach((i) => {
        console.log("forEach",i)
        allDataList.forEach((item, index) => {
          console.log("create marker",item)
          if(allDataList[index]?.order_company === i){
            allDataList[index]['id'] = allDataList[index]['id'].toString()
            arr.push(allDataList[index])
          }
        })
      })
      setMarkerList(arr)
      setFilteredMarkerList(arr)
      // makePagenation(5, arr.length)
      if(company.length > 0){
        let lat = []
        let lng = []

        for(let i=0;i<arr.length;i++){
          lat.push(arr[i].lat)
          lng.push(arr[i].lon)
        }
        const center = {lat: (Math.max(...lat)+Math.min(...lat))/2, lng: (Math.max(...lng)+Math.min(...lng))/2}

        //대한민국을 벗어나거나 오류값일경우 마커를 센터에 찍음.
        if(center.lat > 38.3640 || center.lat < 33.0643 || center.lng > 124.3636 || center.lng < 131.5210 || (center.lat).isNaN() || (center.lng).isNaN()) {
          center.lat = 36.44699
          center.lng = 127.09598
        }

        if(centerMarker.lat === center.lat && centerMarker.lng === center.lng){
          center.lat -= 0.000001
          center.lng -= 0.000001
          // console.log("before set center", center)
          setCenterMarker(center)
        }else{
          // console.log("before set center else ", center)
          setCenterMarker(center)
        }
      }else{
        setCenterMarker({ lat: 36.44699, lng: 127.09598 })
      }
    } else {
      setMarkerList([])
      setFilteredMarkerList([])
      setMarkerList(dataList)
      setFilteredMarkerList(dataList)
      setCenterMarker({ lat: 36.44699, lng: 127.09598 })
    }

    
  }

  const movePage = (id, device) => {
    history.push({ pathname: '/detail', state: { id: id, device: device } })
  }

  const pageCallBack = async (page, domKey) => {
    switch (domKey) {
      case '4':
        getDataList(page)
        break
      case '5':
        // getDimmingList(page)
        break
      default:
        null
    }
  }

  const filterAgencyList = (type, searchText) => {
    if(searchText === ''){
      setfilteredCompanyList(companyList)
    } else {
      setfilteredCompanyList(companyList.filter(i => i[type].toString().indexOf(searchText) !== -1))
    }
    
  }

  const filterMarkerList = (type, searchText) => {
    console.log("set filtered marker",markerList.filter(i => i[type].toString().indexOf(searchText) !== -1))
    if(searchText === ''){
      setFilteredMarkerList(markerList)
    } else {
      setFilteredMarkerList(markerList.filter(i => i[type].toString().indexOf(searchText) !== -1))
    }
    
  }
  const filterDeviceList = (type, searchText) => {
    if(searchText === ''){
      setFilteredDataList(dataList)
    } else {
      setFilteredDataList(dataList.filter(i => i[type].toString().indexOf(searchText) !== -1))
    }
    
  }

  const filterList = (type, searchText, domKey) => {
    switch (domKey) {
      case '2':
        filterAgencyList(type, searchText)
        break
      case '3':
        filterMarkerList(type, searchText)
        break
      case '4':
        filterDeviceList(type, searchText)
        break
      case '5':
        // getDimmingList('1', type, searchText)
        break
      default:
        null
    }
  }

  const fullScreen = (key) => {
    if(screenList[0] === key){
      setScreenList(['0', false])
    }else {
      setScreenList([key, true])
    }
  }

  useEffect( async () => {
    getDataList()
    // getBatteryList()
    // getSolarList()

    // getDimList()

    // !!!!!!디밍 리스트 불러오는 함수 들어가야됨!!!!!

  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
      {/* <div className='content-header d-flex align-items-center noMargin text-black font-size-18 justify-content-between'>
        <div>
          <p className='font-weight-bold'>전체</p>
        </div>
        <div className='d-flex pointer'>
          <div className='padding-left-12 padding-right-12'>
            <p>발광형 표시 단말기<i className="mdi mdi-menu-down" /></p>
          </div>
          <div className='padding-left-12 padding-right-12'>
            <p><i className="mdi mdi-devices" /> 개체</p>
          </div>
          <div className='padding-left-12 padding-right-12'>
            <p><i className="mdi mdi-filter-variant font-size-20" /> Filters</p>
          </div>
          <div className='padding-left-12 padding-right-12'>
            <p><i className="mdi mdi-clock-outline" /> 기록 - 과거 0분</p>
          </div>
          <div className='padding-left-12 padding-right-12'>
            <p><i className="mdi mdi-download" /></p>
          </div>
        </div>
      </div> */}
        <MetaTags>
          <title>IOT MONITOR</title>
        </MetaTags>
        <Container fluid>
          {/* <div className="page-title-box">
            <Row className="align-items-center">
              <Col xl={8}>
                <h6 className="page-title font-size-24">관리 홈</h6>
                <ol className="breadcrumb m-0">
                  <li className="font-size-16" style={{color : '#8A8A8A'}}>'국내최초' 음식물류 폐기물 스마트 수거 플랫폼</li>
                </ol>
              </Col>
            </Row>
          </div> */}
          <Row>
            <Col xl={screenList[0] !== '1' ? 5 : 12} lg={screenList[0] !== '1' ? 8 : 12} style={(screenList[0] !== '1' && screenList[1]) ? { display: 'none' } : {}}>
              <Board
                title={'단말 위치'}
                form={'map'}
                dataList={filteredMarkerList} 
                boardKey={'device'}
                height={screenList[0] !== '1' ? '85vh' : '125vh'}
                centerMarker={centerMarker.lat === 'NaN' ? {lat:36.44699, lng:127.09598} : centerMarker}
                domKey={'1'}
                fullScreen={fullScreen} />
            </Col>
            <Col xl={screenList[0] !== '2' ? 2 : 12} lg={screenList[0] !== '2' ? 4 : 12} style={(screenList[0] !== '2' && screenList[1]) ? { display: 'none' } : {}}>
              <Board
                title={'발주처 목록'}
                form={'post'}
                headers={[
                  {key : 'count', value: '발주처'},  
                ]} 
                dataList={filteredCompanyList}
                callback={createMarker} 
                boardKey={'device'}
                height={screenList[0] !== '2' ? '85vh' : '125vh'}
                domKey={'2'}
                pageCallBack={pageCallBack}
                fullScreen={fullScreen}
                isLoading={isLoading[0]}
                contentCount={contentCount[1]}
                pageArr={pageArr[1]}
                filterList={filterList} />
            </Col>
            <Col xl={screenList[0] !== '3' ? 5 : 12} style={(screenList[0] !== '3' && screenList[1]) ? { display: 'none' } : {}}>
              <Board
                title={'검색 단말'}
                form={'post'}
                headers={[
                  {key : 'id', value: 'SN', class: 'device-id'},
                  {key : 'order_company', value: '발주처'},
                  {key : 'construct_at', value: '설치일'},
                  {key : 'sign_content', value: '표지내용'},
                  {key : 'signage_standard', value: '표지규격'},    
                ]}
                dataList={filteredMarkerList} 
                callback={movePage} 
                boardKey={'device'}
                height={screenList[0] !== '3' ? '85vh' : '125vh'}
                domKey={'3'}
                pageCallBack={pageCallBack}
                fullScreen={fullScreen}
                contentCount={contentCount[5]}
                pageArr={pageArr[5]}
                filterList={filterList} />
            </Col>
            <Col xl={screenList[0] !== '4' ? 12 : 16} style={(screenList[0] !== '4' && screenList[1]) ? { display: 'none' } : {}}>
              <Board
                title={'단말 상태'}
                form={'post'}
                headers={[
                  {key : 'id', value: 'SN', class: 'status-id'},
                  {key : 'order_company', value: '발주처'},
                  {key : 'construct_at', value: '설치일'},
                  {key : 'sign_content', value: '표지내용'},
                  {key : 'signage_standard', value: '표지규격'},  
                ]} 
                dataList={filteredDataList} 
                callback={movePage} 
                boardKey={'device'}
                height={screenList[0] !== '4' ? '85vh' : '125vh'}
                domKey={'4'}
                pageCallBack={pageCallBack}
                fullScreen={fullScreen}
                isLoading={isLoading[1]}
                contentCount={contentCount[2]}
                pageArr={pageArr[2]}
                filterList={filterList} />
            </Col>
            {/* <Col xl={screenList[0] !== '5' ? 6 : 12} style={(screenList[0] !== '5' && screenList[1]) ? { display: 'none' } : {}}>
              <Board
                title={'디밍 리스트'}
                form={'post'}
                headers={[
                  {key : 'id', value: 'SN', class: 'dimming-id'},
                  {key : 'company', value: '발주처'},
                  {key : 'created_at', value: '설치일'},
                  {key : 'sign_content', value: '표지내용'},
                  {key : 'signage_standard', value: '표지규격'}    
                ]} 
                dataList={dimmingList} 
                callback={movePage} 
                boardKey={'device'}
                height={screenList[0] !== '5' ? '43vh' : '85vh'}
                domKey={'5'}
                pageCallBack={pageCallBack}
                fullScreen={fullScreen}
                isLoading={isLoading[2]}
                contentCount={contentCount[3]}
                pageArr={pageArr[3]}
                filterList={filterList} />
            </Col> */}
            {/* <Col xl={screenList[0] !== '5' ? 4 : 12} style={(screenList[0] !== '5' && screenList[1]) ? { display: 'none' } : {}}>
              <Board
                title={'배터리 오류'}
                form={'post'}
                headers={[
                  {key : 'id', value: 'SN', class: 'battery-id'},
                  {key : 'company', value: '발주처'},
                  {key : 'created_at', value: '설치일'},
                  {key : 'updated_at', value: '이벤트시간'},
                  {key : 'sign_content', value: '표지판 내용'},
                  {key : 'signage_standard', value: '표지판 규격'},    
                ]} 
                dataList={batteryError} 
                callback={movePage} 
                boardKey={'device'}
                height={screenList[0] !== '5' ? '43vh' : '85vh'}
                domKey={'5'}
                pageCallBack={pageCallBack}
                fullScreen={fullScreen}
                isLoading={isLoading[2]}
                contentCount={contentCount[3]}
                pageArr={pageArr[3]}
                filterList={filterList} />
            </Col>
            <Col xl={screenList[0] !== '6' ? 4 : 12} style={(screenList[0] !== '6' && screenList[1]) ? { display: 'none' } : {}}>
              <Board
                title={'쏠라전압/LED 오류'}
                form={'post'}
                headers={[
                  {key : 'id', value: 'SN', class: 'solar-id'},
                  {key : 'company', value: '발주처'},
                  {key : 'created_at', value: '설치일'},
                  {key : 'updated_at', value: '이벤트시간'},
                  {key : 'signage_standard', value: '표지판 규격'},    
                ]} 
                dataList={solarError} 
                callback={movePage} 
                boardKey={'device'}
                height={screenList[0] !== '6' ? '43vh' : '85vh'}
                domKey={'6'}
                pageCallBack={pageCallBack}
                fullScreen={fullScreen}
                isLoading={isLoading[3]}
                contentCount={contentCount[4]}
                pageArr={pageArr[4]}
                filterList={filterList} />
            </Col> */}
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)
