import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  RE_LOGIN_USER,
} from "./actionTypes"

import { persistReducer, PURGE } from "redux-persist";
import storage from 'redux-persist/lib/storage'/*로컬스토리지*/

const initialState = {
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  error: "",
  loading: false,
};

const login = persistReducer(/*로그인값*/
  { storage , key: "auth", whitelist: ["user", "accessToken", "refreshToken"], version: 6 },
  (state = initialState, action) => {
    switch (action.type) {
      case LOGIN_USER:
        const { user : {access, refresh}} = action.payload;
        return {
          ...state,
          accessToken : access,
          refreshToken : refresh,
          user: undefined,        
          loading: true,
        }   
      case RE_LOGIN_USER:    
          console.log('재로그인 ::: ', action.payload)
          return {
            ...state,
            accessToken : action.payload?.accessToken,
            refreshToken : action.payload?.accessToken,
            user : action.payload?.user,
            loading: false,          
          }              
      case LOGIN_SUCCESS:        
        return {
          ...state,
          user : action.payload,
          loading: false,
        };                
      case LOGOUT_USER:        
        return initialState;
      case LOGOUT_USER_SUCCESS:
        return state;
      case API_ERROR:
        return { ...state, error: action.payload, loading: false }     
      case PURGE:        
        return initialState
      default:
        return { ...state }     
    }
   // return state
  }
);

export default login
