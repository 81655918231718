import React, { Component } from 'react';
import ChartistGraph from 'react-chartist';

class lineareachart extends Component {
    render() {
        var lineChartData = {
            labels: [1 + '월', 2 + '월', 3 + '월', 4 + '월', 5 + '월', 6 + '월', 7 + '월', 8 + '월', 9 + '월', 10 + '월', 11 + '월', 12 + '월'],
            series: [
                [15000, 19000, 11500, 8000, 15500, 13000, 15000, 14000, 12000, 13000, 14500, 12500]
            ],

        };
        var lineChartOptions = {
            low: 0,
            showArea: true,
        }
        return (
            <React.Fragment>
                <ChartistGraph data={lineChartData} style={{ height: "300px" }} options={lineChartOptions} type={'Line'} />
            </React.Fragment>
        );
    }
}

export default lineareachart;