import React, { useEffect, useState } from "react"
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"


class Collection extends React.PureComponent {

    constructor(props) {
        super(props);        
        this.state = {
            companyFileArray : [],            
            confirmationFileArray : [],
            licenseFileArray : [],
            carFile : [],
            certificateFile : [],
        }
        
        this.touchFileUploadButtonData = [
            {'id' : 'companyFileArray', 'name' : '- 사업장 등록증', 'type' : 'multi'},
            {'id' : 'confirmationFileArray', 'name' : '- 폐기물 수탁 처리 능력확인서', 'type' : 'multi'},
            {'id' : 'licenseFileArray', 'name' : '- 인허가 서류', 'type' : 'multi'},            
            {'id' : 'carFile', 'name' : '- 차량 등록증', 'type' : 'single'},
            {'id' : 'certificateFile', 'name' : '- 공동 인증서', 'type' : 'single'},            
        ]

    }

    isValiddate = () => {
        if(this.state.companyFileArray.length === 0){
            alert("사업장 등록증을 업로드해주세요.")
            return ""
        }
        if(this.state.confirmationFileArray.length === 0){
            alert("폐기물 수탁 처리능력 확인서를 업로드해주세요.")
            return ""
        }
        if(this.state.licenseFileArray.length === 0){
            alert("인허가 서류를 업로드해주세요.")
            return ""
        }
        if(this.state.carFile.length === 0){
            alert("차량 등록증을 업로드해주세요.")
            return ""
        }
        if(this.state.certificateFile.length === 0){
            alert("공동인증서를 업로드해주세요.")
            return ""
        }

        return "success";
    }

    registDocument = (e, item) => {
        let filteredFiles
        for(let i=0;i<e.target.files.length;i++){
          if(this.state[item.id]?.length > 0 && this.state[item.id]?.map(item => item.name).indexOf(e.target.files[i].name) != -1){
            filteredFiles = (filteredFiles || [...e.target.files]).filter(data => data !== [...e.target.files][i]);
          }
        }
        this.setState({[item.id] : item.type === 'multi' ? [...this.state[item.id], ...(filteredFiles || e.target.files)] : [...e.target.files]})
    }

    deleteDocument = (item, data) => {
        this.setState({[item.id] : this.state[item.id].filter(element => element.name !== data.name)})
    }

    render() {
        return (
        <>
            <div className="mb-3">
                <p className="noMargin" style={{fontSize: 18, color: 'black', marginTop: 12}}>필수 업로드 서류</p>
            </div>
            {
                this.touchFileUploadButtonData && this.touchFileUploadButtonData.map((item, index)=>{
                    return (
                    <div className="mb-3" key={item.key+'_'+index}>
                        <p style={{marginBottom: 6, color: "black", fontSize: 15}}>{item.name}</p>
                        <label className="btn btn-primary waves-effect waves-light" htmlFor={item.id} style={{height: 36, marginLeft: 6}}>파일 선택</label>
                        <input style={{display: "none"}} accept=".pdf" type="file" multiple={ item.type === 'multi' ? "multiple" : ""} id={item.id} onChange={(e)=>{
                            this.registDocument(e, item)
                        }}/>
                        {this.state[item.id]?.length > 0 && this.state[item.id]?.map((data, index) => {
                        return (
                            <div key={index} style={{border: "1px solid #48C3B1", padding: "0.25rem", borderRadius: 10, display: "flex", justifyContent: "space-between", marginBottom: 6}}>
                            <p className="noMargin" style={{paddingLeft: 6}}>{data.name}</p>
                            <p className="noMargin" style={{paddingRight: 6, color: "#000", cursor: "pointer"}} onClick={() => {
                                this.deleteDocument(item, data)
                            }}>X</p>
                            </div>
                        )
                        })
                        }
                    </div>
                    )
                })
            }

            <div className="mb-3 row">
                <div className="col-12 text-end">
                    <button
                    className="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                    onClick={()=>{
                        if(this.isValiddate() === 'success'){
                        this.props.handlePress({
                        ...this.state
                    }, this.touchFileUploadButtonData)}}}
                    >                        
                        회원가입 완료하기                        
                    </button>
                </div>
            </div>
        </>
        );
    }
};

export default Collection;