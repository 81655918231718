import { SET_ACCESS_TOKEN, SET_AUTH, CLEAR_AUTH } from "./actionTypes"

import { persistReducer, PURGE } from "redux-persist"
import storage from 'redux-persist/lib/storage'

const initialState = {
  // error: "",
  // success: "",
  accessToken: "",
  refreshToken: "",
  auth: null,
}

const profile = persistReducer({storage, key: "profile"},
  (state = initialState, action) => {
    switch (action.type) {
      case SET_ACCESS_TOKEN:
        return {
          ...state,
          accessToken : action.data?.access,
          refreshToken : action.data?.refresh,
        }
      case SET_AUTH:
        console.log("바뀐 state >>>", {...state, auth : action.data})
        return {
          ...state,
          auth : action.data,
        }
      case CLEAR_AUTH:
        console.log("CLEAR_AUTH 발동!")
        return {
          ...state,
          auth : null,
          accessToken : null,
          refreshToken : null,
        }
      case PURGE:
        return initialState
      default:
        return { ...state }
    }
  }
)


export default profile
