import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Input, Label, Card, CardBody, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap"
import moment from "moment";

const DefaultForm = (props) => {
  const [overlay, setOverlay] = useState();
  const [isFullScreen, setIsFullScreen] = useState(false)

  const { form, headers, dataList, callback, boardKey, page, setPage, pagesGroup, setPagesGroup, pageArr, contentCount, title, centerMarker, height, inputContent, fullScreen, domKey, buttonEvent } = props;

  // useEffect(()=>{
  //   isFullScreen ? fullScreen(0) : fullScreen(domKey)
  // },[isFullScreen])

  return (
    <>
      {form === 'post' &&
        <Card>
          <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
            <div className="table-responsive" style={{ height: height, minHeight: '250px', overflowX: 'auto' }}>
              <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                <thead className="chart-thead position-relative">
                  {/* <i className="mdi mdi-fullscreen position-absolute text-white font-size-24" style={{right: 12, lineHeight: '48px'}} onClick={()=>{
                    if(isFullScreen){
                      setIsFullScreen(!isFullScreen)
                      fullScreen(0)
                      console.log('껐다')
                    }else{
                      setIsFullScreen(!isFullScreen)
                      fullScreen(domKey)
                      console.log('켰다.')
                    }}} /> */}
                  <tr>
                    <th scope="col" className="bg-dark-ash" colSpan={headers.length}>{title}</th>
                  </tr>
                  <tr>
                    {
                      headers.length > 0 && headers.map((v) => {
                        return (
                          <th scope="col" className="bg-dark-ash">{v.value}</th>
                        )
                      })
                    }
                  </tr>
                </thead>
                <tbody className="chart-tbody clickable">

                  {
                    dataList && dataList.map((item, index) => {
                      return (
                        <tr key={boardKey + index} onClick={() => {console.log(item.id || '', item.company || ''); callback(item.id || '', item.company || '') }}>
                          {
                            headers && headers.map((v) => {
                              let text = item[v.key] || ''
                              if (typeof text === 'object' ) {
                                text = text.name || ''
                              }
                              if (text === '' && item.iot_device_model){
                                text = item.iot_device_model[v.key]
                              }
                              if (v.key === 'created_at') {
                                text = moment(text).format('YYYY-MM-DD')
                              }
                              if (v.key === 'updated_at') {
                                text = moment(text).format('YYYY-MM-DD HH:mm:ss')
                              }
                              return (
                                <td>{text}</td>
                              )
                            })
                          }
                        </tr>
                      )
                    })
                  }
                  {dataList.length === 0 &&
                    <tr >
                      <td scope="row" colSpan={headers.length}>데이터가 없습니다.</td>
                    </tr>
                  }

                </tbody>
              </table>
            </div>
            <Row>
              <Col md="12" style={{ textAlign: "center", marginBottom: 20 }}>
                <div className="btn-group mt-4 waver-pagination-btn-group">
                  <Button
                    type="button"
                    size="sm"
                    className={page !== 1 ? 'waves-effect left-btn active' : 'waves-effect left-btn'}
                    onClick={() => {
                      if (page !== 1) {
                        setPage(page - 1)
                        if (Math.min(...pageArr) === page) {
                          setPagesGroup(pagesGroup - 5)
                        }
                      }
                    }}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>
                  {pageArr && pageArr.map((item, index) => {
                    return (
                      <Button
                        key={index}
                        type="button"
                        size="sm"
                        className={page === item ? 'waves-effect center-btn active' : 'waves-effect center-btn'}
                        onClick={() => {
                          setPage(item)
                        }}
                      >
                        {item}
                      </Button>
                    )
                  })}
                  <Button
                    type="button"
                    size="sm"
                    className={page !== contentCount ? 'waves-effect right-btn active' : 'waves-effect right-btn'}
                    onClick={() => {
                      if (page !== contentCount) {
                        setPage(page + 1)
                        if (Math.max(...pageArr) === page) {
                          setPagesGroup(pagesGroup + 5)
                        }
                      }
                    }}
                  >
                    <i className="fa fa-chevron-right" />
                  </Button>
                </div>
              </Col>
            </Row>

          </CardBody>
        </Card>
      }
      {form === 'input' &&
        <Card>
        <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
          <div className="table-responsive" style={{ height: height, minHeight: '100px' }}>
            {title.length !== 0 &&
            <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
              <thead className="chart-thead">
                <tr>
                  <th scope="col" className="bg-dark-ash">{title}</th>
                </tr>
              </thead>
            </table>
            }
            <div className="w-100" style={{padding: 24}}>
              {inputContent && inputContent.map((item, index)=>{return(
                <div key={'input' + index} className="mb-4">
                  {item.button &&
                    <div className="waver-pagination-btn-group d-flex justify-content-center" >
                      <button className="waves-effect submit-btn btn btn-secondary margin-top-35 margin-left-6 font-size-16" 
                      >{item.button}</button>
                    </div>
                  }
                  {(item.value || item.placeholder) &&
                    <>
                      <p className="noMargin text-black font-weight-bold font-size-16">{item.title}</p>
                      <input value={item.key === 'created_at' ? item.value.substr(0, 10) : item.value} className="is-touched is-dirty av-valid form-control" placeholder={item.placeholder && item.placeholder} style={{marginTop: 3}} onChange={(e)=>{console.log("e:::",e)}} />
                    </>
                  }
                </div>
              )})}
            </div>
          </div>
        </CardBody>
      </Card>
      }
    </>
  )

}

export default DefaultForm;
