import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { useSelector } from "react-redux";

import { Link, useHistory } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import moment from 'moment';
import { getBusinessCompany, postChildrenContract, postCollectData, postMyContract, tradeHistoryCall } from "../../crud/auth.crud"
import Util from "pages/Dashboard/Common/Util";

import loading from "../../assets/images/loading.gif";

const ReportTotalList = () => {
  const history = useHistory()
  const selector = useSelector(state => state)

  // 드롭다운
  const [setting_Menu, setsetting_Menu] = useState(false)

  // useState가 list일 땐 목록창, detail일 땐 디테일창, modify일 땐 수정창
  const [form, setForm] = useState('list')

  // 체크박스 상태
  const [onChecked, setOnChecked] = useState([])

  const newDate = new Date()
  const nextDate = new Date()

  const [dataList, setDataList] = useState([]);
  const [sumList, setSumList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [pricePerL, setPricePerL] = useState(50);
  const [user, setUser] = useState(selector.auth?.user?.id);
  const [company, setCompany] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.id);
  const [startDate, setStartDate] = useState(moment(newDate).format('YYYY-MM') + '-01 00:00:00');
  const [endDate, setEndDate] = useState(moment(nextDate.setMonth(nextDate.getMonth() + 1)).format('YYYY-MM') + '-01 00:00:00');
  const [modalShow, setModalShow] = useState(false);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(4);
  const [totalElements, setTotalElements] = useState(4);
  const [page, setPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isListEnd, setIsListEnd] = useState(false);

  const getMyContract = async (params) => {
    try {
      let tmp = []
      const response = await postChildrenContract(params);
      if (response.data?.result === 'success') {
        const dataList = response?.data?.data;
        for (let i = 0; i < dataList.length; i++) {
          const tmpList = dataList[i]
          for (let j = 0; j < tmpList.data.length; j++) {
            const tmpData = tmpList.data[j]
            if (tmpData.contract_company_group_data.type === 'collection') {
              tmp.push({
                id: dataList[i].id,
                minPrice: tmpData.contract_group_data.min_price,
                pricePerL: tmpData.contract_group_data.price_per_l,
              })
            }
            break;
          }
        }
      }
      setPriceList(tmp)
    } catch (error) {
      console.error(error);
    }
  }

  const getCollectDataSum = async (dataList) => {
    try {
      let tmp = []
      for (let i = 0; i < dataList.length; i++) {
        tmp.push(dataList[i].id)
      }
      const params = {
        ids: JSON.stringify(tmp),
        start_ym: startDate,
        end_ym: endDate,
      }
      const response = await postCollectData(params);
      if (response.data?.result === 'success') {
        const dataList = response?.data?.data;
        let sum = 0

        for (let i = 0; i < dataList.length; i++) {
          sum = sum + (dataList[i].sum || 0);
        }
        setSumList(dataList)
      }
      getMyContract(params)
    } catch (error) {
      console.error(error);
    }
  }

  const getDataList = async () => {
    try {
      const response = await getBusinessCompany(company);
      if (response.data?.result === 'success') {
        let dataList = response?.data?.data?.filter(itm => itm.id !== company);
        let myData = response?.data?.data?.filter(itm => itm.id === company)
        setDataList([...myData, ...dataList])
        getCollectDataSum(dataList)
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getDataList()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>사업장 리스트 /  IoT Monitor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs maintitle="홈" title="청구서" breadcrumbItem="사업장 리스트" />

          <Row>
            <Col xs="12">
              <div className="email-rightbar mb-3">
                <Card>
                  <CardBody>
                    <div style={{ display: "flex" }}>
                      <h4 className="card-title mb-4">사업장 리스트</h4>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col" style={{ display: 'flex' }}>(#) <p style={{ fontWeight: 600 }}>ID</p></th>
                            <th scope="col" style={{ fontWeight: 600 }}>거래처명</th>
                            <th scope="col" style={{ fontWeight: 600 }}>배출량</th>
                            <th scope="col" style={{ fontWeight: 600 }}>정산금액</th>
                            {/* <th scope="col" style={{ fontWeight: 600 }}>정산금액</th> */}
                            <th scope="col" style={{ fontWeight: 600 }}>정산여부</th>
                          </tr>
                        </thead>
                        <tbody>

                          {dataList && dataList.map((item, index) => {
                            const sumData = sumList.filter(itm => itm.id === item.id)?.[0]
                            const sum = sumData?.total_qty_l || 0;
                            const money = sumData?.total_payment_price || 0;
                            const state = sumData?.state || 'N';
                            return (
                              <tr key={item.id + '_' + index} style={{cursor: "pointer"}} onClick={()=>{if(company === item.id){
                                history.push({ pathname: '/emitter-bill' })
                              }else{
                                history.push({ pathname: '/settlement-details', state: { companyId: item.id, companyName: item.name } })
                              }}}>
                                <th scope="row">{item.id}</th>
                                <td style={{ cursor: "pointer" }}>{item.name}</td>
                                <td>{sum} L</td>
                                <td>{money} 원</td>
                                {/* <td>{Util.setCommaPrice(item.qty * pricePerL)} 원</td> */}
                                <td>{state !== 'Y' ? '미정산' : '완납'}</td>
                              </tr>
                            )
                          })
                          }
                          {!dataList &&
                            <tr style={{ position: "relative", height: 46 }}>
                              <th scope="row"><p className="noMargin" style={{ position: "absolute", left: 0, right: 0, top: '0.75rem', margin: '0 auto', textAlign: "center" }}>데이터가 없습니다.</p></th>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              {/* <td></td> */}
                            </tr>
                          }

                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isLoading && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

export default ReportTotalList
