import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { useSelector } from "react-redux";
import { getTargetCompany, sendAllbaroEmail } from "crud/auth.crud";
import moment from "moment";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import weaver from "../../assets/images/weaver.png";
import loading from "../../assets/images/loading.gif";

const ReportManagementScreen = () => {
  const selector = useSelector(state => state)

  const [company, setCompany] = useState(selector.auth?.user?.member_group?.[0]?.company_group)
  const [type, setType] = useState(selector.auth.user?.type || "")
  const [detailType, setDetailType] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.company_detail?.[0]?.type || "")
  const [targetCompanyList, setTargetCompanyList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const newDate = new Date();
  const year = moment(newDate).format("YYYY");
  const prevYear = moment(newDate).format("YYYY") - 1;
  const API_URL = 'https://waveradmin.ropiklabs.com'
  const allbaroPath = API_URL + '/media/allbaro/' + company?.id + '/';
  const urls = {
    'collection': prevYear + '-폐기물_수집ㆍ운반_실적보고서.pdf',
    'treatment': year + '-수탁처리능력_확인서_폐기물_수탁확인서(',
    'many1': year + '-음식물류_폐기물_발생_억제_및_처리_계획신고서.pdf',
    'many2': prevYear + '-음식물류_폐기물_발생_억제_및_처리_실적보고.pdf',
    'total1': year + '-사업장_폐기물_배출자_신고서.pdf',
    'total2': '-관리대장.xlsx',
  }
  const numbers = Array.from({ length: 12 }, (_, i) => i + 1);

  const getTargetCompanyList = async () => {
    setIsLoading(true)
    const response = await getTargetCompany(company?.id);
    console.log(response)
    setTargetCompanyList(response.data?.data || [])
    setIsLoading(false)
  }

  const openWebLink = (url) => {
    console.log(url)
    const win = window.open(url, '_blank');
    win.focus();
  };

  const sendEmail = async () => {
    const { type, name, username, email, company_detail } = company;
    if (confirm(`위 내용을 올바로에 신고하시겠습니까?`)) {
      setIsLoading(true)
      let subject = '';
      let content = '';
      let files = [];
      switch (type) {
        case 'treatment':
          subject = `${name} ${year}-수탁처리능력 확인서 폐기물 수탁확인서`;
          content = `안녕하세요. ${name} 소속의 ${username}입니다. ${year}-수탁처리능력 확인서 폐기물 수탁확인서 신고합니다. 감사합니다.`;
          for (let i = 0; i < targetCompanyList.length; i++) {
            let val = targetCompanyList[i];
            files.push(`${urls.treatment}${val.name}).pdf`);
          }
          break;

        case 'collection':
          subject = `${name} ${year}-폐기물 수집ㆍ운반 실적보고서`;
          content = `안녕하세요. ${name} 소속의 ${username}입니다. ${year}-폐기물 수집ㆍ운반 실적보고서 신고합니다. 감사합니다.`;
          files.push(urls.collection);
          break;

        case 'emitter':
          if (detailType === 'many') {
            subject = `${name} ${year}-음식물류 폐기물 발생 억제 및 처리 계획신고서 / ${prevYear}-음식물류 폐기물 발생 억제 및 처리 실적보고서`;
            content = `안녕하세요. ${name} 소속의 ${username}입니다. ${year}-음식물류 폐기물 발생 억제 및 처리 계획신고서 / ${prevYear}-음식물류 폐기물 발생 억제 및 처리 실적보고서 신고합니다. 감사합니다.`;
            files.push(urls.many1);
            files.push(urls.many2);

          } else if (detailType === 'total') {
            subject = `${name} ${year}-사업장_폐기물_배출자_신고서 / ${prevYear}-관리대장`;
            content = `안녕하세요. ${name} 소속의 ${username}입니다. ${year}-사업장_폐기물_배출자_신고서 / ${prevYear}-관리대장 신고합니다. 감사합니다.`;
            files.push(urls.total1);
            for (let i = 0; i < 12; i++) {
              let num = i + 1;
              if (num < 10) {
                num = '0' + num;
              }
              files.push(`${prevYear}-${num}${urls.total2}`);
            }
          }
          break;

        default:
          break;
      }
      const params = {
        company: company?.id,
        email: email,
        subject: subject,
        content: content,
        files: files,
      };
      console.log("sendEmailParams >>> ", params)

      try {
        const response = await sendAllbaroEmail( params);
        if (response.data?.result === 'success') {
          alert(response.data?.msg)
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getTargetCompanyList()
    console.log('설렉터 >>> ', selector)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>신고사항 관리 |  IoT Monitor</title>
        </MetaTags>
        <div className="account-pages">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="신고사항 관리" />

            <Row className="justify-content-center">
              <Col md={8} lg={6} style={{ width: '20%', minWidth: 435 }}>
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-4">
                      <h5 className="text-white font-size-20">신고사항 관리</h5>
                      <p className="text-white-50"></p>
                      <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                    </div>
                  </div>
                  <CardBody className="p-4">
                    <div className="p-3">

                      <div className="mb-3">
                        <p className="noMargin" style={{ fontSize: 18, color: 'black', marginTop: 12 }}>{year}년 필요 구비 서류 확인</p>
                      </div>
                      {type === 'collection' &&
                        <div className="mb-3">
                          <p style={{ marginBottom: 6, color: "black", fontSize: 15 }}>- 폐기물 수집ㆍ운반 실적보고서</p>
                          <label className="btn btn-primary waves-effect waves-light" htmlFor="declaration" style={{ height: 36, marginLeft: 6 }}>파일 받기</label>
                          <input style={{ display: "none" }} type="button" id="declaration" onClick={() => { openWebLink(allbaroPath + urls.collection) }} />
                        </div>
                      }
                      {type === 'treatment' &&
                        <>
                          <div className="mb-3">
                            <p style={{ marginBottom: 6, color: "black", fontSize: 15 }}>- 수탁처리능력 확인서 폐기물 수탁확인서</p>
                          </div>
                          <div style={{ display: "flex", width: '100%', flexWrap: "wrap", justifyContent: "space-between" }}>
                            {targetCompanyList.map((val) => {
                              return (
                                <div className="mb-3" style={{ width: '50%' }}>
                                  <p style={{ marginBottom: 6, color: "black", fontSize: 15 }}>{val.name}</p>
                                  <label className="btn btn-primary waves-effect waves-light" htmlFor="manager" style={{ height: 36, marginLeft: 6 }}>파일 받기</label>
                                  <input style={{ display: "none" }} type="file" id="manager" onClick={() => { openWebLink(allbaroPath + urls.treatment + val.name + ').pdf') }} />
                                </div>
                              );
                            })}
                          </div>
                        </>
                      }
                      {type === 'emitter' && detailType === 'many' &&
                        <>
                          <div className="mb-3">
                            <p style={{ marginBottom: 6, color: "black", fontSize: 15 }}>- 음식물류 폐기물 발생 억제 및 처리 실적보고</p>
                            <label className="btn btn-primary waves-effect waves-light" htmlFor="report" style={{ height: 36, marginLeft: 6 }}>파일 받기</label>
                            <input style={{ display: "none" }} type="file" id="report" onClick={() => { openWebLink(allbaroPath + urls.many1) }} />
                          </div>
                          <div className="mb-3">
                            <p style={{ marginBottom: 6, color: "black", fontSize: 15 }}>- 음식물류 폐기물 발생 억제 및 처리 계획신고서</p>
                            <label className="btn btn-primary waves-effect waves-light" htmlFor="office" style={{ height: 36, marginLeft: 6 }}>파일 받기</label>
                            <input style={{ display: "none" }} type="file" id="office" onClick={() => { openWebLink(allbaroPath + urls.many2) }} />
                          </div>
                        </>
                      }
                      {type === 'emitter' && detailType === 'total' &&
                        <>
                          <div className="mb-3">
                            <p style={{ marginBottom: 6, color: "black", fontSize: 15 }}>- 사업장 폐기물 배출자 신고서</p>
                            <label className="btn btn-primary waves-effect waves-light" htmlFor="report" style={{ height: 36, marginLeft: 6 }}>파일 받기</label>
                            <input style={{ display: "none" }} type="file" id="report" onClick={() => { openWebLink(allbaroPath + urls.total1) }} />
                          </div>
                          <div style={{ display: "flex", width: '100%', flexWrap: "wrap", justifyContent: "space-between" }}>
                            {numbers.map((number) => {
                              let num = number;
                              if (number < 10) {
                                num = '0' + num;
                              }
                              return (
                                <div className="mb-3">
                                  <p style={{ marginBottom: 6, color: "black", fontSize: 15 }}>- {num}월 관리대장</p>
                                  <label className="btn btn-primary waves-effect waves-light" htmlFor="manager" style={{ height: 36, marginLeft: 6 }}>파일 받기</label>
                                  <input style={{ display: "none" }} type="file" id="manager" onClick={() => { openWebLink(allbaroPath + prevYear + '-' + num + urls.total2) }} />
                                </div>
                              );
                            })}
                          </div>
                        </>
                      }

                      <div className="mb-3 row">
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            style={{ marginRight: 12 }}
                            onClick={() => {
                              sendEmail()
                            }}
                          >
                            이메일로 제출
                          </button>
                        </div>
                      </div>

                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {isLoading && <div style={{
        position: "absolute",
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.6)',
        zIndex: 999,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img src={loading} alt="로딩중입니다." width={'5%'}></img>
      </div>}
    </React.Fragment>
  )
}

export default ReportManagementScreen
