import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link, useHistory } from "react-router-dom"

import store from 'store';
import { SET_CODE } from 'store/auth/data/actionTypes';
import { getCode } from "crud/auth.crud";
import { useSelector } from 'react-redux';

// import images
import weaver from "../../assets/images/weaver.png";
import emitter from "../../assets/images/emitter.png";
import emitter_on from "../../assets/images/emitter_on.png";
import demand from "../../assets/images/demand.png";
import demand_on from "../../assets/images/demand_on.png";
import collection from "../../assets/images/collection.png";
import collection_on from "../../assets/images/collection_on.png";
import treatment from "../../assets/images/treatment.png";
import treatment_on from "../../assets/images/treatment_on.png";

const typeArray = [
  {id : 'emitter', selectedImagePath : emitter_on, imagePath : emitter, text: '배출자'},
  {id : 'collection', selectedImagePath : collection_on, imagePath :collection, text: '수집운반업체'},
  {id : 'treatment', selectedImagePath : treatment_on, imagePath : treatment, text: '처리장'},
  {id : 'demand', selectedImagePath : demand_on, imagePath : demand, text: '수요기업'},
]

// const selectUserType = (id) => {
//   this.setState({userType : id})
// }
// onClick={()=>{ return setUserType(item.id);}}

const Register = props => {
  const selector = useSelector(state => state)
  console.log(selector)
  const history = useHistory();
  const [memberType, setMemberType] = useState('emitter');

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.registerUser(values)
  }

  useEffect(() => {
    props.apiError("")
  }, []);

  const handleClick = () => {
    const url = {
      pathname : `/register-step1`,
      state : {userType : memberType} 
    }
    console.log(memberType)
    history.push(url);
  }

  useEffect( async()=>{
    const rs = await getCode()
    console.log(rs.data)
    store.dispatch({ type: SET_CODE, data: [rs.data]})
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>회원가입 |  IoT Monitor</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">회원가입</h5>
                    <p className="text-white-50"></p>
                    <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="p-3">
                    <AvForm
                      className="mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >

                      {props.registrationError &&
                        props.registrationError ? (
                          <Alert color="danger">
                            {props.registrationError}
                          </Alert>
                        ) : null}

                      <div style={{margin: 'auto'}}>
                        { typeArray.map((item, index)=>{
                          return (                            
                            <div key={item.id+'_'+index} style={{display: "flex", float: index % 2 === 0 ? 'left' : 'none'}} onClick={() => {setMemberType(item.id)}}>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 168, height: 168, cursor: "pointer"}} >
                                <img src={memberType === item.id ? item.selectedImagePath : item.imagePath} height="90" alt={item.text} />
                                <p style={{fontSize: 18, color: 'black'}}>{item.text}</p>
                              </div>
                            </div>                          
                        )})}
                        {/* <div style={{display: "flex", float: 'left'}} >
                          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 168, height: 168, cursor: "pointer"}} >
                            <img src={emitter} height="90" alt="배출자" />
                            <p style={{fontSize: 18, color: 'black'}}>배출자</p>
                          </div>
                        </div>
                        <div style={{display: "flex"}}>
                          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 168, height: 168, cursor: "pointer"}}>
                            <img src={collection} height="90" alt="운반업체" />
                            <p style={{fontSize: 18, color: 'black'}}>수집운반업체</p>
                          </div>
                        </div>
                        <div style={{display: "flex", float: 'left'}}>
                          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 168, height: 168, cursor: "pointer"}}>
                            <img src={treatment} height="90" alt="처리기업" />
                            <p style={{fontSize: 18, color: 'black'}}>수요기업</p>
                          </div>
                        </div>
                        <div style={{display: "flex"}}>                          
                          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 168, height: 168, cursor: "pointer"}}>
                            <img src={demand} height="90" alt="수요업체" />
                            <p style={{fontSize: 18, color: 'black'}}>처리장</p>
                          </div>
                        </div> */}
                      </div>

                      <div className="mb-3 row">
                        <div className="col-12 text-center">
                          {/* <Link to="/register-step1"> */}
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              onClick={() => {handleClick()}}
                            >
                              선택 후 가입하기
                            </button>
                          {/* </Link> */}
                        </div>
                      </div>

                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  이미 계정이 있습니까 ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    로그인
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} IoT Monitor
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)
