
import {
  SET_ALARM_COUNT,
  SET_INTRO,
  SET_REFRESH,
  SET_CODE,
  SET_CONTRACT_STATE,
  SET_EXCHANGE_STATE,
  CLEAR_DATA,
} from "./actionTypes"

export const setAlarmCount = (params) => {  
  return {
    type: SET_ALARM_COUNT,
    data: params,
  }
}

export const setIntro = (params) => {  
  return {
    type: SET_INTRO,
    data: params,
  }
}

export const setRefresh = (params) => {  
  console.log('setRefresh 발동!', params)
  return {
    type: SET_REFRESH,
    data: params,
  }
}

export const setCode = (params) => {  
  return {
    type: SET_CODE,
    data: params,
  }
}

export const setContractState = (params) => {  
  return {
    type: SET_CONTRACT_STATE,
    data: params,
  }
}

export const setExchangeState = (params) => {  
  return {
    type: SET_EXCHANGE_STATE,
    data: params,
  }
}

export const clearData = (params) => {  
  return {
    type: CLEAR_DATA,
    data: params,
  }
}