import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container, Button } from "reactstrap"
import styled from 'styled-components';

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import weaver from "../../assets/images/weaver.png"
import { postCheckCode, postSendingCode, postSetPassword } from 'crud/auth.crud';

const style = { fontSize: 14, width: '50%' };

const clickedStyle = { backgroundColor: "#48C3B1", color: "#ffffff" };

const StyledComponent = styled.div`
  background-color: ${props => props.bgColor};
  font-size: ${props => props.fontSize}px;

  @media (max-width: 768px) {
    font-size: ${props => props.mobileFontSize}px;
  }
`;

const ForgetPasswordPage = props => {
  const history = useHistory()

  const [findingState, setFindingState] = useState('id')
  const [userId, setUserId] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [passwd, setPasswd] = useState('');
  const [passwdConfirm, setPasswdConfirm] = useState('');
  const [changePW, setChangePW] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [numberChecked, setNumberChecked] = useState(false);
  const [idListModal, setIdListModal] = useState(false);

  const [passwordModal, setPasswordModal] = useState(false);
  const [user, setUser] = useState({});
  const [onUpload, setOnUpload] = useState(false);

  const onSendCode = async () => {
    try {            
        if(!userId && findingState === 'pw'){
        }        
        if(!email){
        }        
        setOnUpload(true)
        let params = {
            email : email,
        }
        if(findingState === 'pw'){
          params = {...params, user_id : userId}
        }
        console.log(params)
        const response = await postSendingCode(params);     
        alert(response.data?.msg)   
        setOnUpload(false)
        setEmailChecked(true);

    } catch (e) {
      console.log(e);
      // console.log(e.response);
    }
  }

  const onCheckCode = async () => {
    try {      
        if(!emailChecked){
          return alert('인증번호를 확인해주세요.')
        }
        setOnUpload(true)
        let params = {
            email : email,
            number : number
        }
        if(findingState === 'pw'){
            params = {...params, user_id : userId}
        }
        const response = await postCheckCode(params);
        if (response.data.result === 'success') {
            setNumberChecked(true);
            if(findingState === 'pw'){
              setChangePW(true)
              setUser(response.data?.data)
              setPasswordModal(true);
            }else{
              setUser(response.data?.data)
              setIdListModal(true);
            }
        }
        setOnUpload(false)
        alert(response.data?.msg)
    } catch (e) {
      // console.log(e);
      // console.log(e?.response);
    }
  }

  const onChangePassword = async () => {        
    try {    
        if(passwd !== passwdConfirm){
          return alert('비밀번호 확인이 새 비밀번호와 다릅니다.')
        }   
        const params = {
          user_id : user.user_id,
          password : passwd
        }
        setOnUpload(true)
        const response = await postSetPassword(params);
        if (response.data?.result === 'success') {
            alert(response.data?.msg)
            setOnUpload(false)
            setPasswordModal(false)
            history.push('login')
        } else {
            setOnUpload(false)
            alert(response.data?.msg)
        }              
    } catch (e) {
      alert('비밀번호 변경에 실패하였습니다.')
      console.log(e);
    }
  };

  const handleValidSubmit = (event, values) => {
    console.log("event >>", event)
    console.log("value >>", values)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>아이디/비밀번호 찾기 |  IoT Monitor</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4} style={{ minWidth: 450 }}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20 p-2">ID/PW 찾기</h5>
                    <div className="logo logo-admin">
                        <img src={weaver} height="24" alt="logo" />
                      </div>
                  </div>
                </div>
                
                <CardBody className="p-4">

                  {props.forgetError && props.forgetError ? (
                    <Alert color="danger" style={{ marginTop: "13px" }} className="mt-5">
                      {props.forgetError}
                    </Alert>
                  ) : null}
                  {props.forgetSuccessMsg ? (
                    <Alert color="success" style={{ marginTop: "13px" }} className="mt-5">
                      {props.forgetSuccessMsg}
                    </Alert>
                  ) : null}

                  <div className="btn-group" style={{display: 'flex', width: '72%', margin: "36px auto 36px", height: 35}}>
                    <Button
                      style={findingState == 'id' ? {...clickedStyle, ...style} : {...style}}
                      type="button"
                      size="sm"
                      className="waves-effect"
                      onClick={() => {
                        setFindingState('id')
                        setPasswordModal(false)
                      }}
                    >
                      아이디 찾기
                    </Button>
                    <Button
                      style={findingState == 'pw' ? {...clickedStyle, ...style} : {...style}}
                      type="button"
                      size="sm"
                      className="waves-effect"
                      onClick={() => {
                        setFindingState('pw')
                        setIdListModal(false)
                        changePW === true && setPasswordModal(true)
                      }}
                    >
                      비밀번호 찾기
                    </Button>
                  </div>
                  <AvForm
                    className="form-horizontal mt-4"
                    onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                  >
                    
                    {findingState == 'pw' && 
                      <div className="mb-3" style={{display: 'flex', justifyContent: 'space-between'}}>
                        <AvField
                          name="id"
                          label="아이디"
                          className="form-control"
                          placeholder="아이디를 입력하세요."
                          type="id"
                          style={{width: 270}}
                          required
                          errorMessage="아이디를 입력하세요."
                          onChange={(e)=>{setUserId(e.target.value)}}
                        />
                      </div>
                    }
                    <div className="mb-3" style={{display: 'flex', justifyContent: 'space-between'}}>
                      <AvField
                        name="email"
                        label="이메일"
                        className="form-control"
                        placeholder="가입 시 사용한 이메일을 입력하세요."
                        type="email"
                        style={{width: 270}}
                        required
                        errorMessage="이메일을 입력하세요."
                        onChange={(e)=>{setEmail(e.target.value); console.log(e.target.value)}}
                      />
                      <input type="button" className="btn btn-primary waves-effect waves-light" style={{height: 35, marginTop: 30, marginLeft: 6}} onClick={()=>{onSendCode()}} value="인증번호 발송" />
                    </div>
                    <div className="mb-3" style={{display: 'flex', justifyContent: 'space-between'}}>
                      <AvField
                        name="confirmNumber"
                        label="인증번호 입력"
                        className="form-control"
                        placeholder="이메일로 받은 인증번호를 입력하세요."
                        type="any"
                        style={{width: 270}}
                        onChange={(e)=>{setNumber(e.target.value)}}
                      />
                      <input type="button" className="btn btn-primary waves-effect waves-light" style={{height: 35, marginTop: 30, marginLeft: 6}} onClick={()=>{onCheckCode()}} value="인증번호 확인" />
                    </div>
                    {/* <Row className="mb-3">
                      <Col className="text-center">
                        <button
                          className="btn btn-warning w-md waves-effect waves-light"
                          type="submit"
                          style={{marginTop: 36, height: 36}}
                          onClick={()=>{onCheckCode()}}
                        >
                          {findingState == 'id' ? '아이디 확인' : '비밀번호 변경'}
                        </button>
                      </Col>
                    </Row> */}
                  </AvForm>
                  {idListModal &&
                    <div className="mb-3" style={{position: "relative"}}>
                      <p className="noMargin" style={{fontSize: 16, color: 'black'}}>아이디</p>
                      <input value={user.user_id} className="is-touched is-dirty av-valid form-control" style={{marginTop: 3, width: 270}} disabled />
                    </div>
                  }
                  {passwordModal &&
                  <>
                    <div className="mb-3" style={{position: "relative"}}>
                      <p className="noMargin" style={{fontSize: 16, color: 'black'}}>패스워드 입력</p>
                      <input type='password' className="is-touched is-dirty av-valid form-control" placeholder='새 비밀번호를 입력하세요.' style={{marginTop: 3, width: 270}} onChange={(e)=>{
                        setPasswd(e.target.value)
                      }} />
                      <input type='password' className="is-touched is-dirty av-valid form-control" placeholder='비밀번호를 한 번 더 입력하세요.' style={{marginTop: 6, width: 270}} onChange={(e)=>{
                        setPasswdConfirm(e.target.value)
                      }} />
                    </div>
                    <Row className="mb-3">
                      <Col className="text-center">
                        <button
                          className="btn btn-warning w-md waves-effect waves-light"
                          type="submit"
                          style={{marginTop: 12, height: 36}}
                          onClick={()=>{onChangePassword()}}
                        >
                          비밀번호 변경하기
                        </button>
                      </Col>
                    </Row>
                  </>
                  }
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>계정을 기억하십니까? <Link to="login" className="fw-medium text-primary"> 로그인하세요. </Link> </p>
                <p>
                  © {new Date().getFullYear()} IoT Monitor
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)
