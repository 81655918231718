import React from "react";
import Modal from "./Modal"

const FileUploads = (props) => {   
  const {arr, handleChange, handleDelete, title, disabled} = props;
  console.log(arr)
  const [modalShow, setModalShow] = React.useState(false)
  const [selectedItem , setSelectedItem] = React.useState('')

  const modalOpen = (item) => {
    setSelectedItem(item)
    setModalShow(true)
  }

  const modalClose = () => {
    setModalShow(false);
  }

  return (
    <>
      <div style={{ marginBottom: 24 }}>                      
        <p style={{ width: 110, color: '#333', fontSize: 16, fontWeight: 600, lineHeight: "35px", marginBottom: 8 }}>{title}</p>                        
        <div style={{display:'flex', flexDirection:'row', width: 'calc(100% + 23px)', flexWrap : 'wrap', justifyContent:'flex-start'}}>
          { !disabled &&
            <>
            <label
              key={'file_upload_init'}
              className="btn btn-primary waves-effect waves-light" 
              htmlFor="getFile" 
              style={{             
                width: 109,
                height: 109,            
                background: '#4DBBAA',
                border: '1px solid #4DBBAA',
                borderRadius: 4,
                marginRight: 23,
                padding : 12
              }}
            >
              <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M43.1772 23.4118H23.4125V43.1765H20.5889V23.4118H0.824219V20.5883H20.5889V0.823547H23.4125V20.5883H43.1772V23.4118Z" fill="white"/>
              </svg>
              <p style={{marginBottom: 0, marginTop: 11, fontSize:14, lineHeight: 1}}>이미지 첨부</p>
              <p style={{margin: 0}}>{'('+arr.length+'/5)'}</p>
            </label>
            <input style={{ display: "none" }} type="file" id="getFile" accept="image/*" multiple="multiple" onChange={(e) => {
              handleChange(e)          
            }} />                
            </>
          }
          {arr.length > 0 ? arr.map((image, index) => {
            let item = {
              path : !disabled ? image?.path : image?.path.replace("http://127.0.0.1:5010", "http://Iot_monitor.ropiklabs.com"),
              name : image?.name || ''
            }            
            return (            
            <div key={'file_upload_'+index} style={{ width: "fit-content", position: "relative", marginBottom: 24, marginRight: 23}}>
              <div style={{width: 109, height: 109, position: 'relative'}}>                    
                <img style={{ width: 109, height: 109, borderRadius: 4, border : '0.1px solid #bdbdbd', fontSize: 16 }} src={item.path} />
                { !disabled && 
                  <p style={{ 
                      position: "absolute",
                      lineHeight: 0.5 , 
                      padding: 9, 
                      top: 5, 
                      right: 5, 
                      cursor: "pointer", 
                      borderRadius: 20, 
                      fontSize: 14, 
                      backgroundColor: 'rgba(77, 187, 170, 0.75)', 
                      width: 31, 
                      height: 31, 
                      color: '#fff',    
                      zIndex: 3                    
                    }}
                    onMouseEnter={(e)=>{e.target.style.background='rgba(77, 187, 170, 1)'}} 
                    onMouseLeave={(e)=>{e.target.style.background='rgba(77, 187, 170, 0.75)'}}
                    onClick={() => {
                      handleDelete(index)                    
                  }}>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.04125 6.50002L12.1235 11.5823L11.5828 12.1229L6.5006 7.04066L1.41836 12.1229L0.877718 11.5823L5.95995 6.50002L0.876953 1.41778L1.4176 0.877136L6.5006 5.95937L11.5828 0.877136L12.1235 1.41778L7.04125 6.50002Z" fill="white"/>
                    </svg>
                  </p>
                }
                <div 
                  onClick={() => {modalOpen(item)}}
                  onMouseOver={(e)=>{e.target.style.background='none'}} 
                  onMouseOut={(e)=>{e.target.style.background='rgba(0, 0, 0, 0.3)'}}
                  style={{position:'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)', width: 109, height: 109, top:0, left: 0, zIndex: 2, cursor:'pointer'}}
                ></div>
              </div>
            </div>
          )}) : <></>
          }
        </div>
      </div>
      <Modal title={'업체 선택'} show={modalShow} onHide={modalClose} size="md" onSubmit={()=>{console.log(1)}}>
        <div style={{ zIndex: 1, minHeight : '300px', width: '100%' }}>
          <p style={{fontSize: '1rem', textAlign: 'center'}}>{selectedItem.name}</p>
          <div style={{
            margin: '0 auto',
            width: 382,
            height: 676,            
            backgroundImage : 'url('+selectedItem?.path+')',
            backgroundSize : 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
          }}>
          </div>          
        </div>
      </Modal>
    </>
  )

}

export default FileUploads;
