import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';

import { Link, useHistory } from "react-router-dom"
import { Container, Row, Col, Button, Input, Label, Card, CardBody, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap"
import { useSelector } from 'react-redux';
import store from "store";
import { SET_CONTRACT_STATE } from "store/auth/data/actionTypes";

import ContractFinish from "./ContractFinish";
import Uncontracted from "./Uncontracted";

const style = { fontSize: 14 };

const clickedStyle = { backgroundColor: "#48C3B1", color: "#ffffff" };

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const ContractManagementScreen = () => {
  const selector = useSelector(state => state)
  // 계약완료 / 미계약
  const [contractState, setContractState] = useState(selector.Data.contractState)

  const changeContractState = async(str) => {
    await store.dispatch({ type: SET_CONTRACT_STATE, data: [str] });
    setContractState(str);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>계약서 관리 |  IoT Monitor</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Breadcrumbs maintitle="홈" title="마이 IoT Monitor" breadcrumbItem="계약서 관리" />           
          </Row>
          <Row>
            <Col sm={12}>
              <div className="float-end page-title-box">
                <Link to="/contract-management-screen-write"
                  className="btn waves-effect waves-light regist-btn"
                  style={{ fontSize: 16 }}
                >계약서 등록
                </Link>
              </div>
              <div className="float-start btn-group" style={{ margin: "26px 0 24px", height: 38 }}>
                <Button                  
                  type="button"
                  size="sm"
                  className={contractState == 'uncontracted' ? 'waves-effect custom-btn active' : 'custom-btn waves-effect'}                  
                  onClick={() => {
                    changeContractState('uncontracted')
                  }}
                >
                  미계약
                </Button>                
                <Button                  
                  type="button"
                  size="sm"
                  className={contractState == 'contractFinish' ? 'waves-effect custom-btn active' : 'custom-btn waves-effect'}                  
                  onClick={() => {
                    changeContractState('contractFinish')
                  }}
                >
                  계약완료
                </Button>
              </div>
            </Col>
          </Row>

          {contractState == "contractFinish" && <ContractFinish />}
          {contractState == "uncontracted" && <Uncontracted />}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ContractManagementScreen
