import React from "react";
import { Modal, Button } from "react-bootstrap";

const DefaultForm = (props) => {   
  return (
    <>
    <Modal {...props} size={props.size} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {props.children && props.children}
      </Modal.Body>
      
      <Modal.Footer>        
        { props.submitBtn && 
          <Button variant="primary" onClick={props.onSubmit}>{props.submitBtn || '완료'}</Button>
        }      
      </Modal.Footer>      
    </Modal>
    </>
  )

}

export default DefaultForm;
